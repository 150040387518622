import React, {Component, useEffect, useState, useRef} from 'react';
import Leaflet, {divIcon, icon} from 'leaflet';

import {
    Map, TileLayer, Marker, Popup, Polygon, Circle, Polyline, CircleMarker,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

Leaflet.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.5.0/dist/images/";
const LeafletMaps = (props) => {
    //console.log('props', props);
    const markerRef = useRef(null);

    const [zoom, setZoom] = useState(14);
    const [range, setRange] = useState(0);

    //const circle_color = "#F4B52D";
    const circle_color = "yellow";
    
    const {geo_fence, geo_lat, geo_lng} = props.geofence && props.geofence.latitude && props.geofence.longitude ? {
        geo_fence: true, geo_lat:props.geofence.latitude, geo_lng:props.geofence.longitude }:{
            geo_fence:false, geo_lat: props.lat, geo_lng: props.lng
        };
        // console.log('props.geofence', props.geofence, range)
        // console.log('props', props, 'geo_fence', geo_fence)
    const {lat, lng} = !props.multiple ? props.lat && props.lng ? {lat: props.lat, lng: props.lng} : {
        lat: 43,
        lng: 42
    } : props.location.length > 0 ? {
        lat: props.location[props.location.length - 1].latitude != null ? props.location[props.location.length - 1].latitude : 0,
        lng: props.location[props.location.length - 1].longitude != null ? props.location[props.location.length - 1].longitude : 0
    } : {lat: 43, lng: 42};

    const customMarkerIcon = deg => divIcon({
        className: "leaflet-div-icon",
        html: `<img width='20px' src='media/ais/navigation.png' style = "transform: rotate(${deg}deg)" />`,

    });
    const stopMarkerIcon = deg => divIcon({
        className: "leaflet-div-icon",
        html: `<img width='20px' src='media/ais/blue-icon.png' style = "color:green " />`,

    });

    const geoFenceMarkerDivIcon =  divIcon({
        className: "leaflet-div-icon",
        html: `<img  src='/media/svg/icons/Map/Marker2.svg' />`,

    });
    const geoFenceMarkerIcon =  icon({

            iconUrl: '/media/svg/icons/Map/Marker2.svg',
            iconSize: [38, 95],
            iconAnchor: [22, 94],
            popupAnchor: [-3, -76],
            shadowSize: [68, 95],
            shadowAnchor: [22, 94]
  

    });
    // const iconPerson = new L.Icon({
    //     iconUrl: require('media/svg/icons/Map/Marker2.svg'),
    //     iconRetinaUrl: require('media/svg/icons/Map/Marker2.svg'),
    //     iconAnchor: null,
    //     popupAnchor: null,
    //     shadowUrl: null,
    //     shadowSize: null,
    //     shadowAnchor: null,
    //     iconSize: new L.Point(60, 75),
    //     className: 'leaflet-div-icon'
    // });

    // console.log('geo_lat', geo_lat, geo_lng)
    // console.log('props.geofence', props.geofence)


    useEffect(() => {
        let range=props.range;
        setRange(range)
        if(range<=20 && range>=0){
            setZoom(20)
        }if(range<=40 && range>20){
            setZoom(19)
        }if(range<=70 && range>40){
            setZoom(18)
        }if(range<=90 && range>70){
            setZoom(17)
        }if(range<=220 && range>90){
            setZoom(14) // 16
        }if(range<=320 && range>220){
            setZoom(15)
        }if(range<=820 && range>320){
            setZoom(14)
        }if(range<=2020 && range>820){
            setZoom(13)
        }if(range<=4020 && range>2020){
            setZoom(12)
        }if(range<=7020 && range>4020){
            setZoom(11)
        }if(range<=15020 && range>7020){
            setZoom(10)
        }if(range<=25020 && range>15020){
            setZoom(9)
        }if(range<=60020 && range>25020){
            setZoom(8)
        }if(range<=100020 && range>60020){
            setZoom(7)
        }if(range<=300020 && range>100020){
            setZoom(6)
        }if(range<=700020 && range>300020){
            setZoom(5)
        }if(range<=1000020 && range>700020){
            setZoom(4)
        }if(range<=1500020 && range>1000020){
            setZoom(3)
        }
        

    },[props.range]);

    return (

        <Map center={[lat, lng]} zoom={zoom} style={{height: '100%', zIndex: 1}} attributionControl={false}
             zoomControl={true}>
            {
                props.polygon ?
                    props.polygon.length > 0 ?
                        props.polygon.map(poly => (poly.type === 'polygon'
                            ? <Polygon _rev={poly._rev} id={poly._id} key={poly._id} positions={poly.coordinates}/>
                            : <Polyline _rev={poly._rev} color="#c0392b" id={poly._id} key={poly._id}
                                        positions={poly.coordinates}/>))
                        : ""
                    : ""
            }
            <TileLayer
                maxZoom={20}
                attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                url="https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=9688acd1b142413facccbc5783313a69"
            />
            {!props.multiple
                ? <Marker position={[lat, lng]}>
                    <Popup>
                        <h5>Buoy Location:</h5>
                        <hr/>
                        <b>Latitude: </b>{" " + lat} <br/>
                        <b>Longitude: </b>{" " + lng}
                    </Popup>
                </Marker>
                : <>
                    {props.location.map((loc) =>
                        (<Marker position={[loc.latitude, loc.longitude]}>
                                <Popup>
                                    <h5>Buoy Location:</h5>
                                    <hr/>
                                    {`lat: ${loc.latitude}    lng: ${loc.longitude}`}
                                </Popup>
                            </Marker>

                        )
                    )}
                    {
                        props.ais && props.ais.length > 0 ? props.ais.map(ship => (
                                ship.heading == 511 ?
                                    <Marker key={ship.mmsi} position={[ship.latitude, ship.longitude]}
                                            icon={stopMarkerIcon(ship.heading)} /*onClick={e=>ships(ship.mmsi, ship.timestamp)}*/>
                                        <Popup>
                                            MMSI :
                                            {' '}
                                            {ship.mmsi}
                                            {' '}
                                            <br/>
                                            Latitude :
                                            {' '}
                                            {ship.latitude}
                                            {' '}
                                            <br/>
                                            Longitude :
                                            {' '}
                                            {ship.longitude}
                                            {' '}
                                            <br/>
                                            Heading :
                                            {' '}
                                            {ship.heading}
                                            {' '}
                                            <br/>
                                            Country :
                                            {' '}
                                            {ship.midCountry}
                                            {' '}
                                            <br/>
                                            Speed :
                                            {' '}
                                            {ship.speed}
                                            {' '}
                                            <br/>
                                            Course over ground :
                                            {' '}
                                            {ship.cog}
                                            {' '}
                                            <br/>
                                            Time :
                                            {' '}
                                            {new Date(Number(ship.timestamp * 1000)).toLocaleString()}
                                            <br/>
                                            Timestamp :
                                            {' '}
                                            {ship.timestamp}
                                            {' '}
                                            <br/>


                                        </Popup>
                                    </Marker>

                                    : <><Marker key={ship.mmsi} position={[ship.latitude, ship.longitude]}
                                                icon={customMarkerIcon(ship.heading)} /*onClick={e=>ships(ship.mmsi, ship.timestamp)}*/>
                                        <Popup>
                                            MMSI :
                                            {' '}
                                            {ship.mmsi}
                                            {' '}
                                            <br/>
                                            Latitude :
                                            {' '}
                                            {ship.latitude}
                                            {' '}
                                            <br/>
                                            Longitude :
                                            {' '}
                                            {ship.longitude}
                                            {' '}
                                            <br/>
                                            Heading :
                                            {' '}
                                            {ship.heading}
                                            {' '}
                                            <br/>
                                            Country :
                                            {' '}
                                            {ship.midCountry}
                                            {' '}
                                            <br/>
                                            Speed :
                                            {' '}
                                            {ship.speed}
                                            {' '}
                                            <br/>
                                            Course over ground :
                                            {' '}
                                            {ship.cog}
                                            {' '}
                                            <br/>
                                            Time :
                                            {' '}
                                            {new Date(Number(ship.timestamp * 1000)).toLocaleString()}
                                            <br/>
                                            Timestamp :
                                            {' '}
                                            {ship.timestamp}
                                            {' '}
                                            <br/>


                                        </Popup>
                                    </Marker>
                                        {props.line && props.line.length > 0
                                            ? props.line.map(line => (
                                                line.mmsi == ship.mmsi ?

                                                    <Polyline color="red" id={12312312312} key={1231231231} positions={[
                                                        line.data
                                                    ]}/>

                                                    : ""
                                            ))
                                            : ""
                                        }
                                    </>
                            )
                        ) : 'Loading...'
                    }
                </>
            }
            {/* geo_fence ? 
            <Marker  position={[geo_lat, geo_lng]}>
                <Popup>
                    <h5>Geofence Location:</h5>
                    <hr/>
                    <b>Latitude: </b>{" " + geo_lat} <br/>
                    <b>Longitude: </b>{" " + geo_lng}
                </Popup>
        </Marker>: '' */}
            {geo_fence ? <Circle
                // center={[lat?lat:43, lng?lng:42]}
                center={[geo_lat, geo_lng]}
                radius={range
                    //props.range? parseInt(props.range)/10 : 100
                }
                color={circle_color}
                fillColor={circle_color}
            >
                <Popup>
                    {props.range? parseInt(props.range)+" m" : "600 m"}
                </Popup>
            </Circle>:'' }
        </Map>
    );
}

export default LeafletMaps;