import * as requestFromServer from "./devicesCrud";
import { devicesSlice, callTypes } from "./devicesSlice";

const { actions } = devicesSlice;

export const fetchDevices = (queryParams) => (dispatch) => {
  var products = [];
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProducts(queryParams)
    .then((response) => {
      /*response.data.map(data => {
                products.push({
                    ...data,
                    company_name: data.company_devices[0] ? data.company_devices[0].company.name : "",
                    type: data.device_type == 1 ? "Octopus" : "Çakar Lamba",
                    active: data.is_active ? "Tanımlı" : "Tanımlı Değil"
                })
            })*/
      return dispatch(
        actions.productsFetched({
          totalCount: response.data.length,
          entities: products,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDevice = (id) => (dispatch) => {
  if (!id.type) {
    return dispatch(actions.deviceFetched({ deviceForMonitoring: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDeviceById(id)
    .then((response) => {
      /*console.log(response)*/
      if (id.type === "octopus_06202001") {
        const product = response.data;
        dispatch(actions.deviceFetched({ deviceForMonitoring: product }));
      } else {
        // console.log(response.data)
        const product = {
          latitude: response.data.system_status.latitude,
          longitude: response.data.system_status.longitude,
          timestamp: response.data.timestamp,
          //battery_voltage: response.data.system_status.led_on_avg_voltage,
          // change 24.05.2022
          battery_voltage: response.data.system_status.led_off_avg_voltage,
          lantern_led_active: response.data.system_status.led_active,
          lantern_led_health: response.data.system_status.led_health,
          battery_current: response.data.system_status.led_on_avg_current,
          led_off_avg_voltage: response.data.system_status.led_off_avg_voltage,
        };
        if (!response.data)
          product = {
            latitude: "",
            longitude: "",
            timestamp: "",
            battery_voltage: "",
            lantern_led_health: "",
            lantern_led_active: "",
            battery_current: "",
            led_off_avg_voltage: "",
          };
        dispatch(actions.deviceFetched({ deviceForMonitoring: product }));
      }
      return response;
    })
    .catch((error) => {
      error.clientMessage = error.response
        ? error.response
        : "Can't get product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error.clientMessage;
    });
};

export const fetchDeviceRisk = (data) => (dispatch) => {
  if (!data.type) {
    return dispatch(actions.deviceRisk({ deviceRiskForMonitoring: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDeviceRisk(data)
    .then((response) => {
      const product = response.data;
      dispatch(actions.deviceRisk({ deviceRiskForMonitoring: product }));
      return response;
    })
    .catch((error) => {
      error.clientMessage = error.response
        ? error.response
        : "Can't get product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error.clientMessage;
    });
};
