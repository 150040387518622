import axios from "axios";

export const LOGIN_URL = process.env.REACT_APP_API+"/login";
export const REGISTER_URL = process.env.REACT_APP_API+"/register";
export const REQUEST_PASSWORD_URL = process.env.REACT_APP_API+"/forgot-password";
export const UPDATE_SELF_URL = process.env.REACT_APP_API+"/api/users/update/self";

export const ME_URL = process.env.REACT_APP_API+"/api/users/me";

export function login(email, password) {

  return axios.post(LOGIN_URL, { email, password });
}

export function update(values) {

  return axios.post(UPDATE_SELF_URL, { email:values.values.email, firstname:values.values.firstname, lastname:values.values.lastname, phone:values.values.phone, image:values.values.image, token:values.token  });
}

export function register(values) {

  return axios.post(REGISTER_URL, { email:values.email, firstname:values.firstname, lastname:values.lastname, company:values.company, password:values.password , serialNo: values.serialNo});
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(token) {
  // Authorization head should be fulfilled in interceptor.

  return axios.get(ME_URL+"?token="+token);
}
