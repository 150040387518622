import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import RoomIcon from "@material-ui/icons/Room";
import Map from "./map/Map";
import Chip from "@material-ui/core/Chip";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import ToysIcon from "@material-ui/icons/Toys";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "0.42rem",
    marginTop: "25px",
    width: "90%",
    textAlign: "left",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default function RecipeReviewCard(props) {
  const intl = useIntl();

  const classes = useStyles();
  const [data, setData] = useState([]);
  const [date, setDate] = useState(parseInt(new Date().getTime() / 1000));
  const [ais, setAis] = useState([]);
  const [range, setRange] = useState();
  const [geofence, setGeofence] = useState(null);
  const [interval, setInter] = useState(null);
  const { authToken } = useSelector((state) => state.auth);
  const history = useHistory();
  useEffect(() => {
    if (props.db == "octopus_06202001") {
      axios
        .get(`${process.env.REACT_APP_API2}/ais`, {
          params: {
            timestamp: date,
            token: authToken,
            type: props.db,
          },
        })
        .then((res) => {
          const dataArr = res.data.ais;
          const arr = [];
          dataArr.map((data) => {
            arr.push(data);
          });
          setAis(arr);
          //console.log(arr)
        });

      axios
        .get(
          `${process.env.REACT_APP_API2}/live/data?token=${authToken}&type=${props.db}`
        )
        .then((response) => {
          setData(response.data);
          //console.log(response.data)
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 401) {
              history.push("/logout");
            }
          }
          console.log(error);
        });
    } else {
      axios
        .get(
          `${
            process.env.REACT_APP_MQTT_SERVER
          }/api/device?token=${authToken}&dbName=${props.collection.trim()}&collection=system_status`
        )
        .then((response) => {
          //console.log(props.collection, response.data)
          setData({
            lantern_latitude: response.data.system_status.latitude,
            lantern_longitude: response.data.system_status.longitude,
            timestamp: response.data.timestamp,
            //battery_voltage: response.data.system_status.led_on_avg_voltage,
            // change 24.05.2022
            battery_voltage: response.data.system_status.led_off_avg_voltage,
            lantern_led_health: response.data.system_status.led_health,
            lantern_led_active: response.data.system_status.led_active,
          });
          //console.log(response.data)
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 401) {
              history.push("/logout");
            }
          }
          console.log(error);
        });
      axios
        .get(
          `${
            process.env.REACT_APP_MQTT_SERVER
          }/api/device?token=${authToken}&dbName=${props.collection.trim()}&collection=config`
        )
        .then((response) => {
          //console.log(props.collection, response.data)
          setGeofence(response.data.initial_location ? response.data.initial_location : null)
          setRange(
            response.data.initial_location
              ? response.data.initial_location.range
              : ""
          );
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 401) {
              history.push("/logout");
            }
          }
          console.log(error);
        });
    }
  }, []);

  var alrt = parseInt(new Date().getTime() / 1000) - parseInt(data.timestamp);
  var a = new Date(data.timestamp * 1000);

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  //var month = months[a.getMonth()];
  // bug fix for month
  var month = a.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  var dated = a.getDate();
  dated = dated < 10 ? "0" + dated : dated;
  var hour = a.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  var min = a.getMinutes();
  min = min < 10 ? "0" + min : min;
  var sec = a.getSeconds();
  var timestamp_to_date =
    dated + "-" + month + "-" + year + " " + hour + ":" + min + ":" + sec;
  //console.log('timestamp_to_date', data.timestamp, timestamp_to_date)

  //console.log(alrt, data.timestamp)
  return (
    <Card className={classes.card}>
      <a href={"/buoy-detail/" + props.url}>
        <CardHeader
          title={props.name}
          subheader={props.db.split("_")[0].toUpperCase()}
        />
      </a>
      <hr />
      <div style={{ height: "200px", margin: 20 }}>
        <Map
          lat={data.lantern_latitude}
          lng={data.lantern_longitude}
          range={range}
          geofence={geofence}
        />
      </div>
      <CardContent
        style={{
          borderStyle: "solid",
          borderRadius: "5px",
          margin: 20,
          borderColor: "rgba(73,73,73,0.12)",
        }}
      >
        <div className="row">
          <div className="col-lg-6">
            <IconButton aria-label="Add to favorites">
              <WifiTetheringIcon style={{ color: "rgb(53, 193, 241)" }} />
            </IconButton>
            <FormattedMessage id="MAIN.CARD.CONNECTION" />
          </div>
          <div className="col-lg-5" style={{ margin: "auto", float: "right" }}>
            {alrt > 1080 || !alrt ? (
              <span style={{ float: "right" }}>
                <Chip
                  size="small"
                  style={{
                    backgroundColor: "rgb(241,53,81)",
                    color: "#fff",
                  }}
                  label={intl.formatMessage({ id: "DEVICE.STATUS.OFFLINE" })}
                />
              </span>
            ) : (
              <span style={{ float: "right" }}>
                <Chip
                  size="small"
                  style={{
                    backgroundColor: "rgb(52,175,38)",
                    color: "#fff",
                  }}
                  label={intl.formatMessage({ id: "DEVICE.STATUS.ONLINE" })}
                />
              </span>
            )}
          </div>
          <div className="col-lg-6">
            <IconButton aria-label="Add to favorites">
              <BatteryFullIcon style={{ color: "rgb(53, 193, 241)" }} />
            </IconButton>
            <FormattedMessage id="MAIN.CARD.BATTERY" />
          </div>
          <div className="col-lg-5" style={{ margin: "auto", float: "right" }}>
            <span style={{ float: "right" }}>
              <Chip
                size="small"
                variant="outlined"
                style={{
                  borderColor: "rgb(53, 193, 241)",
                  color: "rgb(53, 193, 241)",
                }}
                label={data.battery_voltage + "V"}
              />
            </span>
          </div>
          <div className="col-lg-6">
            <IconButton aria-label="Add to favorites">
              <RoomIcon style={{ color: "rgb(53, 193, 241)" }} />
            </IconButton>
            <FormattedMessage id="MAIN.CARD.GPS" />
          </div>
          <div className="col-lg-5" style={{ margin: "auto", float: "right" }}>
            <span style={{ float: "right" }}>
              <Chip
                size="small"
                color="primary"
                style={{ color: "#fff" }}
                label={
                  data.lantern_latitude
                    ? `${data.lantern_latitude} , ${data.lantern_longitude}`
                    : "undefined"
                }
              />
            </span>
          </div>
          <div className="col-lg-6">
            <IconButton aria-label="Add to favorites">
              <RoomIcon style={{ color: "rgb(53, 193, 241)" }} />
            </IconButton>
            <FormattedMessage id="MAIN.CARD.SENKRON" />
          </div>
          <div className="col-lg-5" style={{ margin: "auto", float: "right" }}>
            <span style={{ float: "right" }}>
              <Chip
                size="small"
                color="primary"
                style={{ color: "#fff" }}
                label={
                  data.lantern_latitude
                    ? intl.formatMessage({ id: "OPERATIONS.USERS.STATUS.1" })
                    : intl.formatMessage({ id: "OPERATIONS.USERS.STATUS.2" })
                }
              />
            </span>
          </div>
          <div className="col-lg-6">
            <IconButton aria-label="Add to favorites">
              <EmojiObjectsIcon style={{ color: "rgb(53, 193, 241)" }} />
            </IconButton>
            <FormattedMessage id="MAIN.CARD.LANTERNHEALTH" />
          </div>
          <div className="col-lg-5" style={{ margin: "auto", float: "right" }}>
            <span style={{ float: "right" }}>
              <Chip
                size="small"
                color="secondary"
                label={
                  data.lantern_led_health
                    ? intl.formatMessage({ id: "DEVICE.STATUS.LED_HEALTHY" })
                    : intl.formatMessage({ id: "DEVICE.STATUS.LED_UNHEALTHY" })
                }
              />
            </span>
          </div>
          <div className="col-lg-6">
            <IconButton aria-label="Add to favorites">
              <EmojiObjectsIcon style={{ color: "rgb(53, 193, 241)" }} />
            </IconButton>
            <FormattedMessage id="MAIN.CARD.LANTERN" />
          </div>
          <div className="col-lg-5" style={{ margin: "auto", float: "right" }}>
            <span style={{ float: "right" }}>
              <Chip
                size="small"
                color="secondary"
                label={
                  data.lantern_led_active
                    ? intl.formatMessage({ id: "OPERATIONS.USERS.STATUS.1" })
                    : intl.formatMessage({ id: "OPERATIONS.USERS.STATUS.2" })
                }
              />
            </span>
          </div>
          <div className="col-lg-6">
            <IconButton aria-label="Add to favorites">
              <WifiTetheringIcon style={{ color: "rgb(53, 193, 241)" }} />
            </IconButton>
            <FormattedMessage id="MAIN.CARD.LASTSEEN" />
          </div>
          <div className="col-lg-5" style={{ margin: "auto", float: "right" }}>
            <span style={{ float: "right" }}>
              <Chip
                size="small"
                color="secondary"
                label={data.timestamp ? timestamp_to_date : timestamp_to_date}
              />
            </span>
          </div>
          {props.db == "octopus_06202001" ? (
            <>
              <div className="col-lg-6">
                <IconButton aria-label="Add to favorites">
                  <ToysIcon style={{ color: "rgb(53, 193, 241)" }} />
                </IconButton>
                <FormattedMessage id="MAIN.CARD.WIND" />
              </div>
              <div
                className="col-lg-5"
                style={{ margin: "auto", float: "right" }}
              >
                <span style={{ float: "right" }}>
                  <Chip
                    size="small"
                    variant="outlined"
                    style={{
                      borderColor: "rgb(53, 193, 241)",
                      color: "rgb(53, 193, 241)",
                    }}
                    label={data.wind_speed + " kt"}
                  />
                </span>
              </div>
              <div className="col-lg-6">
                <IconButton aria-label="Add to favorites">
                  <GpsFixedIcon style={{ color: "rgb(53, 193, 241)" }} />
                </IconButton>
                <FormattedMessage id="MAIN.CARD.SHIP" />
              </div>
              <div
                className="col-lg-5"
                style={{ margin: "auto", float: "right" }}
              >
                <span style={{ float: "right" }}>
                  <Chip
                    size="small"
                    variant="outlined"
                    style={{
                      borderColor: "rgb(205,185,47)",
                      color: "rgb(205,185,47)",
                    }}
                    label={ais.length}
                  />
                </span>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </CardContent>
    </Card>
  );
}
