import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";
import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    margin: {
        height: theme.spacing(1),
    },
}));

function ValueLabelComponent(props) {
    const {children, open, value} = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

const PrettoSlider = withStyles({
    root: {
        color: "grey",
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: 'red',
        color: "red",
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
        color: "#bababa",
    },
    track: {
        color: '#6f97e3',
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);


export default function CustomizedSlider(props) {

    const classes = useStyles();
    const [sliderVal, setSliderVal] = useState(0);
    const [time, setTime] = useState(0);
    const [allTime, setAllTime] = useState([]);

    const sliVal = (value) => {
        //console.log(value)
        props.value(value)
    }
    function valueLabelFormat(value) {
        return `${props.allTime[value]}`;
    }

    //console.log(props.select)
    return (
        <div className={classes.root} style={{
            display: "flex"
        }}>
            <div className={classes.margin}/>

            <Input
                className={classes.input}
                style={{marginRight: "20px", width: " 100px"}}
                value={props.allTime[props.select != null ? props.select : props.sliderVal]}
                margin="dense"
                readOnly={true}
/*disabled={true}*/
                inputProps={{
                    type: 'time',
                    'aria-labelledby': 'input-slider',
                }}
            />
            <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider"
                          value={props.select != null ? props.select : props.sliderVal}
                          max={props.sliderVal}
                          valueLabelFormat={valueLabelFormat} /*getAriaValueText={e=> props.value(valuetext(e)) }*/
                          onChangeCommitted={(e, val) => sliVal(val)}
            />
            <div className={classes.margin}/>

        </div>
    );
}