import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";
import Table from './Table/Table'
import shipTypes from '../../../helper/shipTypes'
import {useIntl} from 'react-intl'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));


export default function RiskHistory(props) {

    const intl = useIntl();

    const {authToken} = useSelector(state => state.auth);
    const [date, setDate] = useState(parseInt(new Date().getTime() / 1000));
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const [state, setState] = React.useState({
        columns: [
            {title: intl.formatMessage({id: "DEVICE.DETAIL.RISK_HISTORY.SHIP_MMSI"}), field: 'mmsi', filtering: false},
            {title: intl.formatMessage({id: "DEVICE.DETAIL.RISK_HISTORY.SHIP_NAME"}), field: 'shipName', filtering: false /*lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },*/},
            {title: intl.formatMessage({id: "DEVICE.DETAIL.RISK_HISTORY.SHIP_TRANSITION"}), field: 'date', type: 'string', filtering: false},
            {title: intl.formatMessage({id: "DEVICE.DETAIL.RISK_HISTORY.SHIP_SPEED"}), field: 'shipFlag', filtering: false},
            {
                title: intl.formatMessage({id: "DEVICE.STATUS.RISK"}),
                field: 'risk',
                filtering: false,
                render: rowData => <Chip size="small" variant="outlined" style={rowData.risk == "Panding" ? {
                    borderColor: "rgb(53, 193, 241)",
                    color: "rgb(53, 193, 241)"
                } : {borderColor: "rgb(241,53,53)", color: "rgb(241,53,53)"}} label={rowData.risk}/>
            },
            {
                title: intl.formatMessage({id: "DEVICE.DETAIL.RISK_HISTORY.SHIP_DIRECTION"}),
                field: 'shipType',
                filtering: false,/*render: rowData => <ul><li style={rowData.shipType=="Retail"?{color:"rgb(53,116,241)"}:{color:"red"}}>{rowData.shipType}</li></ul>*/
            },

        ],
    });

    const {deviceRiskForMonitoring} = useSelector(
        (state) => ({
            deviceRiskForMonitoring: state.devices.deviceRiskForMonitoring
        }),
        shallowEqual
    );
    useEffect(() => {
        if (deviceRiskForMonitoring) {
            const dataArr = deviceRiskForMonitoring;
            const arr = [];
            dataArr.map((data) => {
                arr.push({
                    mmsi: data.mmsi,
                    shipFlag: data.speed,
                    date: new Date(data.timestamp * 1000).toLocaleString(),
                    risk: data.heading == 511 ? "Danger" : "Panding",
                    shipName: data.shipname ? data.shipname : "Yok",
                    shipType: data.heading
                });
            });
            setData(arr);
            setLoad(true);
        }

    }, [deviceRiskForMonitoring]);

    return (
        <>
            <Table data={data} state={state} load={load} title={"Risk Geçmişi"}/>
        </>);
}