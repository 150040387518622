import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';
import axios from "axios";
import {useSelector} from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";


export default function Table(props) {
    return (
<>
    {props.load
    ?<MaterialTable
        title={props.title}
        columns={props.state.columns}
        data={props.data}
        options={{
            exportButton: true,
            filtering: true,
            actionsColumnIndex: -1,
            pageSizeOptions:[5,25,50,100],
            pageSize:props.pageSize ? props.pageSize: 5,
            //selection: true
        }}
       

        /*editable={{
            onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                        if (oldData) {
                            setState((prevState) => {
                                const data = [...prevState.data];
                                data[data.indexOf(oldData)] = newData;
                                return {...prevState, data};
                            });
                        }
                    }, 600);
                }),
            onRowDelete: (oldData) =>
                new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                        setState((prevState) => {
                            const data = [...prevState.data];
                            data.splice(data.indexOf(oldData), 1);
                            return {...prevState, data};
                        });
                    }, 600);
                }),
        }}*/
    />
:<div style={{    textAlignLast: "center",
            alignSelf: "center"}}>
Loading <br/>
                <CircularProgress color="secondary" />
            </div>
        }
    </>);
}
