import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Map from './map/Map'
import Button from '@material-ui/core/Button';
import PrettoSlider from './slider/Slider'
import Typography from '@material-ui/core/Typography';
import {useSelector} from "react-redux";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";


const useStyles = makeStyles(theme => ({
    card: {
        borderRadius: "0.42rem",
        marginTop: "25px"
    },
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

export default function AisMap(props) {
    const classes = useStyles();

    //-----------State Paramaters Start------------
    const [location, setLocation] = useState([]);
    const [polygon, setPolygon] = useState([]);
    //const [time, setTime] = useState(0);
    const [selectDate, setSelectDate] = useState(new Date().getTime())
    const [sliderVal, setSliderVal] = useState(0);
    const [selectVal, setSelectVal] = useState(null);
    const [allTime, setAllTime] = useState([]);
    const [ais, setAis] = useState([]);
    const [play, setPlay] = useState(false);
    const [live, setLive] = useState(true);
    const [stop, setStop] = useState(false);
    const [date, setDate] = useState(new Date());
    const [liveInterval, setLiveInter] = useState(null);
    const [playInterval, setPlayInter] = useState(null);
    const [shipLine, setShipLine] = useState([]);

    //-----------State Paramaters End------------

    const {authToken} = useSelector(state => state.auth);
    const history = useHistory();
/*
    const year = new Date().getFullYear();
    const month = (new Date().getMonth() + 1) <= 9 ? "0" + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
    const day = new Date().getDate() <= 9 ? "0" + new Date().getDate() + 1 : new Date().getDate();
*/

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/api/buoy_location?token=${authToken}`)
            .then(res => setLocation(res.data))
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 401) {
                        history.push("/logout");
                    }
                }
                console.log(error)
            });
        axios.get(`${process.env.REACT_APP_API}/api/polygon/?token=${authToken}&type=${props.db}`)
            .then((response) => {
                setPolygon(response.data);
            }).catch(error => {
            if (error.response) {
                if (error.response.status == 401) {
                    history.push("/logout");
                }
            }
            console.log(error)
        });
        axios.get(`${process.env.REACT_APP_API}/api/ais`, {
            params: {
                timestamp: selectDate,
                token: authToken,
                type: props.db
            },
        }).then((res) => {
            setAis(res.data.ais);
            setShipLine(res.data.line)
        });
        valuetext(null, selectDate)
        //setDate(year + "-" + month + "-" + day)
        setLiveInter(setInterval(fetchData, 2000))
    }, []);
    const fetchData = () => {
        axios.get(`${process.env.REACT_APP_API}/api/ais`, {
            params: {
                timestamp: parseInt(new Date().getTime() / 1000),
                token: authToken,
                type: props.db
            },
        }).then((res) => {
            setAis(res.data.ais);
            setShipLine(res.data.line);
        }).catch(error => {
            if (error.response) {
                if (error.response.status == 401) {
                    history.push("/logout");
                }
            }
            console.log(error)
        });
        valuetext(null, new Date().getTime())
        setSelectDate(new Date().getTime())
        //console.log(selectDate)
    };

    function valuetext(dat = null, date = null) {

        const selectedDate = date != null ? date : new Date().getTime();
        const times = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
        const minutes = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
        //const second = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
        const arr = [];
        for (let i = 0; i < 24; i += 1) {
            for (let j = 0; j < 60; j += 1) {
                if (new Date(selectedDate).getDate() != new Date().getDate() || new Date(selectedDate).getMonth() != new Date().getMonth()) {

                    arr.push(`${times[i].length === 1 ? `0${times[i]}` : times[i]}:${minutes[j]}`);
                } else if (new Date().getHours() - times[i] > -1) {
                    if (new Date().getHours() - times[i] > 0) {
                        arr.push(`${times[i].length === 1 ? `0${times[i]}` : times[i]}:${minutes[j]}`);
                    }
                    if (new Date().getHours() == times[i] && new Date().getMinutes() >= minutes[j]) {
                        arr.push(`${times[i].length === 1 ? `0${times[i]}` : times[i]}:${minutes[j]}`);

                        /*if (new Date().getSeconds() >= second[k]){
                            arr.push(`${times[i].length === 1 ? `0${times[i]}` : times[i]}:${minutes[j]}:${second[k]}`);

                        }*/
                    }
                }
            }
        }
        setSliderVal(arr.length - 1)
        //console.log(arr.length)
        setAllTime(arr)
        //return arr[value];

    }

    const dateOnChange = (value) => {
        if (!stop) {
            buttonOnClick("stop")
        }
        const val = new Date(value).getTime()
        //const now=new Date().getTime()
        setDate(value)
        setSelectDate(val)
        valuetext(null, val)
        setSelectVal(0)


    }
    const sliderOnChange = (value) => {
        if (!stop) {
            buttonOnClick("stop")
        }
        setSelectVal(value)
        const selectedDate = new Date(selectDate);
        const hour = allTime[value].split(':')[0];
        const min = allTime[value].split(':')[1];
        selectedDate.setHours(parseInt(hour));
        selectedDate.setMinutes(parseInt(min));
        setSelectDate(selectedDate.getTime());

        axios.get(`${process.env.REACT_APP_API}/api/ais`, {
            params: {
                timestamp: parseInt(selectedDate.getTime() / 1000),
                token: authToken,
                type: props.db
            },
        })
            .then((res) => {
                setAis([]);
                setShipLine([]);
                setAis(res.data.ais);
                setShipLine(res.data.line);
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 401) {
                        history.push("/logout");
                    }
                }
                console.log(error)
            });
    }
    const buttonOnClick = (value) => {
        if (value == "stop") {
            setPlay(false);
            setLive(false);
            setStop(true)

            clearInterval(liveInterval);
            clearInterval(playInterval);
            console.log("stop")

        } else if (value == "play") {
            clearInterval(liveInterval);
            //clearInterval(playInterval);

            let sliderValue = selectVal;
            const selectedDate = new Date(selectDate);
            if (!play) {

                var timer = setInterval(() => {
                        if (selectVal !== null ) {
                            const hour = allTime[sliderValue].split(':')[0];
                            const min = allTime[sliderValue].split(':')[1];
                            selectedDate.setHours(parseInt(hour));
                            selectedDate.setMinutes(parseInt(min));
                            setSelectDate(selectedDate.getTime())
                            if (sliderValue != allTime.length - 1) {
                                sliderValue = sliderValue + 1;
                                setSelectVal(sliderValue)
                            } else if (selectedDate.getDate() != new Date().getDate()) {
                                setDate(selectedDate.setDate(selectedDate.getDate() + 1))
                                selectedDate.setHours(0)
                                selectedDate.setMinutes(0)
                                sliderValue = 0
                                //setSelectVal(0)
                                valuetext(null, selectedDate)
                                console.log(selectedDate.getTime())
                            } else {
                                console.log("sa", timer)
                                clearInterval(timer);
                                /*clearInterval(liveInterval);
                                setPlay(false);*/
                                buttonOnClick("live");
                            }
                        } else if (selectedDate.getDate() == new Date().getDate()) {
                            console.log("sa", timer)
                            clearInterval(timer);
                            /*clearInterval(liveInterval);
                            setPlay(false);*/
                            buttonOnClick("stop");
                        }

                        axios.get(`${process.env.REACT_APP_API}/api/ais`, {
                            params: {
                                timestamp: parseInt(selectedDate.getTime() / 1000),
                                token: authToken,
                                type: props.db
                            },
                        })
                            .then((res) => {
                                setAis([]);
                                setShipLine([]);
                                setAis(res.data.ais);
                                setShipLine(res.data.line);
                            })
                            .catch((error) => {
                                throw (error);
                            });

                    },

                    1300)
                setPlayInter(timer)
            }

            setStop(false);
            setLive(false);
            setPlay(true);
            console.log("play")
        } else {
            clearInterval(playInterval);
            if (!live) {
                setLiveInter(setInterval(fetchData, 2000));
            }
            valuetext(null, new Date().getTime())
            setPlay(false);
            setStop(false);
            setLive(true);
            setSelectVal(null)
            setDate(new Date())
            console.log("live")
        }
    }

    return (
        <>
            <div className="col-xxl-12  col-md-12 col-sm-12 col-xs-12 ">
                <div style={{height: "450px", margin: "30px 20px 30px 20px"}}>
                    <Map multiple={true} location={location} polygon={polygon} ais={ais} line={shipLine}/>
                </div>
            </div>
            <div className="col-xxl-3  col-md-6 col-sm-6 col-xs-6 ">
                <div style={{marginTop: "20px"}}>

                    <Button variant="contained" color={live ? "secondary" : "grey"} className={classes.button}
                            onClick={e => buttonOnClick("live")}>
                        Canlı
                    </Button>
                    <Button variant="contained" color={play ? "secondary" : "grey"} className={classes.button}
                            onClick={e => buttonOnClick("play")}>
                        Oynat
                    </Button>
                    <Button variant="contained" color={stop ? "secondary" : "grey"} className={classes.button}
                            onClick={e => buttonOnClick("stop")}>
                        Dur
                    </Button>
                </div>
            </div>
            <div className="col-xxl-3  col-md-6 col-sm-6 col-xs-6 ">
                <div style={{margin: "20px", textAlign: "center", textAlignLast: "center", display: "flex"}}>

                    <Typography variant="overline" gutterBottom style={{marginTop: "6px"}}>Tarih
                        Seçimi:{"  "}</Typography>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            clearable
                            value={date}
                            //placeholder="10/10/2018"
                            onChange={e => dateOnChange(e)}
                            maxDate={new Date()}
                            format="yyyy.MM.dd"
                            style={{marginTop: "6px"}}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>

            {/*<div className="col-xxl-2 col-md-6 col-sm-6 col-xs-6 ">
                    <div style={{margin:"20px"}}>
                        <Typography variant="overline" display="flex" gutterBottom style={{marginTop:"10px"}}>Saat:Dakika  </Typography>
                        <b>{time}</b>
                    </div>
                </div>*/}
            <div className="col-xxl-6 col-md-6 col-sm-6 col-xs-6 ">
                <div style={{margin: "20px"}}>
                    <PrettoSlider value={time => sliderOnChange(time)} sliderVal={sliderVal} select={selectVal}
                                  allTime={allTime}/>
                </div>
            </div>

        </>
    );
}
