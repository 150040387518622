import React, {useEffect, useState, useRef} from 'react';
import {makeStyles, withStyles, useTheme} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import MapIcon from '@material-ui/icons/Map';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Map from './map/Map'
import RiskHistory from './RiskHistory'
import Warnings from './Warnings'
import SystemStatus from './SystemStatus'
import BuoySettings from './BuoySettings'
import NotificationSettings from './NotificationSettings'
import Statistic from './Statistic'
import Sensors from './Sensors'
import DvrIcon from '@material-ui/icons/Dvr';
import SettingsIcon from '@material-ui/icons/Settings';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MemoryIcon from '@material-ui/icons/Memory';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {useHistory} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {FormattedMessage} from "react-intl";
import {shallowEqual, useSelector} from "react-redux";

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})((props) => <Tabs variant="scrollable" scrollButtons="on" aria-label="scrollable force tabs example"
    /*disableripple*/ {...props} />);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        width: 72,

        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);


const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "0.42rem",
        flexGrow: 1,
        margin: 10,
        width: "99%"
    },
    padding: {
        padding: theme.spacing(3),
    },
    demo1: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "0.42rem",
        marginTop: "25px",

    },
}));

function TabContainer({children, dir}) {
    return (
        <Typography component="div" dir={dir} style={{padding: 8 * 3}}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

export default function BuoyMonitoring(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [success, setSuccess] = useState("");
    const [errors, setErrors] = useState("");
    const [userType, setUserType] = useState(false);
    const markerRef = useRef(null);

    const {auth} = useSelector(
        (state) => ({
            auth: state.auth
        }),
        shallowEqual
    );
    const history = useHistory();
    useEffect(() => {
        // console.log('buoy monitoring props', props)
        // console.log(auth)
        setUserType(auth.user.user.is_admin || auth.user.user.is_owner ? true : false)
        if (history.location.hash == "#warning") {
            console.log("sa")
            if (device == "octopus") {
                setValue(5)
            } else {
                setValue(2)
            }
        }
    }, []);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onCloseSnackbar = (e) => {
        setSuccess(e)
    }
    const onErrorSnackbar = (e) => {
        setErrors(e)
    }

    const device = props.db.split("_")[0]

    return (
        <>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={success ? true : false}
                      autoHideDuration={5000} onClose={e => onCloseSnackbar(false)}>
                <Alert variant="filled" severity="success">
                    {success}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={errors ? true : false}
                      autoHideDuration={5000} onClose={e => onErrorSnackbar(false)}>
                <Alert variant="filled" severity="error">
                    {errors}
                </Alert>
            </Snackbar>
            <div className={classes.root}>
                <div className={classes.demo1}>

                    {device === "octopus"
                        ? <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                            <AntTab
                                label={<div style={{display: "flex"}}><MapIcon
                                    style={{marginRight: "5px"}}/>{" "}
                                    <FormattedMessage id="DEVICE.DETAIL.MONITORING.MAP"/>
                                </div>}/>
                            <AntTab
                                label={<div style={{display: "flex"}}><DvrIcon style={{marginRight: "5px"}}/>{" "}
                                    <FormattedMessage id="DEVICE.DETAIL.MONITORING.RISK"/>
                                </div>}/>
                            {/* <AntTab
                                label={<div style={{display: "flex"}}><SettingsIcon
                                    style={{marginRight: "5px"}}/>{" "}
                                    <FormattedMessage id="DEVICE.DETAIL.MONITORING.SETTING"/>
                                </div>}/>*/}
                            {userType ? <AntTab
                                    label={<div style={{display: "flex"}}><SettingsIcon
                                        style={{marginRight: "5px"}}/>{" "}
                                        <FormattedMessage id="DEVICE.DETAIL.MONITORING.NOTIFICATION_SETTING"/>
                                    </div>}/>
                                : ""}
                            <AntTab
                                label={<div style={{display: "flex"}}><EqualizerIcon
                                    style={{marginRight: "5px"}}/>{" "}
                                    <FormattedMessage id="DEVICE.DETAIL.MONITORING.STATISTIC"/>
                                </div>}/>
                            <AntTab
                                label={<div style={{display: "flex"}}><MemoryIcon
                                    style={{marginRight: "5px"}}/>{" "}
                                    <FormattedMessage id="DEVICE.DETAIL.MONITORING.SENSOR"/>
                                </div>}/>
                            <AntTab
                                label={<div style={{display: "flex"}}><ErrorOutlineIcon
                                    style={{marginRight: "5px"}}/>{" "}
                                    <FormattedMessage id="DEVICE.DETAIL.MONITORING.NOTIFICATION"/>
                                </div>}/>
                        </AntTabs>
                        : userType
                            ? <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                                <AntTab
                                    label={<div style={{display: "flex"}}><MapIcon
                                        style={{marginRight: "5px"}}/>{" "}
                                        <FormattedMessage id="DEVICE.DETAIL.MONITORING.MAP"/>
                                    </div>}/>
                                <AntTab
                                    label={<div style={{display: "flex"}}><SettingsIcon
                                        style={{marginRight: "5px"}}/>{" "}
                                        <FormattedMessage id="DEVICE.DETAIL.MONITORING.SETTING"/>
                                    </div>}/>
                                <AntTab
                                    label={<div style={{display: "flex"}}><SettingsIcon
                                        style={{marginRight: "5px"}}/>{" "}
                                        <FormattedMessage id="DEVICE.DETAIL.MONITORING.NOTIFICATION_SETTING"/>
                                    </div>}/>
                                <AntTab
                                    label={<div style={{display: "flex"}}><ErrorOutlineIcon
                                        style={{marginRight: "5px"}}/>{" "}
                                        <FormattedMessage id="DEVICE.DETAIL.MONITORING.HISTORY"/>
                                    </div>}/>
                                <AntTab
                                label={<div style={{display: "flex"}}><ErrorOutlineIcon
                                    style={{marginRight: "5px"}}/>{" "}
                                    <FormattedMessage id="DEVICE.DETAIL.MONITORING.NOTIFICATION"/>
                                </div>}/>
                            </AntTabs>
                            : <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                                <AntTab
                                    label={<div style={{display: "flex"}}><MapIcon
                                        style={{marginRight: "5px"}}/>{" "}
                                        <FormattedMessage id="DEVICE.DETAIL.MONITORING.MAP"/>
                                    </div>}/>
                                <AntTab
                                    label={<div style={{display: "flex"}}><SettingsIcon
                                        style={{marginRight: "5px"}}/>{" "}
                                        <FormattedMessage id="DEVICE.DETAIL.MONITORING.SETTING"/>
                                    </div>}/>
                                <AntTab
                                    label={<div style={{display: "flex"}}><ErrorOutlineIcon
                                        style={{marginRight: "5px"}}/>{" "}
                                        <FormattedMessage id="DEVICE.DETAIL.MONITORING.NOTIFICATION"/>
                                    </div>}/>
                            </AntTabs>}


                    {device === "octopus" ?
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}

                        >
                            <TabContainer dir={theme.direction}>
                                <div style={{height: "400px", margin: 20}}>
                                    <Map lat={props.data.lantern_latitude} lng={props.data.lantern_longitude}
                                         range={props.range} />

                                </div>
                            </TabContainer>
                            <TabContainer dir={theme.direction}><RiskHistory db={props.db}/></TabContainer>

                            <TabContainer dir={theme.direction}><BuoySettings
                                onCloseSnackbar={(e) => onCloseSnackbar(e)} db={props.db}/></TabContainer>


                            {userType ? <TabContainer dir={theme.direction}><NotificationSettings
                                    onCloseSnackbar={(e) => onCloseSnackbar(e)} db={props.db}/></TabContainer>
                                : ""}
                            <TabContainer dir={theme.direction}><Statistic db={props.db}/></TabContainer>
                            <TabContainer dir={theme.direction}><Sensors db={props.db}/></TabContainer>
                            <TabContainer dir={theme.direction}><Warnings db={props.db}/></TabContainer>
                        </SwipeableViews>
                        : userType
                            ? <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}

                            >
                                <TabContainer dir={theme.direction}>
                                    <div style={{height: "70vh", margin: 20}}>
                                        <Map lat={props.data.latitude} lng={props.data.longitude} range={props.range} geofence={props.geofence}/>

                                    </div>
                                </TabContainer>
                                <TabContainer dir={theme.direction}><BuoySettings
                                    onCloseSnackbar={(e) => onCloseSnackbar(e)} db={props.db}
                                    onErrorSnackbar={(e) => onErrorSnackbar(e)} lat={props.data.latitude} lng={props.data.longitude}
                                    collection={props.collection}
                                /></TabContainer>

                                <TabContainer dir={theme.direction}><NotificationSettings
                                    onCloseSnackbar={(e) => onCloseSnackbar(e)} db={props.db}/></TabContainer>
                                <TabContainer dir={theme.direction}><SystemStatus db={props.db} system_status={props.system_status} collection={props.collection}/></TabContainer>
                                <TabContainer dir={theme.direction}><Warnings db={props.db} warning={props.warning} collection={props.collection}/></TabContainer>
                                

                            </SwipeableViews>
                            : <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}

                            >
                                <TabContainer dir={theme.direction}>
                                    <div style={{height: "500px", margin: 20}}>
                                        <Map lat={props.data.latitude} lng={props.data.longitude} range={props.range} geofence={props.geofence}/>

                                    </div>
                                </TabContainer>
                                <TabContainer dir={theme.direction}><BuoySettings
                                    onCloseSnackbar={(e) => onCloseSnackbar(e)} db={props.db}
                                    onErrorSnackbar={(e) => onErrorSnackbar(e)}
                                    collection={props.collection}
                                /></TabContainer>

                                <TabContainer dir={theme.direction}><Warnings db={props.db} warning={props.warning} collection={props.collection}/></TabContainer>
                                
                            </SwipeableViews>}


                </div>
            </div>
        </>
    );
}
