import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Map from '../modules/Devices/component/map/Map'
import AisMap from '../modules/Devices/component/AisMap'
import RiskHistory from '../modules/Devices/component/RiskHistory';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
    card: {
        borderRadius: "0.42rem",
        marginTop: "25px"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

export default function CityBuoysPage() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    return (
        <>
            <div className="row" style={{margin: 20, backgroundColor: "white"}}>
                <AisMap db="octopus_06202001"/>

                <div className="col-xxl-12  col-md-12 col-sm-12 col-xs-12">
                    <div style={{margin: "35px 20px 20px 20px"}}>
                        <Typography variant="h5" gutterBottom>
                            <b>Risk Geçmişi</b>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Kablo Hattı Üstünden Geçiş Yapan Gemiler
                        </Typography>
                        <br/>
                        <RiskHistory db="octopus_06202001"/>
                    </div>
                </div>
            </div>
        </>
    );
}
