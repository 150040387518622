import React, {Suspense, lazy, useEffect} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import Main from "./pages/MainPage";
import BuoyDetailPage from "./pages/BuoyDetailPage";
import CityBuoysPage from "./pages/CityBuoysPage";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as actions from "../app/modules/ECommerce/_redux/products/productsActions";
//import {NotificationsCard} from "../app/modules/Notifications/notification/NotificationsCard"
const UserProfilepage = lazy(() =>
    import("./modules/UserProfile/UserProfilePage")
);
const ECommercePage = lazy(() =>
    import("./modules/ECommerce/pages/eCommercePage")
);
const NotificationPage = lazy(() =>
    import("./modules/Notifications/pages/eCommercePage")
);
export default function BasePage() {
    const {user} = useSelector((state) => state.auth, shallowEqual);
    // Getting curret state of products list from store (Redux)
    const {currentState, authToken} = useSelector(
        (state) => ({
            currentState: state.products,
            authToken: state.auth.authToken
        }),
        shallowEqual
    );
    const {entities} = currentState;

    // Products Redux state
    const dispatch = useDispatch();
    useEffect( () => {
        // server call by queryParams
        dispatch(actions.fetchProducts({token: authToken}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/main"/>
                }
                <ContentRoute path="/main" component={Main}/>
                {entities ?
                    entities.map(data => (
                        data?
                        <ContentRoute path={"/buoy-detail/" + data.code+"*"+data.device_type} component={BuoyDetailPage} key={data.serial_number}/>
                        :""
                    ))
                    : ""}
                <Route path="/user-profile" component={UserProfilepage}/>
                {user.user.is_admin || user.user.is_owner
                    ? <Route path="/operations" component={ECommercePage}/>
                    : ""
                }
                <Route path="/notification" component={NotificationPage}/>
                {/*<Redirect to="error/error-v1"/>*/}
            </Switch>
        </Suspense>
    );
}
