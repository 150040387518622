export default [{"SWITCH": "0", "FLASHCODE": "F (Steady light)", "DATA": []}, {
    "SWITCH": "1",
    "FLASHCODE": "VQ 0.5 S",
    "DATA": [200, 300]
}, {"SWITCH": "2", "FLASHCODE": "VQ 0.6 S", "DATA": [200, 400]}, {
    "SWITCH": "3",
    "FLASHCODE": "VQ 0.6 S",
    "DATA": [300, 300]
}, {"SWITCH": "4", "FLASHCODE": "Q 1 S", "DATA": [200, 800]}, {
    "SWITCH": "5",
    "FLASHCODE": "Q 1 S",
    "DATA": [300, 700]
}, {"SWITCH": "6", "FLASHCODE": "Q 1 S", "DATA": [400, 600]}, {
    "SWITCH": "7",
    "FLASHCODE": "Q 1 S",
    "DATA": [500, 500]
}, {"SWITCH": "8", "FLASHCODE": "Q 1 S", "DATA": [800, 200]}, {
    "SWITCH": "9",
    "FLASHCODE": "Q 1.2 S",
    "DATA": [300, 900]
}, {"SWITCH": "10", "FLASHCODE": "Q 1.2 S", "DATA": [500, 700]}, {
    "SWITCH": "11",
    "FLASHCODE": "Q 1.2 S",
    "DATA": [600, 600]
}, {"SWITCH": "12", "FLASHCODE": "FL 1.5 S", "DATA": [200, 1300]}, {
    "SWITCH": "13",
    "FLASHCODE": "FL 1.5 S",
    "DATA": [300, 1200]
}, {"SWITCH": "14", "FLASHCODE": "FL 1.5 S", "DATA": [400, 1100]}, {
    "SWITCH": "15",
    "FLASHCODE": "FL 1.5 S",
    "DATA": [500, 1000]
}, {"SWITCH": "16", "FLASHCODE": "FL 2 S", "DATA": [200, 1800]}, {
    "SWITCH": "17",
    "FLASHCODE": "FL 2 S",
    "DATA": [300, 1700]
}, {"SWITCH": "18", "FLASHCODE": "FL 2 S", "DATA": [400, 1600]}, {
    "SWITCH": "19",
    "FLASHCODE": "FL 2 S",
    "DATA": [500, 1500]
}, {"SWITCH": "20", "FLASHCODE": "FL 2 S", "DATA": [700, 1300]}, {
    "SWITCH": "21",
    "FLASHCODE": "FL 2 S",
    "DATA": [800, 1200]
}, {"SWITCH": "22", "FLASHCODE": "ISO 2 S", "DATA": [1000, 1000]}, {
    "SWITCH": "23",
    "FLASHCODE": "FL 2.5 S",
    "DATA": [300, 2200]
}, {"SWITCH": "24", "FLASHCODE": "FL 2.5 S", "DATA": [500, 2000]}, {
    "SWITCH": "25",
    "FLASHCODE": "FL 2.5 S",
    "DATA": [1000, 1500]
}, {"SWITCH": "26", "FLASHCODE": "FL 3 S", "DATA": [200, 2800]}, {
    "SWITCH": "27",
    "FLASHCODE": "FL 3 S",
    "DATA": [300, 2700]
}, {"SWITCH": "28", "FLASHCODE": "FL 3 S", "DATA": [400, 2600]}, {
    "SWITCH": "29",
    "FLASHCODE": "FL 3 S",
    "DATA": [500, 2500]
}, {"SWITCH": "30", "FLASHCODE": "FL 3 S", "DATA": [600, 2400]}, {
    "SWITCH": "31",
    "FLASHCODE": "FL 3 S",
    "DATA": [700, 2300]
}, {"SWITCH": "32", "FLASHCODE": "FL 3 S", "DATA": [1000, 2000]}, {
    "SWITCH": "33",
    "FLASHCODE": "ISO 3 S",
    "DATA": [1500, 1500]
}, {"SWITCH": "34", "FLASHCODE": "OC 3 S", "DATA": [2000, 1000]}, {
    "SWITCH": "35",
    "FLASHCODE": "OC 3 S",
    "DATA": [2500, 500]
}, {"SWITCH": "36", "FLASHCODE": "OC 3.5 S", "DATA": [2500, 1000]}, {
    "SWITCH": "37",
    "FLASHCODE": "FL 4 S",
    "DATA": [200, 3800]
}, {"SWITCH": "38", "FLASHCODE": "FL 4 S", "DATA": [300, 3700]}, {
    "SWITCH": "39",
    "FLASHCODE": "FL 4 S",
    "DATA": [400, 3600]
}, {"SWITCH": "40", "FLASHCODE": "FL 4 S", "DATA": [500, 3500]}, {
    "SWITCH": "41",
    "FLASHCODE": "FL 4 S",
    "DATA": [600, 3400]
}, {"SWITCH": "42", "FLASHCODE": "FL 4 S", "DATA": [800, 3200]}, {
    "SWITCH": "43",
    "FLASHCODE": "FL 4 S",
    "DATA": [1000, 3000]
}, {"SWITCH": "44", "FLASHCODE": "FL 4 S", "DATA": [1500, 2500]}, {
    "SWITCH": "45",
    "FLASHCODE": "ISO 4 S",
    "DATA": [2000, 2000]
}, {"SWITCH": "46", "FLASHCODE": "OC 4 S", "DATA": [2500, 1500]}, {
    "SWITCH": "47",
    "FLASHCODE": "OC 4 S",
    "DATA": [3000, 1000]
}, {"SWITCH": "48", "FLASHCODE": "FL 4.3 S", "DATA": [1300, 3000]}, {
    "SWITCH": "49",
    "FLASHCODE": "FL 5 S",
    "DATA": [200, 4800]
}, {"SWITCH": "50", "FLASHCODE": "FL 5 S", "DATA": [300, 4700]}, {
    "SWITCH": "51",
    "FLASHCODE": "FL 5 S",
    "DATA": [500, 4500]
}, {"SWITCH": "52", "FLASHCODE": "FL 5 S", "DATA": [900, 4100]}, {
    "SWITCH": "53",
    "FLASHCODE": "FL 5 S",
    "DATA": [1000, 4000]
}, {"SWITCH": "54", "FLASHCODE": "FL 5 S", "DATA": [1500, 3500]}, {
    "SWITCH": "55",
    "FLASHCODE": "ISO 5 S",
    "DATA": [2500, 2500]
}, {"SWITCH": "56", "FLASHCODE": "LFL 5 S", "DATA": [2000, 3000]}, {
    "SWITCH": "57",
    "FLASHCODE": "OC 5 S",
    "DATA": [3000, 2000]
}, {"SWITCH": "58", "FLASHCODE": "OC 5 S", "DATA": [4000, 1000]}, {
    "SWITCH": "59",
    "FLASHCODE": "OC 5 S",
    "DATA": [4500, 500]
}, {"SWITCH": "60", "FLASHCODE": "FL 6 S", "DATA": [200, 5800]}, {
    "SWITCH": "61",
    "FLASHCODE": "FL 6 S",
    "DATA": [300, 5700]
}, {"SWITCH": "62", "FLASHCODE": "FL 6 S", "DATA": [400, 5600]}, {
    "SWITCH": "63",
    "FLASHCODE": "FL 6 S",
    "DATA": [500, 5500]
}, {"SWITCH": "64", "FLASHCODE": "FL 6 S", "DATA": [600, 5400]}, {
    "SWITCH": "65",
    "FLASHCODE": "FL 6 S",
    "DATA": [1000, 5000]
}, {"SWITCH": "66", "FLASHCODE": "FL 6 S", "DATA": [1200, 4800]}, {
    "SWITCH": "67",
    "FLASHCODE": "FL 6 S",
    "DATA": [1500, 4500]
}, {"SWITCH": "68", "FLASHCODE": "ISO 6 S", "DATA": [3000, 3000]}, {
    "SWITCH": "69",
    "FLASHCODE": "LFL 6 S",
    "DATA": [2000, 4000]
}, {"SWITCH": "70", "FLASHCODE": "OC 6 S", "DATA": [4000, 2000]}, {
    "SWITCH": "71",
    "FLASHCODE": "OC 6 S",
    "DATA": [4500, 1500]
}, {"SWITCH": "72", "FLASHCODE": "OC 6 S", "DATA": [5000, 1000]}, {
    "SWITCH": "73",
    "FLASHCODE": "FL 7 S",
    "DATA": [1000, 6000]
}, {"SWITCH": "74", "FLASHCODE": "FL 7 S", "DATA": [2000, 5000]}, {
    "SWITCH": "75",
    "FLASHCODE": "OC 7 S",
    "DATA": [4500, 2500]
}, {"SWITCH": "76", "FLASHCODE": "FL 7.5 S", "DATA": [500, 7000]}, {
    "SWITCH": "77",
    "FLASHCODE": "FL 7.5 S",
    "DATA": [800, 6700]
}, {"SWITCH": "78", "FLASHCODE": "FL 8 S", "DATA": [500, 7500]}, {
    "SWITCH": "79",
    "FLASHCODE": "FL 8 S",
    "DATA": [1000, 7000]
}, {"SWITCH": "80", "FLASHCODE": "ISO 8 S", "DATA": [4000, 4000]}, {
    "SWITCH": "81",
    "FLASHCODE": "LFL 8 S",
    "DATA": [2000, 6000]
}, {"SWITCH": "82", "FLASHCODE": "OC 8 S", "DATA": [5000, 3000]}, {
    "SWITCH": "83",
    "FLASHCODE": "LFL 8 S",
    "DATA": [3000, 5000]
}, {"SWITCH": "84", "FLASHCODE": "FL 9 S", "DATA": [900, 8100]}, {
    "SWITCH": "85",
    "FLASHCODE": "FL 9 S",
    "DATA": [1000, 8000]
}, {"SWITCH": "86", "FLASHCODE": "OC 9 S", "DATA": [6000, 3000]}, {
    "SWITCH": "87",
    "FLASHCODE": "FL 10 S",
    "DATA": [200, 9800]
}, {"SWITCH": "88", "FLASHCODE": "FL 10 S", "DATA": [300, 9700]}, {
    "SWITCH": "89",
    "FLASHCODE": "FL 10 S",
    "DATA": [500, 9500]
}, {"SWITCH": "90", "FLASHCODE": "FL 10 S", "DATA": [800, 9200]}, {
    "SWITCH": "91",
    "FLASHCODE": "FL 10 S",
    "DATA": [1000, 9000]
}, {"SWITCH": "92", "FLASHCODE": "FL 10 S", "DATA": [1500, 8500]}, {
    "SWITCH": "93",
    "FLASHCODE": "LFL 10 S",
    "DATA": [2000, 8000]
}, {"SWITCH": "94", "FLASHCODE": "LFL 10 S", "DATA": [3000, 7000]}, {
    "SWITCH": "95",
    "FLASHCODE": "ISO 10 S",
    "DATA": [5000, 5000]
}, {"SWITCH": "96", "FLASHCODE": "LFL 10 S", "DATA": [4000, 6000]}, {
    "SWITCH": "97",
    "FLASHCODE": "OC 10 S",
    "DATA": [6000, 4000]
}, {"SWITCH": "98", "FLASHCODE": "OC 10 S", "DATA": [7000, 3000]}, {
    "SWITCH": "99",
    "FLASHCODE": "OC 10 S",
    "DATA": [7500, 2500]
}, {"SWITCH": "100", "FLASHCODE": "FL 12 S", "DATA": [1200, 10800]}, {
    "SWITCH": "101",
    "FLASHCODE": "FL 12 S",
    "DATA": [2500, 9500]
}, {"SWITCH": "102", "FLASHCODE": "LFL 12 S", "DATA": [2000, 10000]}, {
    "SWITCH": "103",
    "FLASHCODE": "FL 15 S",
    "DATA": [1000, 14000]
}, {"SWITCH": "104", "FLASHCODE": "LFL 15 S", "DATA": [4000, 11000]}, {
    "SWITCH": "105",
    "FLASHCODE": "OC 15 S",
    "DATA": [10000, 5000]
}, {"SWITCH": "106", "FLASHCODE": "LFL 20 S", "DATA": [2000, 18000]}, {
    "SWITCH": "107",
    "FLASHCODE": "FL 26 S",
    "DATA": [1000, 25000]
}, {"SWITCH": "108", "FLASHCODE": "FL (2) 4 S", "DATA": [500, 1000, 500, 2000]}, {
    "SWITCH": "109",
    "FLASHCODE": "VQ (2) 4 S",
    "DATA": [200, 1000, 200, 2600]
}, {"SWITCH": "110", "FLASHCODE": "FL (2) 4.5 S", "DATA": [300, 1000, 300, 2900]}, {
    "SWITCH": "111",
    "FLASHCODE": "FL (2) 4.5 S",
    "DATA": [400, 1000, 400, 2700]
}, {"SWITCH": "112", "FLASHCODE": "FL (2) 4.5 S", "DATA": [500, 1000, 500, 2500]}, {
    "SWITCH": "113",
    "FLASHCODE": "FL (2) 5 S",
    "DATA": [200, 800, 200, 3800]
}, {"SWITCH": "114", "FLASHCODE": "FL (2) 5 S", "DATA": [200, 1200, 200, 3400]}, {
    "SWITCH": "115",
    "FLASHCODE": "FL (2) 5 S",
    "DATA": [400, 600, 400, 3600]
}, {"SWITCH": "116", "FLASHCODE": "FL (2) 5 S", "DATA": [500, 1000, 500, 3000]}, {
    "SWITCH": "117",
    "FLASHCODE": "FL (2) 5 S",
    "DATA": [1000, 1000, 1000, 2000]
}, {"SWITCH": "118", "FLASHCODE": "Q (2) 5 S", "DATA": [300, 700, 300, 3700]}, {
    "SWITCH": "119",
    "FLASHCODE": "Q (2) 5 S",
    "DATA": [500, 500, 500, 3500]
}, {"SWITCH": "120", "FLASHCODE": "FL (2) 5.5 S", "DATA": [400, 1400, 400, 3300]}, {
    "SWITCH": "121",
    "FLASHCODE": "FL (2) 6 S",
    "DATA": [300, 600, 1000, 4100]
}, {"SWITCH": "122", "FLASHCODE": "FL (2) 6 S", "DATA": [300, 900, 300, 4500]}, {
    "SWITCH": "123",
    "FLASHCODE": "FL (2) 6 S",
    "DATA": [300, 1000, 300, 4400]
}, {"SWITCH": "124", "FLASHCODE": "FL (2) 6 S", "DATA": [400, 1000, 400, 4200]}, {
    "SWITCH": "125",
    "FLASHCODE": "FL (2) 6 S",
    "DATA": [500, 1000, 500, 4000]
}, {"SWITCH": "126", "FLASHCODE": "FL (2) 6 S", "DATA": [800, 1200, 800, 3200]}, {
    "SWITCH": "127",
    "FLASHCODE": "FL (2) 6 S",
    "DATA": [1000, 1000, 1000, 3000]
}, {"SWITCH": "128", "FLASHCODE": "Q (2) 6 S", "DATA": [300, 700, 300, 4700]}, {
    "SWITCH": "129",
    "FLASHCODE": "FL (2) 7 S",
    "DATA": [1000, 1000, 1000, 4000]
}, {"SWITCH": "130", "FLASHCODE": "FL (2) 8 S", "DATA": [400, 600, 2000, 5000]}, {
    "SWITCH": "131",
    "FLASHCODE": "FL (2) 8 S",
    "DATA": [400, 1000, 400, 6200]
}, {"SWITCH": "132", "FLASHCODE": "FL (2) 8 S", "DATA": [500, 1000, 500, 6000]}, {
    "SWITCH": "133",
    "FLASHCODE": "FL (2) 8 S",
    "DATA": [800, 1200, 2400, 3600]
}, {"SWITCH": "134", "FLASHCODE": "FL (2) 8 S", "DATA": [1000, 1000, 1000, 5000]}, {
    "SWITCH": "135",
    "FLASHCODE": "OC (2) 8 S",
    "DATA": [3000, 2000, 1000, 2000]
}, {"SWITCH": "136", "FLASHCODE": "OC (2) 8 S", "DATA": [5000, 1000, 1000, 1000]}, {
    "SWITCH": "137",
    "FLASHCODE": "VQ (2) 8 S",
    "DATA": [200, 1000, 200, 6600]
}, {"SWITCH": "138", "FLASHCODE": "FL (2) 10 S", "DATA": [400, 1600, 400, 7600]}, {
    "SWITCH": "139",
    "FLASHCODE": "FL (2) 10 S",
    "DATA": [500, 500, 1500, 7500]
}, {"SWITCH": "140", "FLASHCODE": "FL (2) 10 S", "DATA": [500, 1000, 500, 8000]}, {
    "SWITCH": "141",
    "FLASHCODE": "FL (2) 10 S",
    "DATA": [500, 1500, 500, 7500]
}, {"SWITCH": "142", "FLASHCODE": "FL (2) 10 S", "DATA": [500, 2000, 500, 7500]}, {
    "SWITCH": "143",
    "FLASHCODE": "FL (2) 10 S",
    "DATA": [800, 1200, 800, 7200]
}, {"SWITCH": "144", "FLASHCODE": "FL (2) 10 S", "DATA": [1000, 1000, 1000, 7000]}, {
    "SWITCH": "145",
    "FLASHCODE": "FL (2) 10 S",
    "DATA": [1000, 1500, 1000, 6500]
}, {"SWITCH": "146", "FLASHCODE": "Q (2) 10 S", "DATA": [600, 400, 600, 8400]}, {
    "SWITCH": "147",
    "FLASHCODE": "FL (2) 12 S",
    "DATA": [400, 1000, 400, 10200]
}, {"SWITCH": "148", "FLASHCODE": "FL (2) 12 S", "DATA": [500, 1000, 500, 10000]}, {
    "SWITCH": "149",
    "FLASHCODE": "FL (2) 12 S",
    "DATA": [1500, 2000, 1500, 7000]
}, {"SWITCH": "150", "FLASHCODE": "FL (2) 15 S", "DATA": [500, 1500, 2000, 11000]}, {
    "SWITCH": "151",
    "FLASHCODE": "FL (2) 15 S",
    "DATA": [1000, 2000, 1000, 11000]
}, {"SWITCH": "152", "FLASHCODE": "Q (2) 15 S", "DATA": [200, 800, 200, 13800]}, {
    "SWITCH": "153",
    "FLASHCODE": "FL (2) 20 S",
    "DATA": [1000, 3000, 1000, 15000]
}, {"SWITCH": "154", "FLASHCODE": "FL (2) 25 S", "DATA": [1000, 1000, 1000, 22000]}, {
    "SWITCH": "155",
    "FLASHCODE": "Q (3) 5 S",
    "DATA": [500, 500, 500, 500, 500, 2500]
}, {"SWITCH": "156", "FLASHCODE": "VQ (3) 5 S", "DATA": [200, 300, 200, 300, 200, 3800]}, {
    "SWITCH": "157",
    "FLASHCODE": "VQ (3) 5 S",
    "DATA": [300, 200, 300, 200, 300, 3700]
}, {"SWITCH": "158", "FLASHCODE": "VQ (3) 5 S", "DATA": [300, 300, 300, 300, 300, 3500]}, {
    "SWITCH": "159",
    "FLASHCODE": "FL (3) 6 S",
    "DATA": [500, 1000, 500, 1000, 500, 2500]
}, {"SWITCH": "160", "FLASHCODE": "FL (2+1) 6 S", "DATA": [300, 400, 300, 1200, 300, 3500]}, {
    "SWITCH": "161",
    "FLASHCODE": "Q (3) 6 S",
    "DATA": [300, 700, 300, 700, 300, 3700]
}, {"SWITCH": "162", "FLASHCODE": "FL (3) 8 S", "DATA": [500, 1000, 500, 1000, 500, 4500]}, {
    "SWITCH": "163",
    "FLASHCODE": "FL (3) 9 S",
    "DATA": [300, 1000, 300, 1000, 300, 6100]
}, {"SWITCH": "164", "FLASHCODE": "FL (3) 9 S", "DATA": [800, 1200, 800, 1200, 800, 4200]}, {
    "SWITCH": "165",
    "FLASHCODE": "FL (3) 10 S",
    "DATA": [300, 700, 300, 700, 900, 7100]
}, {"SWITCH": "166", "FLASHCODE": "FL (3) 10 S", "DATA": [400, 600, 400, 600, 1200, 6800]}, {
    "SWITCH": "167",
    "FLASHCODE": "FL (3) 10 S",
    "DATA": [500, 500, 500, 500, 500, 7500]
}, {"SWITCH": "168", "FLASHCODE": "FL (3) 10 S", "DATA": [500, 1500, 500, 1500, 500, 5500]}, {
    "SWITCH": "169",
    "FLASHCODE": "FL (3) 10 S",
    "DATA": [600, 600, 600, 600, 600, 7000]
}, {"SWITCH": "170", "FLASHCODE": "FL (3) 10 S", "DATA": [1000, 1000, 1000, 1000, 1000, 5000]}, {
    "SWITCH": "171",
    "FLASHCODE": "FL (2+1) 10 S",
    "DATA": [500, 700, 500, 2100, 500, 5700]
}, {"SWITCH": "172", "FLASHCODE": "OC (3) 10 S", "DATA": [5000, 1000, 1000, 1000, 1000, 1000]}, {
    "SWITCH": "173",
    "FLASHCODE": "Q (3) 10 S",
    "DATA": [300, 700, 300, 700, 300, 7700]
}, {"SWITCH": "174", "FLASHCODE": "FL (2 + 1) 10 S", "DATA": [500, 500, 500, 500, 1500, 6500]}, {
    "SWITCH": "175",
    "FLASHCODE": "FL (3) 12 S",
    "DATA": [500, 1500, 500, 1500, 500, 7500]
}, {"SWITCH": "176", "FLASHCODE": "FL (3) 12 S", "DATA": [500, 2000, 500, 2000, 500, 6500]}, {
    "SWITCH": "177",
    "FLASHCODE": "FL (3) 12 S",
    "DATA": [800, 1200, 800, 1200, 800, 7200]
}, {"SWITCH": "178", "FLASHCODE": "FL (3) 12 S", "DATA": [1000, 1000, 1000, 3000, 1000, 5000]}, {
    "SWITCH": "179",
    "FLASHCODE": "FL (2+1) 12 S",
    "DATA": [800, 1200, 800, 2400, 800, 6000]
}, {"SWITCH": "180", "FLASHCODE": "FL (2+1) 12 S", "DATA": [1000, 1000, 1000, 4000, 1000, 4000]}, {
    "SWITCH": "181",
    "FLASHCODE": "FL (2+1) 13.5 S",
    "DATA": [1000, 1000, 1000, 4000, 1000, 5500]
}, {"SWITCH": "182", "FLASHCODE": "FL (3) 15 S", "DATA": [300, 1700, 300, 1700, 300, 10700]}, {
    "SWITCH": "183",
    "FLASHCODE": "FL (3) 15 S",
    "DATA": [400, 1000, 400, 1000, 400, 11800]
}, {"SWITCH": "184", "FLASHCODE": "FL (3) 15 S", "DATA": [500, 1500, 500, 1500, 500, 10500]}, {
    "SWITCH": "185",
    "FLASHCODE": "FL (2+1) 15 S",
    "DATA": [600, 300, 600, 300, 1400, 11800]
}, {"SWITCH": "186", "FLASHCODE": "FL (2+1) 15 S", "DATA": [700, 500, 700, 500, 1900, 10700]}, {
    "SWITCH": "187",
    "FLASHCODE": "FL (2+1) 15 S",
    "DATA": [700, 700, 700, 700, 2100, 10100]
}, {"SWITCH": "188", "FLASHCODE": "FL (2+1) 15 S", "DATA": [1000, 2000, 1000, 5000, 1000, 5000]}, {
    "SWITCH": "189",
    "FLASHCODE": "VQ (3) 15 S",
    "DATA": [100, 500, 100, 500, 100, 13700]
}, {"SWITCH": "190", "FLASHCODE": "FL (3) 20 S", "DATA": [500, 3000, 500, 3000, 500, 12500]}, {
    "SWITCH": "191",
    "FLASHCODE": "FL (3) 20 S",
    "DATA": [500, 1500, 500, 1500, 500, 15500]
}, {"SWITCH": "192", "FLASHCODE": "FL (3) 20 S", "DATA": [800, 1200, 800, 1200, 500, 15200]}, {
    "SWITCH": "193",
    "FLASHCODE": "FL (3) 20 S",
    "DATA": [1000, 1000, 1000, 1000, 1000, 15000]
}, {"SWITCH": "194", "FLASHCODE": "VQ (4) 4 S", "DATA": [300, 300, 300, 300, 300, 300, 300, 1900]}, {
    "SWITCH": "195",
    "FLASHCODE": "Q (4) 6 S",
    "DATA": [300, 700, 300, 700, 300, 700, 300, 2700]
}, {"SWITCH": "196", "FLASHCODE": "Q (4) 6 S", "DATA": [400, 600, 400, 600, 400, 600, 400, 2600]}, {
    "SWITCH": "197",
    "FLASHCODE": "FL (4) 10 S",
    "DATA": [500, 1000, 500, 1000, 500, 1000, 500, 5000]
}, {
    "SWITCH": "198",
    "FLASHCODE": "FL (4) 10 S",
    "DATA": [800, 1200, 800, 1200, 800, 1200, 800, 3200]
}, {"SWITCH": "199", "FLASHCODE": "Q (4) 10 S", "DATA": [300, 700, 300, 700, 300, 700, 300, 6700]}, {
    "SWITCH": "200",
    "FLASHCODE": "FL (4) 12 S",
    "DATA": [300, 1700, 300, 1700, 300, 1700, 300, 5700]
}, {"SWITCH": "201", "FLASHCODE": "FL (4) 12 S", "DATA": [500, 500, 500, 500, 500, 500, 500, 8500]}, {
    "SWITCH": "202",
    "FLASHCODE": "FL (4) 12 S",
    "DATA": [500, 1500, 500, 1500, 500, 1500, 500, 5500]
}, {
    "SWITCH": "203",
    "FLASHCODE": "FL (4) 12 S",
    "DATA": [800, 1200, 800, 1200, 800, 1200, 800, 5200]
}, {"SWITCH": "204", "FLASHCODE": "Q (4) 12 S", "DATA": [300, 700, 300, 700, 300, 700, 300, 8700]}, {
    "SWITCH": "205",
    "FLASHCODE": "FL (4) 15 S",
    "DATA": [500, 1500, 500, 1500, 500, 1500, 500, 8500]
}, {
    "SWITCH": "206",
    "FLASHCODE": "FL (4) 15 S",
    "DATA": [1000, 1000, 1000, 1000, 1000, 1000, 1000, 8000]
}, {"SWITCH": "207", "FLASHCODE": "FL (4) 15 S", "DATA": [1500, 500, 500, 500, 500, 500, 500, 10500]}, {
    "SWITCH": "208",
    "FLASHCODE": "FL (4) 16 S",
    "DATA": [500, 1500, 500, 1500, 500, 1500, 500, 9500]
}, {
    "SWITCH": "209",
    "FLASHCODE": "FL (4) 20 S",
    "DATA": [300, 3000, 300, 3000, 300, 3000, 300, 9800]
}, {
    "SWITCH": "210",
    "FLASHCODE": "FL (4) 20 S",
    "DATA": [500, 1500, 500, 1500, 500, 1500, 500, 13500]
}, {
    "SWITCH": "211",
    "FLASHCODE": "FL (4) 20 S",
    "DATA": [500, 1500, 500, 1500, 500, 4500, 500, 10500]
}, {
    "SWITCH": "212",
    "FLASHCODE": "FL (4) 20 S",
    "DATA": [1500, 1500, 1500, 1500, 1500, 1500, 1500, 9500]
}, {"SWITCH": "213", "FLASHCODE": "Q (4) 20 S", "DATA": [500, 500, 500, 500, 500, 500, 500, 16500]}, {
    "SWITCH": "214",
    "FLASHCODE": "Q (4) 28 S",
    "DATA": [500, 500, 500, 500, 500, 500, 500, 24500]
}, {"SWITCH": "215", "FLASHCODE": "FL (4) 30 S", "DATA": [500, 500, 500, 500, 500, 500, 500, 26500]}, {
    "SWITCH": "216",
    "FLASHCODE": "Q (5) 7 S",
    "DATA": [300, 700, 300, 700, 300, 700, 300, 700, 300, 2700]
}, {
    "SWITCH": "217",
    "FLASHCODE": "Q (5) 10 S",
    "DATA": [300, 700, 300, 700, 300, 700, 300, 700, 300, 5700]
}, {
    "SWITCH": "218",
    "FLASHCODE": "FL (5) 16.5 S",
    "DATA": [5000, 1500, 500, 1500, 500, 1500, 500, 1500, 500, 3500]
}, {
    "SWITCH": "219",
    "FLASHCODE": "FL (5) 20 S",
    "DATA": [500, 500, 500, 500, 500, 500, 500, 500, 500, 15500]
}, {
    "SWITCH": "220",
    "FLASHCODE": "FL (5) 20 S",
    "DATA": [800, 1200, 800, 1200, 800, 1200, 800, 1200, 800, 11200]
}, {
    "SWITCH": "221",
    "FLASHCODE": "FL (5) 20 S",
    "DATA": [1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 11000]
}, {
    "SWITCH": "222",
    "FLASHCODE": "Q (6) 10 S",
    "DATA": [300, 700, 300, 700, 300, 700, 300, 700, 300, 700, 300, 4700]
}, {
    "SWITCH": "223",
    "FLASHCODE": "FL (6) 15 S",
    "DATA": [300, 700, 300, 700, 300, 700, 300, 700, 300, 700, 300, 9700]
}, {
    "SWITCH": "224",
    "FLASHCODE": "FL (6) 15 S",
    "DATA": [500, 1000, 500, 1000, 500, 1000, 500, 1000, 500, 1000, 500, 7000]
}, {
    "SWITCH": "225",
    "FLASHCODE": "FL (6) + LFL 15 S",
    "DATA": [500, 1000, 500, 1000, 500, 1000, 500, 1000, 500, 1000, 500, 7000]
}, {
    "SWITCH": "226",
    "FLASHCODE": "VQ (6) + LFL 10 S",
    "DATA": [200, 300, 200, 300, 200, 300, 200, 300, 200, 300, 200, 300, 2000, 5000]
}, {
    "SWITCH": "227",
    "FLASHCODE": "VQ (6) + LFL 10 S",
    "DATA": [300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 2000, 4400]
}, {
    "SWITCH": "228",
    "FLASHCODE": "Q (6) + LFL 15 S",
    "DATA": [200, 800, 200, 800, 200, 800, 200, 800, 200, 800, 200, 800, 2000, 7000]
}, {
    "SWITCH": "229",
    "FLASHCODE": "Q (6) + LFL 15 S",
    "DATA": [300, 700, 300, 700, 300, 700, 300, 700, 300, 700, 300, 700, 2000, 5000]
}, {
    "SWITCH": "230",
    "FLASHCODE": "Q (6) + LFL 15 S",
    "DATA": [600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 2000, 5800]
}, {
    "SWITCH": "231",
    "FLASHCODE": "VQ (6) + LFL 15 S",
    "DATA": [300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 2000, 9400]
}, {
    "SWITCH": "232",
    "FLASHCODE": "VQ (9) 10 S",
    "DATA": [200, 300, 200, 300, 200, 300, 200, 300, 200, 300, 200, 300, 200, 300, 200, 300, 200, 5800]
}, {
    "SWITCH": "233",
    "FLASHCODE": "VQ (9) 10 S",
    "DATA": [300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 4900]
}, {
    "SWITCH": "234",
    "FLASHCODE": "Q (9) 15 S",
    "DATA": [200, 800, 200, 800, 200, 800, 200, 800, 200, 800, 200, 800, 200, 800, 200, 800, 200, 6800]
}, {
    "SWITCH": "235",
    "FLASHCODE": "Q (9) 15 S",
    "DATA": [300, 700, 300, 700, 300, 700, 300, 700, 300, 700, 300, 700, 300, 700, 300, 700, 300, 6700]
}, {
    "SWITCH": "236",
    "FLASHCODE": "Q (9) 15 S",
    "DATA": [600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 4800]
}, {"SWITCH": "237", "FLASHCODE": "MO (A) 6 S", "DATA": [300, 600, 1000, 4100]}, {
    "SWITCH": "238",
    "FLASHCODE": "MO (A) 8 S",
    "DATA": [400, 600, 2000, 5000]
}, {"SWITCH": "239", "FLASHCODE": "MO (A) 8 S", "DATA": [800, 1200, 2400, 3600]}, {
    "SWITCH": "240",
    "FLASHCODE": "MO (U) 10 S",
    "DATA": [300, 700, 300, 700, 900, 7100]
}, {"SWITCH": "241", "FLASHCODE": "MO (U) 10 S", "DATA": [400, 600, 400, 600, 1200, 6800]}, {
    "SWITCH": "242",
    "FLASHCODE": "MO (U) 10 S",
    "DATA": [500, 500, 500, 500, 1500, 6500]
}, {"SWITCH": "243", "FLASHCODE": "MO (A) 10 S", "DATA": [500, 500, 1500, 7500]}, {
    "SWITCH": "244",
    "FLASHCODE": "MO (D) 10 S",
    "DATA": [5000, 1000, 1000, 1000, 1000, 1000]
}, {"SWITCH": "245", "FLASHCODE": "MO (A) 15 S", "DATA": [500, 1500, 2000, 11000]}, {
    "SWITCH": "246",
    "FLASHCODE": "MO (U) 15 S",
    "DATA": [600, 300, 600, 300, 1400, 11800]
}, {"SWITCH": "247", "FLASHCODE": "MO (U) 15 S", "DATA": [700, 500, 700, 500, 1900, 10700]}, {
    "SWITCH": "248",
    "FLASHCODE": "MO (U) 15 S",
    "DATA": [700, 700, 700, 700, 2100, 10100]
}, {"SWITCH": "249", "FLASHCODE": "MO (B) 15 S", "DATA": [1500, 500, 500, 500, 500, 500, 500, 10500]}, {
    "SWITCH": "250",
    "FLASHCODE": "MO (F) 15 S",
    "DATA": [500, 500, 500, 500, 1500, 500, 500, 10500]
}, {"SWITCH": "255", "FLASHCODE": "", "DATA": []}]
/*
[
    {
        "SWITCH": "0",
        "FLASHCODE": "F (Steady light)",
        "DATA": []
    },
    {
        "SWITCH": "1",
        "FLASHCODE": "VQ 0.5 S",
        "DATA": ["0.2", "0.3"]
    },
    {
        "SWITCH": "2",
        "FLASHCODE": "VQ 0.6 S",
        "DATA": ["0.2", "0.4"]
    },
    {
        "SWITCH": "3",
        "FLASHCODE": "VQ 0.6 S",
        "DATA": ["0.3", "0.3"]
    },
    {
        "SWITCH": "4",
        "FLASHCODE": "Q 1 S",
        "DATA": ["0.2", "0.8"]
    },
    {
        "SWITCH": "5",
        "FLASHCODE": "Q 1 S",
        "DATA": ["0.3", "0.7"]
    },
    {
        "SWITCH": "6",
        "FLASHCODE": "Q 1 S",
        "DATA": ["0.4", "0.6"]
    },
    {
        "SWITCH": "7",
        "FLASHCODE": "Q 1 S",
        "DATA": ["0.5", "0.5"]
    },
    {
        "SWITCH": "8",
        "FLASHCODE": "Q 1 S",
        "DATA": ["0.8", "0.2"]
    },
    {
        "SWITCH": "9",
        "FLASHCODE": "Q 1.2 S",
        "DATA": ["0.3", "0.9"]
    },
    {
        "SWITCH": "10",
        "FLASHCODE": "Q 1.2 S",
        "DATA": ["0.5", "0.7"]
    },
    {
        "SWITCH": "11",
        "FLASHCODE": "Q 1.2 S",
        "DATA": ["0.6", "0.6"]
    },
    {
        "SWITCH": "12",
        "FLASHCODE": "FL 1.5 S",
        "DATA": ["0.2", "1.3"]
    },
    {
        "SWITCH": "13",
        "FLASHCODE": "FL 1.5 S",
        "DATA": ["0.3", "1.2"]
    },
    {
        "SWITCH": "14",
        "FLASHCODE": "FL 1.5 S",
        "DATA": ["0.4", "1.1"]
    },
    {
        "SWITCH": "15",
        "FLASHCODE": "FL 1.5 S",
        "DATA": ["0.5", "1"]
    },
    {
        "SWITCH": "16",
        "FLASHCODE": "FL 2 S",
        "DATA": ["0.2", "1.8"]
    },
    {
        "SWITCH": "17",
        "FLASHCODE": "FL 2 S",
        "DATA": ["0.3", "1.7"]
    },
    {
        "SWITCH": "18",
        "FLASHCODE": "FL 2 S",
        "DATA": ["0.4", "1.6"]
    },
    {
        "SWITCH": "19",
        "FLASHCODE": "FL 2 S",
        "DATA": ["0.5", "1.5"]
    },
    {
        "SWITCH": "20",
        "FLASHCODE": "FL 2 S",
        "DATA": ["0.7", "1.3"]
    },
    {
        "SWITCH": "21",
        "FLASHCODE": "FL 2 S",
        "DATA": ["0.8", "1.2"]
    },
    {
        "SWITCH": "22",
        "FLASHCODE": "ISO 2 S",
        "DATA": ["1", "1"]
    },
    {
        "SWITCH": "23",
        "FLASHCODE": "FL 2.5 S",
        "DATA": ["0.3", "2.2"]
    },
    {
        "SWITCH": "24",
        "FLASHCODE": "FL 2.5 S",
        "DATA": ["0.5", "2"]
    },
    {
        "SWITCH": "25",
        "FLASHCODE": "FL 2.5 S",
        "DATA": ["1", "1.5"]
    },
    {
        "SWITCH": "26",
        "FLASHCODE": "FL 3 S",
        "DATA": ["0.2", "2.8"]
    },
    {
        "SWITCH": "27",
        "FLASHCODE": "FL 3 S",
        "DATA": ["0.3", "2.7"]
    },
    {
        "SWITCH": "28",
        "FLASHCODE": "FL 3 S",
        "DATA": ["0.4", "2.6"]
    },
    {
        "SWITCH": "29",
        "FLASHCODE": "FL 3 S",
        "DATA": ["0.5", "2.5"]
    },
    {
        "SWITCH": "30",
        "FLASHCODE": "FL 3 S",
        "DATA": ["0.6", "2.4"]
    },
    {
        "SWITCH": "31",
        "FLASHCODE": "FL 3 S",
        "DATA": ["0.7", "2.3"]
    },
    {
        "SWITCH": "32",
        "FLASHCODE": "FL 3 S",
        "DATA": ["1", "2"]
    },
    {
        "SWITCH": "33",
        "FLASHCODE": "ISO 3 S",
        "DATA": ["1.5", "1.5"]
    },
    {
        "SWITCH": "34",
        "FLASHCODE": "OC 3 S",
        "DATA": ["2", "1"]
    },
    {
        "SWITCH": "35",
        "FLASHCODE": "OC 3 S",
        "DATA": ["2.5", "0.5"]
    },
    {
        "SWITCH": "36",
        "FLASHCODE": "OC 3.5 S",
        "DATA": ["2.5", "1"]
    },
    {
        "SWITCH": "37",
        "FLASHCODE": "FL 4 S",
        "DATA": ["0.2", "3.8"]
    },
    {
        "SWITCH": "38",
        "FLASHCODE": "FL 4 S",
        "DATA": ["0.3", "3.7"]
    },
    {
        "SWITCH": "39",
        "FLASHCODE": "FL 4 S",
        "DATA": ["0.4", "3.6"]
    },
    {
        "SWITCH": "40",
        "FLASHCODE": "FL 4 S",
        "DATA": ["0.5", "3.5"]
    },
    {
        "SWITCH": "41",
        "FLASHCODE": "FL 4 S",
        "DATA": ["0.6", "3.4"]
    },
    {
        "SWITCH": "42",
        "FLASHCODE": "FL 4 S",
        "DATA": ["0.8", "3.2"]
    },
    {
        "SWITCH": "43",
        "FLASHCODE": "FL 4 S",
        "DATA": ["1", "3"]
    },
    {
        "SWITCH": "44",
        "FLASHCODE": "FL 4 S",
        "DATA": ["1.5", "2.5"]
    },
    {
        "SWITCH": "45",
        "FLASHCODE": "ISO 4 S",
        "DATA": ["2", "2"]
    },
    {
        "SWITCH": "46",
        "FLASHCODE": "OC 4 S",
        "DATA": ["2.5", "1.5"]
    },
    {
        "SWITCH": "47",
        "FLASHCODE": "OC 4 S",
        "DATA": ["3", "1"]
    },
    {
        "SWITCH": "48",
        "FLASHCODE": "FL 4.3 S",
        "DATA": ["1.3", "3"]
    },
    {
        "SWITCH": "49",
        "FLASHCODE": "FL 5 S",
        "DATA": ["0.2", "4.8"]
    },
    {
        "SWITCH": "50",
        "FLASHCODE": "FL 5 S",
        "DATA": ["0.3", "4.7"]
    },
    {
        "SWITCH": "51",
        "FLASHCODE": "FL 5 S",
        "DATA": ["0.5", "4.5"]
    },
    {
        "SWITCH": "52",
        "FLASHCODE": "FL 5 S",
        "DATA": ["0.9", "4.1"]
    },
    {
        "SWITCH": "53",
        "FLASHCODE": "FL 5 S",
        "DATA": ["1", "4"]
    },
    {
        "SWITCH": "54",
        "FLASHCODE": "FL 5 S",
        "DATA": ["1.5", "3.5"]
    },
    {
        "SWITCH": "55",
        "FLASHCODE": "ISO 5 S",
        "DATA": ["2.5", "2.5"]
    },
    {
        "SWITCH": "56",
        "FLASHCODE": "LFL 5 S",
        "DATA": ["2", "3"]
    },
    {
        "SWITCH": "57",
        "FLASHCODE": "OC 5 S",
        "DATA": ["3", "2"]
    },
    {
        "SWITCH": "58",
        "FLASHCODE": "OC 5 S",
        "DATA": ["4", "1"]
    },
    {
        "SWITCH": "59",
        "FLASHCODE": "OC 5 S",
        "DATA": ["4.5", "0.5"]
    },
    {
        "SWITCH": "60",
        "FLASHCODE": "FL 6 S",
        "DATA": ["0.2", "5.8"]
    },
    {
        "SWITCH": "61",
        "FLASHCODE": "FL 6 S",
        "DATA": ["0.3", "5.7"]
    },
    {
        "SWITCH": "62",
        "FLASHCODE": "FL 6 S",
        "DATA": ["0.4", "5.6"]
    },
    {
        "SWITCH": "63",
        "FLASHCODE": "FL 6 S",
        "DATA": ["0.5", "5.5"]

    },
    {
        "SWITCH": "64",
        "FLASHCODE": "FL 6 S",
        "DATA": ["0.6", "5.4"]
    },
    {
        "SWITCH": "65",
        "FLASHCODE": "FL 6 S",
        "DATA": ["1", "5"]
    },
    {
        "SWITCH": "66",
        "FLASHCODE": "FL 6 S",
        "DATA": ["1.2", "4.8"]
    },
    {
        "SWITCH": "67",
        "FLASHCODE": "FL 6 S",
        "DATA": ["1.5", "4.5"]
    },
    {
        "SWITCH": "68",
        "FLASHCODE": "ISO 6 S",
        "DATA": ["3", "3"]
    },
    {
        "SWITCH": "69",
        "FLASHCODE": "LFL 6 S",
        "DATA": ["2", "4"]
    },
    {
        "SWITCH": "70",
        "FLASHCODE": "OC 6 S",
        "DATA": ["4", "2"]
    },
    {
        "SWITCH": "71",
        "FLASHCODE": "OC 6 S",
        "DATA": ["4.5", "1.5"]
    },
    {
        "SWITCH": "72",
        "FLASHCODE": "OC 6 S",
        "DATA": ["5", "1"]
    },
    {
        "SWITCH": "73",
        "FLASHCODE": "FL 7 S",
        "DATA": ["1", "6"]
    },
    {
        "SWITCH": "74",
        "FLASHCODE": "FL 7 S",
        "DATA": ["2", "5"]
    },
    {
        "SWITCH": "75",
        "FLASHCODE": "OC 7 S",
        "DATA": ["4.5", "2.5"]
    },
    {
        "SWITCH": "76",
        "FLASHCODE": "FL 7.5 S",
        "DATA": ["0.5", "7"]
    },
    {
        "SWITCH": "77",
        "FLASHCODE": "FL 7.5 S",
        "DATA": ["0.8", "6.7"]
    },
    {
        "SWITCH": "78",
        "FLASHCODE": "FL 8 S",
        "DATA": ["0.5", "7.5"]
    },
    {
        "SWITCH": "79",
        "FLASHCODE": "FL 8 S",
        "DATA": ["1", "7"]
    },
    {
        "SWITCH": "80",
        "FLASHCODE": "ISO 8 S",
        "DATA": ["4", "4"]
    },
    {
        "SWITCH": "81",
        "FLASHCODE": "LFL 8 S",
        "DATA": ["2", "6"]
    },
    {
        "SWITCH": "82",
        "FLASHCODE": "OC 8 S",
        "DATA": ["5", "3"]
    },
    {
        "SWITCH": "83",
        "FLASHCODE": "LFL 8 S",
        "DATA": ["3", "5"]
    },
    {
        "SWITCH": "84",
        "FLASHCODE": "FL 9 S",
        "DATA": ["0.9", "8.1"]
    },
    {
        "SWITCH": "85",
        "FLASHCODE": "FL 9 S",
        "DATA": ["1", "8"]
    },
    {
        "SWITCH": "86",
        "FLASHCODE": "OC 9 S",
        "DATA": ["6", "3"]
    },
    {
        "SWITCH": "87",
        "FLASHCODE": "FL 10 S",
        "DATA": ["0.2", "9.8"]
    },
    {
        "SWITCH": "88",
        "FLASHCODE": "FL 10 S",
        "DATA": ["0.3", "9.7"]
    },
    {
        "SWITCH": "89",
        "FLASHCODE": "FL 10 S",
        "DATA": ["0.5", "9.5"]
    },
    {
        "SWITCH": "90",
        "FLASHCODE": "FL 10 S",
        "DATA": ["0.8", "9.2"]
    },
    {
        "SWITCH": "91",
        "FLASHCODE": "FL 10 S",
        "DATA": ["1", "9"]
    },
    {
        "SWITCH": "92",
        "FLASHCODE": "FL 10 S",
        "DATA": ["1.5", "8.5"]
    },
    {
        "SWITCH": "93",
        "FLASHCODE": "LFL 10 S",
        "DATA": ["2", "8"]
    },
    {
        "SWITCH": "94",
        "FLASHCODE": "LFL 10 S",
        "DATA": ["3", "7"]
    },
    {
        "SWITCH": "95",
        "FLASHCODE": "ISO 10 S",
        "DATA": ["5", "5"]
    },
    {
        "SWITCH": "96",
        "FLASHCODE": "LFL 10 S",
        "DATA": ["4", "6"]
    },
    {
        "SWITCH": "97",
        "FLASHCODE": "OC 10 S",
        "DATA": ["6", "4"]
    },
    {
        "SWITCH": "98",
        "FLASHCODE": "OC 10 S",
        "DATA": ["7", "3"]
    },
    {
        "SWITCH": "99",
        "FLASHCODE": "OC 10 S",
        "DATA": ["7.5", "2.5"]
    },
    {
        "SWITCH": "100",
        "FLASHCODE": "FL 12 S",
        "DATA": ["1.2", "10.8"]
    },
    {
        "SWITCH": "101",
        "FLASHCODE": "FL 12 S",
        "DATA": ["2.5", "9.5"]
    },
    {
        "SWITCH": "102",
        "FLASHCODE": "LFL 12 S",
        "DATA": ["2", "10"]
    },
    {
        "SWITCH": "103",
        "FLASHCODE": "FL 15 S",
        "DATA": ["1", "14"]
    },
    {
        "SWITCH": "104",
        "FLASHCODE": "LFL 15 S",
        "DATA": ["4", "11"]
    },
    {
        "SWITCH": "105",
        "FLASHCODE": "OC 15 S",
        "DATA": ["10", "5"]
    },
    {
        "SWITCH": "106",
        "FLASHCODE": "LFL 20 S",
        "DATA": ["2", "18"]
    },
    {
        "SWITCH": "107",
        "FLASHCODE": "FL 26 S",
        "DATA": ["1", "25"]
    },
    {
        "SWITCH": "108",
        "FLASHCODE": "FL (2) 4 S",
        "DATA": ["0.5", "1", "0.5", "2"]
    },
    {
        "SWITCH": "109",
        "FLASHCODE": "VQ (2) 4 S",
        "DATA": ["0.2", "1", "0.2", "2.6"]

    },
    {
        "SWITCH": "110",
        "FLASHCODE": "FL (2) 4.5 S",
        "DATA": ["0.3", "1", "0.3", "2.9"]

    },
    {
        "SWITCH": "111",
        "FLASHCODE": "FL (2) 4.5 S",
        "DATA": ["0.4", "1", "0.4", "2.7"]

    },
    {
        "SWITCH": "112",
        "FLASHCODE": "FL (2) 4.5 S",
        "DATA": ["0.5", "1", "0.5", "2.5"]

    },
    {
        "SWITCH": "113",
        "FLASHCODE": "FL (2) 5 S",
        "DATA": ["0.2", "0.8", "0.2", "3.8"]

    },
    {
        "SWITCH": "114",
        "FLASHCODE": "FL (2) 5 S",
        "DATA": ["0.2", "1.2", "0.2", "3.4"]

    },
    {
        "SWITCH": "115",
        "FLASHCODE": "FL (2) 5 S",
        "DATA": ["0.4", "0.6", "0.4", "3.6"]

    },
    {
        "SWITCH": "116",
        "FLASHCODE": "FL (2) 5 S",
        "DATA": ["0.5", "1", "0.5", "3"]

    },
    {
        "SWITCH": "117",
        "FLASHCODE": "FL (2) 5 S",
        "DATA": ["1", "1", "1", "2"]
    },
    {
        "SWITCH": "118",
        "FLASHCODE": "Q (2) 5 S",
        "DATA": ["0.3", "0.7", "0.3", "3.7"]

    },
    {
        "SWITCH": "119",
        "FLASHCODE": "Q (2) 5 S",
        "DATA": ["0.5", "0.5", "0.5", "3.5"]

    },
    {
        "SWITCH": "120",
        "FLASHCODE": "FL (2) 5.5 S",
        "DATA": ["0.4", "1.4", "0.4", "3.3"]
    },
    {
        "SWITCH": "121",
        "FLASHCODE": "FL (2) 6 S",
        "DATA": ["0.3", "0.6", "1", "4.1"]

    },
    {
        "SWITCH": "122",
        "FLASHCODE": "FL (2) 6 S",
        "DATA": ["0.3", "0.9", "0.3", "4.5"]
    },
    {
        "SWITCH": "123",
        "FLASHCODE": "FL (2) 6 S",
        "DATA": ["0.3", "1", "0.3", "4.4"]

    },
    {
        "SWITCH": "124",
        "FLASHCODE": "FL (2) 6 S",
        "DATA": ["0.4", "1", "0.4", "4.2"]

    },
    {
        "SWITCH": "125",
        "FLASHCODE": "FL (2) 6 S",
        "DATA": ["0.5", "1", "0.5", "4"]

    },
    {
        "SWITCH": "126",
        "FLASHCODE": "FL (2) 6 S",
        "DATA": ["0.8", "1.2", "0.8", "3.2"]

    },
    {
        "SWITCH": "127",
        "FLASHCODE": "FL (2) 6 S",
        "ON_1": "1",
        "OFF_1": "1",
        "ON_2": "1",
        "OFF_2": "3",
        "DATA": ["1", "1", "1", "3"]

    },
    {
        "SWITCH": "128",
        "FLASHCODE": "Q (2) 6 S",
        "ON_1": "0.3",
        "OFF_1": "0.7",
        "ON_2": "0.3",
        "OFF_2": "4.7",
        "DATA": ["0.3", "0.7", "0.3", "4.7"]

    },
    {
        "SWITCH": "129",
        "FLASHCODE": "FL (2) 7 S",
        "DATA": ["1", "1", "1", "4"]

    },
    {
        "SWITCH": "130",
        "FLASHCODE": "FL (2) 8 S",
        "DATA": ["0.4", "0.6", "2", "5"]

    },
    {
        "SWITCH": "131",
        "FLASHCODE": "FL (2) 8 S",
        "DATA": ["0.4", "1", "0.4", "6.2"]

    },
    {
        "SWITCH": "132",
        "FLASHCODE": "FL (2) 8 S",
        "DATA": ["0.5", "1", "0.5", "6"]

    },
    {
        "SWITCH": "133",
        "FLASHCODE": "FL (2) 8 S",
        "DATA": ["0.8", "1.2", "2.4", "3.6"]

    },
    {
        "SWITCH": "134",
        "FLASHCODE": "FL (2) 8 S",
        "DATA": ["1", "1", "1", "5"]

    },
    {
        "SWITCH": "135",
        "FLASHCODE": "OC (2) 8 S",
        "DATA": ["3", "2", "1", "2"]

    },
    {
        "SWITCH": "136",
        "FLASHCODE": "OC (2) 8 S",
        "DATA": ["5", "1", "1", "1"]

    },
    {
        "SWITCH": "137",
        "FLASHCODE": "VQ (2) 8 S",
        "DATA": ["0.2", "1", "0.2", "6.6"]

    },
    {
        "SWITCH": "138",
        "FLASHCODE": "FL (2) 10 S",
        "DATA": ["0.4", "1.6", "0.4", "7.6"]

    },
    {
        "SWITCH": "139",
        "FLASHCODE": "FL (2) 10 S",
        "DATA": ["0.5", "0.5", "1.5", "7.5"]

    },
    {
        "SWITCH": "140",
        "FLASHCODE": "FL (2) 10 S",
        "DATA": ["0.5", "1", "0.5", "8"]

    },
    {
        "SWITCH": "141",
        "FLASHCODE": "FL (2) 10 S",
        "DATA": ["0.5", "1.5", "0.5", "7.5"]

    },
    {
        "SWITCH": "142",
        "FLASHCODE": "FL (2) 10 S",
        "DATA": ["0.5", "2", "0.5", "7.5"]
    },
    {
        "SWITCH": "143",
        "FLASHCODE": "FL (2) 10 S",
        "DATA": ["0.8", "1.2", "0.8", "7.2"]
    },
    {
        "SWITCH": "144",
        "FLASHCODE": "FL (2) 10 S",
        "DATA": ["1", "1", "1", "7"]

    },
    {
        "SWITCH": "145",
        "FLASHCODE": "FL (2) 10 S",
        "DATA": ["1", "1.5", "1", "6.5"]

    },
    {
        "SWITCH": "146",
        "FLASHCODE": "Q (2) 10 S",
        "DATA": ["0.6", "0.4", "0.6", "8.4"]

    },
    {
        "SWITCH": "147",
        "FLASHCODE": "FL (2) 12 S",
        "DATA": ["0.4", "1", "0.4", "10.2"]

    },
    {
        "SWITCH": "148",
        "FLASHCODE": "FL (2) 12 S",
        "DATA": ["0.5", "1", "0.5", "10"]

    },
    {
        "SWITCH": "149",
        "FLASHCODE": "FL (2) 12 S",
        "DATA": ["1.5", "2", "1.5", "7"]

    },
    {
        "SWITCH": "150",
        "FLASHCODE": "FL (2) 15 S",
        "DATA": ["0.5", "1.5", "2", "11"]

    },
    {
        "SWITCH": "151",
        "FLASHCODE": "FL (2) 15 S",
        "DATA": ["1", "2", "1", "11"]

    },
    {
        "SWITCH": "152",
        "FLASHCODE": "Q (2) 15 S",
        "DATA": ["0.2", "0.8", "0.2", "13.8"]

    },
    {
        "SWITCH": "153",
        "FLASHCODE": "FL (2) 20 S",
        "DATA": ["1", "3", "1", "15"]

    },
    {
        "SWITCH": "154",
        "FLASHCODE": "FL (2) 25 S",
        "DATA": ["1", "1", "1", "22"]

    },
    {
        "SWITCH": "155",
        "FLASHCODE": "Q (3) 5 S",
        "DATA": ["0.5", "0.5", "0.5", "0.5", "0.5", "2.5"]

    },
    {
        "SWITCH": "156",
        "FLASHCODE": "VQ (3) 5 S",
        "DATA": ["0.2", "0.3", "0.2", "0.3", "0.2", "3.8"]
    },
    {
        "SWITCH": "157",
        "FLASHCODE": "VQ (3) 5 S",
        "DATA": ["0.3", "0.2", "0.3", "0.2", "0.3", "3.7"]
    },
    {
        "SWITCH": "158",
        "FLASHCODE": "VQ (3) 5 S",
        "DATA": ["0.3", "0.3", "0.3", "0.3", "0.3", "3.5"]
    },
    {
        "SWITCH": "159",
        "FLASHCODE": "FL (3) 6 S",
        "DATA": ["0.5", "1", "0.5", "1", "0.5", "2.5"]
    },
    {
        "SWITCH": "160",
        "FLASHCODE": "FL (2+1) 6 S",
        "DATA": ["0.3", "0.4", "0.3", "1.2", "0.3", "3.5"]
    },
    {
        "SWITCH": "161",
        "FLASHCODE": "Q (3) 6 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.3", "3.7"]
    },
    {
        "SWITCH": "162",
        "FLASHCODE": "FL (3) 8 S",
        "DATA": ["0.5", "1", "0.5", "1", "0.5", "4.5"]
    },
    {
        "SWITCH": "163",
        "FLASHCODE": "FL (3) 9 S",
        "DATA": ["0.3", "1", "0.3", "1", "0.3", "6.1"]
    },
    {
        "SWITCH": "164",
        "FLASHCODE": "FL (3) 9 S",
        "DATA": ["0.8", "1.2", "0.8", "1.2", "0.8", "4.2"]
    },
    {
        "SWITCH": "165",
        "FLASHCODE": "FL (3) 10 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.9", "7.1"]
    },
    {
        "SWITCH": "166",
        "FLASHCODE": "FL (3) 10 S",
        "DATA": ["0.4", "0.6", "0.4", "0.6", "1.2", "6.8"]
    },
    {
        "SWITCH": "167",
        "FLASHCODE": "FL (3) 10 S",
        "DATA": ["0.5", "0.5", "0.5", "0.5", "0.5", "7.5"]
    },
    {
        "SWITCH": "168",
        "FLASHCODE": "FL (3) 10 S",
        "DATA": ["0.5", "1.5", "0.5", "1.5", "0.5", "5.5"]
    },
    {
        "SWITCH": "169",
        "FLASHCODE": "FL (3) 10 S",
        "DATA": ["0.6", "0.6", "0.6", "0.6", "0.6", "7"]
    },
    {
        "SWITCH": "170",
        "FLASHCODE": "FL (3) 10 S",
        "DATA": ["1", "1", "1", "1", "1", "5"]
    },
    {
        "SWITCH": "171",
        "FLASHCODE": "FL (2+1) 10 S",
        "DATA": ["0.5", "0.7", "0.5", "2.1", "0.5", "5.7"]
    },
    {
        "SWITCH": "172",
        "FLASHCODE": "OC (3) 10 S",
        "DATA": ["5", "1", "1", "1", "1", "1"]
    },
    {
        "SWITCH": "173",
        "FLASHCODE": "Q (3) 10 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.3", "7.7"]
    },
    {
        "SWITCH": "174",
        "FLASHCODE": "FL (2 + 1) 10 S",
        "DATA": ["0.5", "0.5", "0.5", "0.5", "1.5", "6.5"]
    },
    {
        "SWITCH": "175",
        "FLASHCODE": "FL (3) 12 S",
        "DATA": ["0.5", "1.5", "0.5", "1.5", "0.5", "7.5"]
    },
    {
        "SWITCH": "176",
        "FLASHCODE": "FL (3) 12 S",
        "DATA": ["0.5", "2", "0.5", "2", "0.5", "6.5"]
    },
    {
        "SWITCH": "177",
        "FLASHCODE": "FL (3) 12 S",
        "DATA": ["0.8", "1.2", "0.8", "1.2", "0.8", "7.2"]
    },
    {
        "SWITCH": "178",
        "FLASHCODE": "FL (3) 12 S",
        "DATA": ["1", "1", "1", "3", "1", "5"]
    },
    {
        "SWITCH": "179",
        "FLASHCODE": "FL (2+1) 12 S",
        "DATA": ["0.8", "1.2", "0.8", "2.4", "0.8", "6"]
    },
    {
        "SWITCH": "180",
        "FLASHCODE": "FL (2+1) 12 S",
        "DATA": ["1", "1", "1", "4", "1", "4"]
    },
    {
        "SWITCH": "181",
        "FLASHCODE": "FL (2+1) 13.5 S",
        "DATA": ["1", "1", "1", "4", "1", "5.5"]
    },
    {
        "SWITCH": "182",
        "FLASHCODE": "FL (3) 15 S",
        "DATA": ["0.3", "1.7", "0.3", "1.7", "0.3", "10.7"]
    },
    {
        "SWITCH": "183",
        "FLASHCODE": "FL (3) 15 S",
        "DATA": ["0.4", "1", "0.4", "1", "0.4", "11.8"]
    },
    {
        "SWITCH": "184",
        "FLASHCODE": "FL (3) 15 S",
        "DATA": ["0.5", "1.5", "0.5", "1.5", "0.5", "10.5"]
    },
    {
        "SWITCH": "185",
        "FLASHCODE": "FL (2+1) 15 S",
        "DATA": ["0.6", "0.3", "0.6", "0.3", "1.4", "11.8"]
    },
    {
        "SWITCH": "186",
        "FLASHCODE": "FL (2+1) 15 S",
        "DATA": ["0.7", "0.5", "0.7", "0.5", "1.9", "10.7"]
    },
    {
        "SWITCH": "187",
        "FLASHCODE": "FL (2+1) 15 S",
        "DATA": ["0.7", "0.7", "0.7", "0.7", "2.1", "10.1"]
    },
    {
        "SWITCH": "188",
        "FLASHCODE": "FL (2+1) 15 S",
        "DATA": ["1", "2", "1", "5", "1", "5"]
    },
    {
        "SWITCH": "189",
        "FLASHCODE": "VQ (3) 15 S",
        "DATA": ["0.1", "0.5", "0.1", "0.5", "0.1", "13.7"]
    },
    {
        "SWITCH": "190",
        "FLASHCODE": "FL (3) 20 S",
        "DATA": ["0.5", "3", "0.5", "3", "0.5", "12.5"]
    },
    {
        "SWITCH": "191",
        "FLASHCODE": "FL (3) 20 S",
        "DATA": ["0.5", "1.5", "0.5", "1.5", "0.5", "15.5"]
    },
    {
        "SWITCH": "192",
        "FLASHCODE": "FL (3) 20 S",
        "DATA": ["0.8", "1.2", "0.8", "1.2", "0.5", "15.2"]
    },
    {
        "SWITCH": "193",
        "FLASHCODE": "FL (3) 20 S",
        "DATA": ["1", "1", "1", "1", "1", "15"]
    },
    {
        "SWITCH": "194",
        "FLASHCODE": "VQ (4) 4 S",
        "DATA": ["0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "1.9"]
    },
    {
        "SWITCH": "195",
        "FLASHCODE": "Q (4) 6 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "2.7"]
    },
    {
        "SWITCH": "196",
        "FLASHCODE": "Q (4) 6 S",
        "DATA": ["0.4", "0.6", "0.4", "0.6", "0.4", "0.6", "0.4", "2.6"]
    },
    {
        "SWITCH": "197",
        "FLASHCODE": "FL (4) 10 S",
        "DATA": ["0.5", "1", "0.5", "1", "0.5", "1", "0.5", "5"]
    },
    {
        "SWITCH": "198",
        "FLASHCODE": "FL (4) 10 S",
        "DATA": ["0.8", "1.2", "0.8", "1.2", "0.8", "1.2", "0.8", "3.2"]
    },
    {
        "SWITCH": "199",
        "FLASHCODE": "Q (4) 10 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "6.7"]
    },
    {
        "SWITCH": "200",
        "FLASHCODE": "FL (4) 12 S",
        "DATA": ["0.3", "1.7", "0.3", "1.7", "0.3", "1.7", "0.3", "5.7"]
    },
    {
        "SWITCH": "201",
        "FLASHCODE": "FL (4) 12 S",
        "DATA": ["0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "8.5"]
    },
    {
        "SWITCH": "202",
        "FLASHCODE": "FL (4) 12 S",
        "DATA": ["0.5", "1.5", "0.5", "1.5", "0.5", "1.5", "0.5", "5.5"]
    },
    {
        "SWITCH": "203",
        "FLASHCODE": "FL (4) 12 S",
        "DATA": ["0.8", "1.2", "0.8", "1.2", "0.8", "1.2", "0.8", "5.2"]
    },
    {
        "SWITCH": "204",
        "FLASHCODE": "Q (4) 12 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "8.7"]
    },
    {
        "SWITCH": "205",
        "FLASHCODE": "FL (4) 15 S",
        "DATA": ["0.5", "1.5", "0.5", "1.5", "0.5", "1.5", "0.5", "8.5"]
    },
    {
        "SWITCH": "206",
        "FLASHCODE": "FL (4) 15 S",
        "DATA": ["1", "1", "1", "1", "1", "1", "1", "8"]
    },
    {
        "SWITCH": "207",
        "FLASHCODE": "FL (4) 15 S",
        "DATA": ["1.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "10.5"]
    },
    {
        "SWITCH": "208",
        "FLASHCODE": "FL (4) 16 S",
        "DATA": ["0.5", "1.5", "0.5", "1.5", "0.5", "1.5", "0.5", "9.5"]
    },
    {
        "SWITCH": "209",
        "FLASHCODE": "FL (4) 20 S",
        "DATA": ["0.3", "3", "0.3", "3", "0.3", "3", "0.3", "9.8"]
    },
    {
        "SWITCH": "210",
        "FLASHCODE": "FL (4) 20 S",
        "DATA": ["0.5", "1.5", "0.5", "1.5", "0.5", "1.5", "0.5", "13.5"]
    },
    {
        "SWITCH": "211",
        "FLASHCODE": "FL (4) 20 S",
        "DATA": ["0.5", "1.5", "0.5", "1.5", "0.5", "4.5", "0.5", "10.5"]
    },
    {
        "SWITCH": "212",
        "FLASHCODE": "FL (4) 20 S",
        "DATA": ["1.5", "1.5", "1.5", "1.5", "1.5", "1.5", "1.5", "9.5"]
    },
    {
        "SWITCH": "213",
        "FLASHCODE": "Q (4) 20 S",
        "DATA": ["0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "16.5"]
    },
    {
        "SWITCH": "214",
        "FLASHCODE": "Q (4) 28 S",
        "DATA": ["0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "24.5"]
    },
    {
        "SWITCH": "215",
        "FLASHCODE": "FL (4) 30 S",
        "DATA": ["0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "26.5"]
    },
    {
        "SWITCH": "216",
        "FLASHCODE": "Q (5) 7 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "2.7"]
    },
    {
        "SWITCH": "217",
        "FLASHCODE": "Q (5) 10 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "5.7"]
    },
    {
        "SWITCH": "218",
        "FLASHCODE": "FL (5) 16.5 S",
        "DATA": ["5", "1.5", "0.5", "1.5", "0.5", "1.5", "0.5", "1.5", "0.5", "3.5"]
    },
    {
        "SWITCH": "219",
        "FLASHCODE": "FL (5) 20 S",
        "DATA": ["0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "15.5"]
    },
    {
        "SWITCH": "220",
        "FLASHCODE": "FL (5) 20 S",
        "DATA": ["0.8", "1.2", "0.8", "1.2", "0.8", "1.2", "0.8", "1.2", "0.8", "11.2"]
    },
    {
        "SWITCH": "221",
        "FLASHCODE": "FL (5) 20 S",
        "DATA": ["1", "1", "1", "1", "1", "1", "1", "1", "1", "11"]
    },
    {
        "SWITCH": "222",
        "FLASHCODE": "Q (6) 10 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "4.7"]
    },
    {
        "SWITCH": "223",
        "FLASHCODE": "FL (6) 15 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "9.7"]
    },
    {
        "SWITCH": "224",
        "FLASHCODE": "FL (6) 15 S",
        "DATA": ["0.5", "1", "0.5", "1", "0.5", "1", "0.5", "1", "0.5", "1", "0.5", "7"]
    },
    {
        "SWITCH": "225",
        "FLASHCODE": "FL (6) + LFL 15 S",
        "DATA": ["0.5", "1", "0.5", "1", "0.5", "1", "0.5", "1", "0.5", "1", "0.5", "7"]
    },
    {
        "SWITCH": "226",
        "FLASHCODE": "VQ (6) + LFL 10 S",
        "DATA": ["0.2", "0.3", "0.2", "0.3", "0.2", "0.3", "0.2", "0.3", "0.2", "0.3", "0.2", "0.3", "2", "5"]
    },
    {
        "SWITCH": "227",
        "FLASHCODE": "VQ (6) + LFL 10 S",
        "DATA": ["0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "2", "4.4"]
    },
    {
        "SWITCH": "228",
        "FLASHCODE": "Q (6) + LFL 15 S",
        "DATA": ["0.2", "0.8", "0.2", "0.8", "0.2", "0.8", "0.2", "0.8", "0.2", "0.8", "0.2", "0.8", "2", "7"]
    },
    {
        "SWITCH": "229",
        "FLASHCODE": "Q (6) + LFL 15 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "2", "5"]
    },
    {
        "SWITCH": "230",
        "FLASHCODE": "Q (6) + LFL 15 S",
        "DATA": ["0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "2", "5.8"]
    },
    {
        "SWITCH": "231",
        "FLASHCODE": "VQ (6) + LFL 15 S",
        "DATA": ["0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "2", "9.4"]
    },
    {
        "SWITCH": "232",
        "FLASHCODE": "VQ (9) 10 S",
        "DATA": ["0.2", "0.3", "0.2", "0.3", "0.2", "0.3", "0.2", "0.3", "0.2", "0.3", "0.2", "0.3", "0.2", "0.3", "0.2", "0.3", "0.2", "5.8"]
    },
    {
        "SWITCH": "233",
        "FLASHCODE": "VQ (9) 10 S",
        "DATA": ["0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "0.3", "4.9"]
    },
    {
        "SWITCH": "234",
        "FLASHCODE": "Q (9) 15 S",
        "DATA": ["0.2", "0.8", "0.2", "0.8", "0.2", "0.8", "0.2", "0.8", "0.2", "0.8", "0.2", "0.8", "0.2", "0.8", "0.2", "0.8", "0.2", "6.8"]
    },
    {
        "SWITCH": "235",
        "FLASHCODE": "Q (9) 15 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "0.7", "0.3", "6.7"]
    },
    {
        "SWITCH": "236",
        "FLASHCODE": "Q (9) 15 S",
        "DATA": ["0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "0.6", "4.8"]
    },
    {
        "SWITCH": "237",
        "FLASHCODE": "MO (A) 6 S",
        "DATA": ["0.3", "0.6", "1", "4.1"]
    },
    {
        "SWITCH": "238",
        "FLASHCODE": "MO (A) 8 S",
        "DATA": ["0.4", "0.6", "2", "5"]
    },
    {
        "SWITCH": "239",
        "FLASHCODE": "MO (A) 8 S",
        "DATA": ["0.8", "1.2", "2.4", "3.6"]
    },
    {
        "SWITCH": "240",
        "FLASHCODE": "MO (U) 10 S",
        "DATA": ["0.3", "0.7", "0.3", "0.7", "0.9", "7.1"]
    },
    {
        "SWITCH": "241",
        "FLASHCODE": "MO (U) 10 S",
        "DATA": ["0.4", "0.6", "0.4", "0.6", "1.2", "6.8"]
    },
    {
        "SWITCH": "242",
        "FLASHCODE": "MO (U) 10 S",
        "DATA": ["0.5", "0.5", "0.5", "0.5", "1.5", "6.5"]
    },
    {
        "SWITCH": "243",
        "FLASHCODE": "MO (A) 10 S",
        "DATA": ["0.5", "0.5", "1.5", "7.5"]
    },
    {
        "SWITCH": "244",
        "FLASHCODE": "MO (D) 10 S",
        "DATA": ["5", "1", "1", "1", "1", "1"]
    },
    {
        "SWITCH": "245",
        "FLASHCODE": "MO (A) 15 S",
        "ON_1": "0.5",
        "OFF_1": "1.5",
        "ON_2": "2",
        "OFF_2": "11",
        "DATA": ["0.5", "1.5", "2", "11"]
    },
    {
        "SWITCH": "246",
        "FLASHCODE": "MO (U) 15 S",
        "DATA": ["0.6", "0.3", "0.6", "0.3", "1.4", "11.8"]
    },
    {
        "SWITCH": "247",
        "FLASHCODE": "MO (U) 15 S",
        "DATA": ["0.7", "0.5", "0.7", "0.5", "1.9", "10.7"]
    },
    {
        "SWITCH": "248",
        "FLASHCODE": "MO (U) 15 S",
        "DATA": ["0.7", "0.7", "0.7", "0.7", "2.1", "10.1"]
    },
    {
        "SWITCH": "249",
        "FLASHCODE": "MO (B) 15 S",
        "DATA": ["1.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "10.5"]
    },
    {
        "SWITCH": "250",
        "FLASHCODE": "MO (F) 15 S",
        "DATA": ["0.5", "0.5", "0.5", "0.5", "1.5", "0.5", "0.5", "10.5"]
    },
    {
        "SWITCH": "255",
        "FLASHCODE": "",
        "ON_1": "",
        "OFF_1": "",
        "ON_2": "",
        "OFF_2": "",
        "ON_3": "",
        "OFF_3": "",
        "ON_4": "",
        "OFF_4": "",
        "ON_5": "",
        "OFF_5": "",
        "ON_6": "",
        "OFF_6": "",
        "ON_7": "",
        "OFF_7": "",
        "ON_8": "",
        "OFF_8": "",
        "ON_9": "",
        "OFF_9": ""
    }
]*/
