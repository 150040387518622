import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import {Divider} from '@material-ui/core';
import axios from "axios";
import {useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from 'react-intl'
import {TextField} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {indexOf} from "leaflet/src/core/Util";
import LanternTriggerModes from "../../../helper/lanternTriggerMode";
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function BuoySettings(props) {
    const {authToken} = useSelector(state => state.auth);
    const intl = useIntl();

    const defaultKey = 255;
    const [ledOn, setLedOn] = useState('00:00');
    const [ledOff, setLedOff] = useState('00:00');
    const [triggerMode, setTriggerMode] = useState();
    const [savedTriggerMode, setSavedTriggerMode] = useState();
    const [flashMode, setFlashMode] = useState();
    const [ambientLight, setAmbientLight] = useState();
    const [confKey, setconfKey] = useState(255);
    const [definedPeriod, setDefinedPeriod] = useState(true);
    const [ledFlashSeq, setLedFlashSeq] = useState();
    const [disabledText, setDisabledText] = useState(false);
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [range, setRange] = useState();
    const [currrentLocation, setCurrentLocation] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [success, setSuccess] = useState(false);


    const [sliiMe, setSliiMe] = useState(false);
    const ambient = ['50,100', '100,1000', '1000,10000', '10000,60000']

    useEffect(() => {
        getSettings();
    }, []);

    const getCode = (pathname) => {
        const pthName = pathname.split('/')

        return pthName[pthName.length - 1].split('*')[0]
    }

    function handleClose() {
        setOpen(false);
    }
    const getSettings = () => {
        axios.get(`${process.env.REACT_APP_MQTT_SERVER}/api/device?token=${authToken}&dbName=${props.collection.trim()}&collection=config`)
            .then((res) => {
                let ledOnLight = res.data.led_config.led_on_utc_time;
                let ledOffLight = res.data.led_config.led_off_utc_time;
                setLedOn(ledOnLight.substr(0, 2) + ":" + ledOnLight.substr(2))
                setLedOff(ledOffLight.substr(0, 2) + ":" + ledOffLight.substr(2))

                setTriggerMode(res.data.led_config.led_trigger_mode)
                setSavedTriggerMode(res.data.led_config.led_trigger_mode)
                setFlashMode(res.data.led_config.led_flash_mode)
                setAmbientLight(indexOf(ambient, `${res.data.led_config.led_on_ambient_light},${res.data.led_config.led_off_ambient_light}`))
                setconfKey(res.data.led_config.led_flash_mode)
                setLedFlashSeq(res.data.led_flash_sequence)
                setLatitude(res.data.initial_location.latitude)
                setLongitude(res.data.initial_location.longitude)
                setRange(res.data.initial_location.range)
                if (parseInt(res.data.led_config.led_flash_mode) !== 255) {
                    setDefinedPeriod(true)
                } else {
                    setDefinedPeriod(false)
                }
            }).catch(err => {
            //console.log(err.response)
        })
        if (definedPeriod) {
            setDisabledText(true)
        } else {
            setDisabledText(false)
        }
    }


    const fioopMeClick = () => {
        setSliiMe(!sliiMe);
        axios.put(`${process.env.REACT_APP_API}/api/device/update/maintenance`, {token: authToken, collections:props.collection.trim().split("_")[1]}
        ).then((response) => {
            props.onCloseSnackbar(intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.SUCCESS_RESET_MAINTENANCE"}))
            setOpen(false)
            setTimeout(()=>{ setSliiMe(false) }, 1000);
        }).catch(e=>{
            console.log(e.response.data)
        });
    }

    const periodClick = () => {
        setDefinedPeriod(!definedPeriod);
        if (!definedPeriod) {

            setDisabledText(true)

        } else {
            /*setLedFlashSeq([])*/
            setDisabledText(false)

        }
        periodInput()
    }


    const onchanges = (e) => {
        //setLedOn(e.target.value)
        console.log(e.target.value)
    }

    const onChangeTriggerMode = (e) => {
        //setLedOn(e.target.value)

        setconfKey(e.target.value)
        let modes = LanternTriggerModes.find(valu => parseInt(valu.SWITCH) === parseInt(e.target.value));
        setLedFlashSeq(modes.DATA)
    }


    const getFlashCode = (val) => {
        let modes = LanternTriggerModes.find(valu => parseInt(valu.SWITCH) === parseInt(val));
        //console.log(modes)
        return modes.FLASHCODE
    }

    // Save Lantern On-Off Time
    const saveGeofence = () => {
        if (!currrentLocation) {
            //console.log(latitude, longitude, range)
            axios.post(`${process.env.REACT_APP_MQTT_SERVER}/api/publish`, {
                token: authToken,
                topic: `lantern/${props.collection.trim().split("_")[1]}/set_initial_location`,
                message: latitude + "," + longitude + "," + range
            }).then(resp => {
                //console.log(resp)
                props.onCloseSnackbar(intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.SUCCESS_GEO"}))
            });
        } else {
            //console.log(range)
            axios.post(`${process.env.REACT_APP_MQTT_SERVER}/api/publish`, {
                token: authToken,
                topic: `lantern/${props.collection.trim().split("_")[1]}/set_location_as_initial`,
                message: range.toString()
            }).then(resp => {
                //console.log(resp)
                props.onCloseSnackbar(intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.SUCCESS_GEO"}))
            });
        }

    }

    // Save Lantern On-Off Time
    const saveOnOffTime = () => {
        let ledOnTime = ledOn.split(":")
        let ledOffTime = ledOff.split(":")
        let onoff = ledOnTime[0] + ledOnTime[1] + "," + ledOffTime[0] + ledOffTime[1]
        //console.log(onoff)
        axios.post(`${process.env.REACT_APP_MQTT_SERVER}/api/publish`, {
            token: authToken,
            topic: `lantern/${props.collection.trim().split("_")[1]}/set_led_start_stop_utc_time`,
            message: onoff
        }).then(resp => {
            //console.log(resp)
            props.onCloseSnackbar(intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.SUCCESS_LED_START_STOP"}))
        });
    }

    // Save Lantern Ambient Light
    const saveAmbientLight = () => {
        axios.post(`${process.env.REACT_APP_MQTT_SERVER}/api/publish`, {
            token: authToken,
            topic: `lantern/${props.collection.trim().split("_")[1]}/set_ambient_light_tresholds`,
            message: ambient[ambientLight]
        }).then(resp => {
            //console.log(resp)
            props.onCloseSnackbar(intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.SUCCESS_AMBIENT"}))
        });
    }

    // Save Lantern Trigger Mode
    const saveTriggerMode = (e) => {
        axios.post(`${process.env.REACT_APP_MQTT_SERVER}/api/publish`, {
            token: authToken,
            topic: `lantern/${props.collection.trim().split("_")[1]}/set_trigger_mode`,
            message: triggerMode.toString()
        }).then(resp => {
            //console.log(resp)
            setSavedTriggerMode(triggerMode);
            props.onCloseSnackbar(intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.SUCCESS_TRIGGER_MODE"}))
        });
    }


    // Lantern Period Settings Input List
    const periodInput = () => {

        let inputs = [];
        let ledFlash = definedPeriod ? ledFlashSeq : [];

        if (ledFlashSeq) {
            for (let i = 0; i < 18; i++) {
                if (i % 2 == 0) {
                    //console.log(i, i + 1)
                    inputs.push(<>
                        <TextField
                            label={intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.LED_FLASH_PERIOD.INPUT_ON"}) + ((i / 2) + 1)}
                            style={{margin: "0 0 5px 5px", width: "140px"}}
                            variant="outlined"
                            value={ledFlash[i]}
                            name={"period" + i}
                            disabled={disabledText}
                            InputLabelProps={{shrink: true}}
                            type="number"
                        />
                        <TextField
                            label={intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.LED_FLASH_PERIOD.INPUT_OFF"}) + ((i / 2) + 1)}
                            style={{margin: "0 0 5px 5px", width: "140px"}}
                            variant="outlined"
                            value={ledFlash[i + 1]}
                            name={"period" + (i + 1)}
                            disabled={disabledText}
                            InputLabelProps={{shrink: true}}
                            type="number"
                        />
                    </>)
                }
            }
        }
        return inputs
    }

    // Lantern Period Settings
    const saveLanternPeriodSettings = (e) => {
        e.preventDefault();
        if (!definedPeriod) {
            let lanter = '';
            let lant = [];
            for (let i = 2; i < 20; i++) {
                let val = e.target[i * 2].value;//.replace(/ /g, '');
                if (val) {
                    lanter = lanter + val + ",";
                    lant.push(val)
                } else if (!val && e.target[(i + 1) * 2].value) {
                    props.onErrorSnackbar(intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.ERROR.EMPTY"}))
                    return;
                }
            }
            if (lant.length > 0) {
                if (lant.length % 2 === 0) {

                    //console.log(lanter.slice(0, -1));
                    axios.post(`${process.env.REACT_APP_MQTT_SERVER}/api/publish`, {
                        token: authToken,
                        topic: `lantern/${props.collection.trim().split("_")[1]}/set_led_flash_sequence`,
                        message: lanter.slice(0, -1)
                    }).then(resp => {
                        //console.log(resp)
                        props.onCloseSnackbar(intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.SUCCESS_PERIOD"}))

                    });
                } else {
                    props.onErrorSnackbar(intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.ERROR.ON_OFF"}))
                }
            } else {
                props.onErrorSnackbar(intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.ERROR.ALL_EMPTY"}))
            }


        } else {
            axios.post(`${process.env.REACT_APP_MQTT_SERVER}/api/publish`, {
                token: authToken,
                topic: `lantern/${props.collection.trim().split("_")[1]}/set_flash_mode`,
                message: confKey
            }).then(resp => {
                //console.log(resp)
                props.onCloseSnackbar(intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.SUCCESS_FLASH_MODE"}))
            });
        }
    }

    return (
        <div className="row">

            <div className="col-3" style={{marginBottom: "35px"}}>

            </div>
            <div className="col-9" style={{marginBottom: "35px"}}>
                <Typography component="h5" variant="h5" align='left'>
                    <b><FormattedMessage id="DEVICE.DETAIL.DEVICE_SETTINGS.TITLE"/></b>

                </Typography>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lm-3 col-md-6">
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    <FormattedMessage id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_TRIGGER_MODE"/>
                </InputLabel>
                <Select
                    labelId="demo-simple-select-placeholder-label-label"
                    id="demo-simple-select-placeholder-label"
                    value={triggerMode ? triggerMode.toString() : "0"}
                    onChange={e => setTriggerMode(e.target.value)}
                    displayEmpty
                    style={{width: "80%"}}
                    name="Led Tetik Modu"
                >
                    <MenuItem value={"0"} key="Always On"><FormattedMessage
                        id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_TRIGGER_MODE_0"/> </MenuItem>
                    <MenuItem value={"1"} key="Ambient Light"><FormattedMessage
                        id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_TRIGGER_MODE_1"/></MenuItem>
                    <MenuItem value={"2"} key="Sunset-Sunrise Time"><FormattedMessage
                        id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_TRIGGER_MODE_2"/></MenuItem>
                    <MenuItem value={"3"} key="Hybrid"><FormattedMessage
                        id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_TRIGGER_MODE_3"/></MenuItem>
                    <MenuItem value={"4"} key="UTC Time"><FormattedMessage
                        id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_TRIGGER_MODE_4"/></MenuItem>
                </Select>

                <Button variant="contained" color="secondary" type="submit"
                        onClick={saveTriggerMode} style={{marginTop: "10px"}}>
                    <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON"/>
                </Button>

            </div>
            <div className="col-xxl-3 col-xl-3 col-lm-3 col-md-6">
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    <FormattedMessage id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_AMBIENT_LIGHT"/>
                </InputLabel>
                <Select
                    labelId="demo-simple-select-placeholder-label-label"
                    id="demo-simple-select-placeholder-label"
                    value={ambientLight ? ambientLight.toString() : "0"}
                    onChange={e => setAmbientLight(e.target.value)}
                    displayEmpty
                    style={{width: "80%"}}
                    name="Ortam Işığı Eşiği"
                >{/*
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>*/}
                    <MenuItem value={"0"}><FormattedMessage
                        id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_AMBIENT_LIGHT_0"/></MenuItem>
                    <MenuItem value={"1"}><FormattedMessage
                        id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_AMBIENT_LIGHT_1"/></MenuItem>
                    <MenuItem value={"2"}><FormattedMessage
                        id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_AMBIENT_LIGHT_2"/></MenuItem>
                    <MenuItem value={"3"}><FormattedMessage
                        id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_AMBIENT_LIGHT_3"/></MenuItem>
                </Select>

                <Button variant="contained" color="secondary" type="submit"
                        onClick={saveAmbientLight} style={{marginTop: "10px"}}>
                    <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON"/>
                </Button>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lm-6 col-md-12">

                <div className="col-xxl-12 col-xl-12 col-lm-12 col-md-12" style={{display: "flex"}}>

                    <TextField
                        label={intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.LED_ON_TIME"})}
                        id="time" type="time"
                        onChange={e => setLedOn(e.target.value)}
                        value={ledOn} fullWidth style={{margin: "5px 10px 0 10px"}}
                        disabled={false}/>

                    <TextField
                        label={intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.LED_OFF_TIME"})}
                        id="time"
                        type="time" onChange={e => setLedOff(e.target.value)}
                        value={ledOff} fullWidth style={{margin: "5px 10px 0 10px"}}
                        disabled={false}/>
                </div>
                <div className="col-xxl-12 col-xl-12 col-lm-12 col-md-12"
                     style={{textAlign: "center", display: "block"}}>
                    <Button variant="contained" color="secondary" type="submit"
                            onClick={saveOnOffTime} style={{marginTop: "10px"}}
                            disabled={false}>
                        <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON"/>
                    </Button>
                    <br/>
                    {/*{savedTriggerMode === 4 ? "" :
                        <span style={{color: "red"}}>Ayarlayabilmek için Trigger Mod UTC Time Ayarlanmalı!</span>}*/}
                </div>

            </div>
            <div className="col-12"><br/><Divider/> <br/></div>


            <div className="col-md-6 col-xs-12" style={{marginBottom: "15px", marginTop: "15px", display: "flex"}}>
                <Typography component="h5" variant="h5" style={{marginTop: "5px"}}>
                    <b> <FormattedMessage id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_FLASH_PERIOD_TITLE"/></b>
                </Typography>
                <Typography style={{marginLeft: "15px"}}>
                    <FormattedMessage id="OPERATIONS.DEVICES.STATUS.DEFINED"/>
                    <Switch checked={definedPeriod} onChange={periodClick}/>
                </Typography>
                <Typography style={{marginLeft: "10px"}}>
                    <FormattedMessage id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_FLASH_PERIOD.CUSTOM_SWITCH"/>
                    <Switch checked={!definedPeriod} onChange={periodClick}/>
                </Typography>
            </div>

            <div className="col-md-6 col-xs-12">

            </div>

            <div className="col-12" style={{marginTop: "10px"}}>
                <form onSubmit={saveLanternPeriodSettings}>
                    <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label" style={{marginLeft: "4px"}}>
                            <FormattedMessage id="DEVICE.DETAIL.DEVICE_SETTINGS.LED_FLASH_PERIOD.INPUT_KEY"/>
                        </InputLabel>
                        <Select
                            label={intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.LED_FLASH_PERIOD.INPUT_KEY"})}
                            id="demo-simple-select-outlined"
                            value={!disabledText ? defaultKey : confKey}
                            onChange={e => onChangeTriggerMode(e)}
                            style={{margin: "0 0 5px 5px", width: "140px"}}
                            name="Anahtar"
                            variant="outlined"
                            disabled={!disabledText}
                        >
                            {
                                LanternTriggerModes.map((val, index) =>
                                    <MenuItem value={val.SWITCH} key={val.SWITCH}>{val.SWITCH}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        id="outlined-default-helper-text"
                        label={intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.LED_FLASH_PERIOD.INPUT_FLASH_CODE"})}
                        style={{margin: "0 0 5px 5px", width: "140px"}}
                        variant="outlined"
                        disabled={true}
                        value={!disabledText || !confKey ? getFlashCode(defaultKey) : getFlashCode(confKey)}
                    />

                    {periodInput()}
                    <div style={{textAlignLast: "center"}}>
                        <Button variant="contained" color="secondary" type="submit"
                                style={{marginTop: "10px"}}>
                            <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON"/>
                        </Button>
                    </div>
                </form>
            </div>

            <div className="col-12" style={{textAlignLast: "center"}}>

            </div>

            <div className="col-12"><br/><Divider/> <br/></div>

            <div className="col-12">

                <Typography component="h5" variant="h5" style={{marginTop: "5px"}}>
                    <b><FormattedMessage id="DEVICE.DETAIL.DEVICE_SETTINGS.GEO_FENCE_TITLE"/></b>
                </Typography>
                <br/>

                <Switch checked={currrentLocation} onChange={() => setCurrentLocation(!currrentLocation)}/>
                <FormattedMessage id="DEVICE.DETAIL.DEVICE_SETTINGS.GEO_FENCE.SWITCH"/>
                <br/>
                <div style={{marginTop: "20px"}}>
                   {/* {!currrentLocation
                        ? <>
                            <TextField
                                id="outlined-default-helper-text"
                                label={intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.GEO_FENCE.INPUT_LATITUDE"})}
                                style={{margin: "2px"}}
                                variant="outlined"
                                value={latitude}
                                onChange={e => setLatitude(e.target.value)}
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                id="outlined-default-helper-text"
                                label={intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.GEO_FENCE.INPUT_LONGITUDE"})}
                                style={{margin: "2px"}}
                                variant="outlined"
                                value={longitude}
                                onChange={e => setLongitude(e.target.value)}
                                InputLabelProps={{shrink: true}}
                            />
                        </>
                        : ""}*/}
                    <TextField
                        id="outlined-default-helper-text"
                        label={intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.GEO_FENCE.INPUT_LATITUDE"})}
                        style={{margin: "2px"}}
                        variant="outlined"
                        value={!currrentLocation ?latitude :props.lat}
                        onChange={e => setLatitude(e.target.value)}
                        InputLabelProps={{shrink: true}}
                        disabled={!currrentLocation ?false:true}
                    />
                    <TextField
                        id="outlined-default-helper-text"
                        label={intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.GEO_FENCE.INPUT_LONGITUDE"})}
                        style={{margin: "2px"}}
                        variant="outlined"
                        value={!currrentLocation ?longitude:props.lng}
                        onChange={e => setLongitude(e.target.value)}
                        InputLabelProps={{shrink: true}}

                        disabled={!currrentLocation ?false:true}
                    />
                    <TextField
                        label={intl.formatMessage({id: "DEVICE.DETAIL.DEVICE_SETTINGS.GEO_FENCE.INPUT_RADIUS"})}
                        style={{margin: "2px"}}
                        variant="outlined"
                        value={range}
                        onChange={e => setRange(e.target.value)}
                        InputLabelProps={{shrink: true}}
                        type="number"
                    />
                    <Button variant="contained" color="secondary" onClick={saveGeofence}
                            style={{marginTop: "10px"}}>
                        <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON"/>
                    </Button>
                </div>

            </div>

            <div className="col-12"><br/><Divider/> <br/></div>


            <div className="col-12">

                <Typography component="h5" variant="h5" style={{marginTop: "5px"}}>
                    <b><FormattedMessage id="DEVICE.DETAIL.DEVICE_SETTINGS.MAINTENANCE_TITLE"/></b>
                </Typography>
                <br/>

                <Switch checked={sliiMe} onChange={e=> setOpen(true)//fioopMeClick
                }/>
                <FormattedMessage id="DEVICE.DETAIL.DEVICE_SETTINGS.MAINTENANCE.SWITCH"/>

            </div>

            {/*  <div className="col-12" style={{textAlignLast: "center"}}>
                <Button variant="contained" color="secondary" onClick={}><FormattedMessage
                    id="USER.PROFILE.BUTTON_SAVE"/></Button>
            </div>*/}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <FormattedMessage id="DEVICE.DETAIL.DEVICE_SETTINGS.MAINTENANCE_TITLE"/></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id="DEVICE.DETAIL.DEVICE_SETTINGS.MAINTENANCE.QUESTION"/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={e=>setOpen(false)} color="primary">
                        <FormattedMessage id="USER.PROFILE.BUTTON_CANCEL"/>
                    </Button>
                    <Button onClick={fioopMeClick} color="primary" autoFocus>
                        <FormattedMessage id="USER.PROFILE.BUTTON_SAVE"/>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
