import React,  {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import {useIntl} from "react-intl";

export default function StackedBar (props) {

    const intl=useIntl();

    const [series, setSeries] = useState([{
        name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.FISH"}),
        data: []
    }, {
        name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.TUG"}),
        data: []
    }, {
        name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.CRUISE"}),
        data: []
    }, {
        name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.UNDEFINED"}),
        data: []
    }, {
        name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.MILITARY"}),
        data: []
    }]);

    const [options, setOptions] = useState({
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        title: {
            text: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SUB_TITLE1"})
        },
        xaxis: {
            categories: [2020/*, 2009, 2010, 2011, 2012, 2013, 2014*/],
            labels: {
                formatter: function (val) {
                    return val
                }
            }
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    })


    useEffect(() => {
        if(props.data){
            setSeries([{
                name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.FISH"}),
                data: [props.data.fishing]
            }, {
                name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.CARGO"}),
                data: [props.data.cargo/*, 32, 33, 52, 13, 43, 32*/]
            }, {
                name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.CRUISE"}),
                data: [props.data.passenger/*, 17, 11, 9, 15, 11, 20*/]
            }, {
                name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.TUG"}),
                data: [props.data.tug/*, 7, 5, 8, 6, 9, 4*/]
            }, {
                name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.MILITARY"}),
                data: [props.data.military/*, 12, 19, 32, 25, 24, 10*/]
            }, {
                name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.OTHER"}),
                data: [props.data.other/*, 12, 19, 32, 25, 24, 10*/]
            }, {
                name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.UNDEFINED"}),
                data: [props.data.undef/*, 12, 19, 32, 25, 24, 10*/]
            }, {
                name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.DREDGER"}),
                data: [props.data.dredger/*, 12, 19, 32, 25, 24, 10*/]
            }, {
                name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.DIVE"}),
                data: [props.data.dive/*, 12, 19, 32, 25, 24, 10*/]
            }, {
                name: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SHIP_TYPES.TANKER"}),
                data: [props.data.tanker/*, 12, 19, 32, 25, 24, 10*/]
            }])
        }
},[props])

        //console.log(intl.formatMessage({id: "DEVICE.DETAIL.WARNING.TITLE"}))
        return (
            <div id="chart" style={{marginTop: "20px"}}>
                <Chart options={options} series={series} type="bar" height={350}/>
            </div>
        );

}

//export default StackedBar;