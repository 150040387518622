import React, {useState} from "react";
import {useFormik} from "formik";
import {connect} from "react-redux";
import * as Yup from "yup";
import {Link} from "react-router-dom";
import * as auth from "../_redux/authRedux";
import {register} from "../_redux/authCrud";
import Alert from "@material-ui/lab/Alert";
import {FormattedMessage, injectIntl} from "react-intl";


const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    serialNo: "",
    company: "",
    password: "",
    changepassword: "",
    acceptTerms: true,
};

function Registration(props) {
    const intl = props.intl;

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const RegistrationSchema = Yup.object().shape({
        serialNo: Yup.string()
            .min(3, intl.formatMessage({
                id: "AUTH.VALIDATION.MIN_LENGTH_FIELD",
            })+" 3")
            .max(50, intl.formatMessage({
                id: "AUTH.VALIDATION.MAX_LENGTH_FIELD",
            })+" 50")
            .required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"})),
        firstname: Yup.string()
            .min(3, intl.formatMessage({
                id: "AUTH.VALIDATION.MIN_LENGTH_FIELD",
            })+" 3")
            .max(50, intl.formatMessage({
                id: "AUTH.VALIDATION.MAX_LENGTH_FIELD",
            })+" 50")
            .required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"})),
        lastname: Yup.string()
            .min(3, intl.formatMessage({
                id: "AUTH.VALIDATION.MIN_LENGTH_FIELD",
            })+" 3")
            .max(50, intl.formatMessage({
                id: "AUTH.VALIDATION.MAX_LENGTH_FIELD",
            })+" 50")
            .required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"})),
        email: Yup.string()
            .email(intl.formatMessage({id: "AUTH.VALIDATION.MAIL_FORMAT"}))
            .min(3, intl.formatMessage({
                id: "AUTH.VALIDATION.MIN_LENGTH_FIELD",
            })+" 3")
            .max(50, intl.formatMessage({
                id: "AUTH.VALIDATION.MAX_LENGTH_FIELD",
            })+" 50")
            .required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"})),
        company: Yup.string()
            .min(3, intl.formatMessage({
                id: "AUTH.VALIDATION.MIN_LENGTH_FIELD",
            })+" 3")
            .max(50, intl.formatMessage({
                id: "AUTH.VALIDATION.MAX_LENGTH_FIELD",
            })+" 50"),
        password: Yup.string()
            .min(3, intl.formatMessage({
                id: "AUTH.VALIDATION.MIN_LENGTH_FIELD",
            })+" 3")
            .max(50, intl.formatMessage({
                id: "AUTH.VALIDATION.MAX_LENGTH_FIELD",
            })+" 50")
            .required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"}))
            .matches(
                /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])/,
                intl.formatMessage({id: "AUTH.VALIDATION.PASSWORD_MATCHES"})
            ),
        changepassword: Yup.string()
            .required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"}))
            .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    intl.formatMessage({id: "AUTH.VALIDATION.PASSWORD_NOTMATCHED"})
                ),
            }),
        acceptTerms: Yup.bool().required(
            intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"})
        ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            register(values).then((response) => {
                //props.register(response.data.token);

                disableLoading();
                //console.log(response.data)
                setSubmitting(true)
                setStatus()
                setMessage(response.data.message)
                //props.history.push("auth/login")
            })
                .catch((err) => {

                    //console.log(err.response.data.message)
                    setSubmitting(false);
                    setStatus(
                        err.response.data.message
                    );
                    disableLoading();
                });
        },
    });

    return (
        <div className="login-form login-signin" style={{display: "block"}}>
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.REGISTER.TITLE"/>

                </h3>
                <p className="text-muted font-weight-bold">
                    <FormattedMessage id="AUTH.REGISTER.DESC"/>
                </p>
            </div>
            <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={formik.handleSubmit}
            >
                {/* begin: Alert */}
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                )}
                {!message
                    ? ""
                    : <Alert variant="outlined" severity="success" style={{marginBottom: "10px"}}>
                        {message}
                    </Alert>

                }
                {/* end: Alert */}

                {/* begin: Company */}
                <div className="form-group fv-plugins-icon-container">

                    <input
                        placeholder={intl.formatMessage({id: "AUTH.INPUT.SERIAL"})}
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "serialNo"
                        )}`}
                        name="serialNo"
                        {...formik.getFieldProps("serialNo")}
                    />
                    {formik.touched.serialNo && formik.errors.serialNo ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.serialNo}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Company */}
                {/* begin: Company */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder={intl.formatMessage({id: "AUTH.INPUT.COMPANY"})}
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "company"
                        )}`}
                        name="company"
                        {...formik.getFieldProps("company")}
                    />
                </div>
                {/* end: Company */}

                {/* begin: firstname */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder={intl.formatMessage({id: "AUTH.INPUT.FIRSTNAME"})}
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "firstname"
                        )}`}
                        name="firstname"
                        {...formik.getFieldProps("firstname")}
                    />
                    {formik.touched.firstname && formik.errors.firstname ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.firstname}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: lastname */}
                {/* begin: lastname */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder={intl.formatMessage({id: "AUTH.INPUT.SECONDNAME"})}
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "lastname"
                        )}`}
                        name="lastname"
                        {...formik.getFieldProps("lastname")}
                    />
                    {formik.touched.lastname && formik.errors.lastname ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.lastname}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: lastname */}

                {/* begin: Email */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder={intl.formatMessage({id: "AUTH.INPUT.EMAIL"})}
                        type="email"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "email"
                        )}`}
                        name="email"
                        {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Email */}

                {/* begin: Password */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder={intl.formatMessage({id: "AUTH.INPUT.CONFIRM_PASSWORD"})}
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "password"
                        )}`}
                        name="password"
                        {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Password */}

                {/* begin: Confirm Password */}
                <div className="form-group fv-plugins-icon-container">

                    <input
                        placeholder={intl.formatMessage({id: "AUTH.INPUT.CONFIRM_PASSWORD"})}
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "changepassword"
                        )}`}
                        name="changepassword"
                        {...formik.getFieldProps("changepassword")}
                    />
                    {formik.touched.changepassword && formik.errors.changepassword ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.changepassword}
                            </div>
                        </div>
                    ) : null}
                </div>
                {/* end: Confirm Password */}

                {/* begin: Terms and Conditions */}
                {/* Sözleşme Onayı */}
                {/* <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link to="/terms" target="_blank" className="mr-1" rel="noopener noreferrer">
            I agree the Terms & Conditions
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>*/}
                {/* end: Terms and Conditions */}
                <div className="form-group d-flex flex-wrap flex-center">
                    <button
                        type="submit"
                        disabled={formik.isSubmitting || !formik.values.acceptTerms}
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                        <span><FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON"/></span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>

                    <Link to="/auth/login">
                        <button
                            type="button"
                            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        >
                            <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON"/>
                        </button>
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Registration));
