import axios from "axios";

export const DEVICE_ALL_URL   = `${process.env.REACT_APP_API}/api/device/list`;
export const DEVICE_ONE_URL   = `${process.env.REACT_APP_API2}/live/data`;
export const DEVICE_RISK_URL  = `${process.env.REACT_APP_API2}/ais/risk`;
export const LANTERN_ONE_URL  = `${process.env.REACT_APP_MQTT_SERVER}/api/device`;


export function getDeviceById(productId) {
  if(productId.type === "octopus_06202001") {
    return axios.get(`${DEVICE_ONE_URL}?type=${productId.type}`);
  }else{
    return axios.get(`${LANTERN_ONE_URL}?token=${productId.token}&dbName=${productId.type}&collection=${productId.collection}`);
  }
}


export function findProducts(queryParams) {

  return axios.get(`${DEVICE_ONE_URL}?token=${queryParams.token}`);

}

export function getDeviceRisk(queryParams) {

  return axios.get(`${DEVICE_RISK_URL}`,{
    params: {
      timestamp: queryParams.date,
      token: queryParams.authToken,
      type: queryParams.type
    },
  });

}

