/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";

export function AsideMenuList({layoutProps}) {
    const location = useLocation();
    //const user = useSelector((state) => state.auth.user, shallowEqual);
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    // Getting curret state of products list from store (Redux)
    const {currentState, authToken, user} = useSelector(
        (state) => ({
            currentState: state.products,
            authToken: state.auth.authToken,
            user: state.auth.user
        }),
        shallowEqual
    );
    const {entities} = currentState;

    var octopus = [];
    var lantern = [];

    if (entities && lantern.length == 0 && octopus.length == 0) {
        entities.map(data => {
            if (data) {
                if (data.device_type == 0 && data.collections) {
                    lantern.push(data)
                } else if (data.device_type == 1) {
                    octopus.push(data)
                }
            }
        })
    }
    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`} >
                <li
                    className={`menu-item ${getMenuItemActive("/main", true)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/main">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
                        <span className="menu-text">
                            <FormattedMessage id="SIDEBAR.ALL_DEVICE"/>
                        </span>
                    </NavLink>
                </li>

                {/* Begin devices */}

                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/buoy-detail", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/buoy-detail">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Server.svg")}/>
            </span>
                        <span className="menu-text"><FormattedMessage id="SIDEBAR.DEVICE_LIST"/></span>
                    </NavLink>
                    <div className="menu-submenu">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text"><FormattedMessage id="SIDEBAR.DEVICE_LIST"/></span>
                </span>
                            </li>
                            {lantern.length > 0 ? <li className="menu-section " style={{marginTop:"10px"}}>
                                    <h4 className="menu-text" style={{color:"#d0d0d0"}}><FormattedMessage id="SIDEBAR.DEVICE_LIST.LANTERN"/></h4>
                                    <i className="menu-icon flaticon-more-v2"></i>
                                </li>
                                : ""}
                            {lantern.length > 0 ?
                                lantern.map(data => (
                                    data ?
                                        <li
                                            className={`menu-item ${getMenuItemActive("/buoy-detail/" + data.code + "*" + data.device_type)}`}
                                            aria-haspopup="true"
                                            key={data.serial_number}
                                        >
                                            <NavLink className="menu-link"
                                                     to={"/buoy-detail/" + data.code + "*" + data.device_type}>
                                                <i className="menu-bullet menu-bullet-dot">
                                                    <span/>
                                                </i>
                                                <span className="menu-text">{data.name}</span>
                                            </NavLink>
                                        </li>
                                        : ""
                                ))
                                : ""}

                            {octopus.length > 0
                                ? <li className="menu-section " style={{marginTop:"10px"}}>
                                    <h4 className="menu-text" style={{color:"#d0d0d0"}}><FormattedMessage id="SIDEBAR.DEVICE_LIST.OCTOPUS"/></h4>
                                    <i className="menu-icon flaticon-more-v2"></i>
                                </li>
                                : ""}
                            {octopus.length>0 ?
                                octopus.map(data => (
                                    data ?
                                        <li
                                            className={`menu-item ${getMenuItemActive("/buoy-detail/" + data.code + "*" + data.device_type)}`}
                                            aria-haspopup="true"
                                            key={data.serial_number}
                                        >
                                            <NavLink className="menu-link"
                                                     to={"/buoy-detail/" + data.code + "*" + data.device_type}>
                                                <i className="menu-bullet menu-bullet-dot">
                                                    <span/>
                                                </i>
                                                <span className="menu-text">{data.name}</span>
                                            </NavLink>
                                        </li>
                                        : ""
                                ))
                                : ""}

                        </ul>
                    </div>
                </li>

                {/*Begin settings*/}
                {user.user.is_owner || user.user.is_admin
                    ? <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/operations", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/operations">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")}/>
            </span>
                            <span className="menu-text"><FormattedMessage id="SIDEBAR.OPERATIONS"/></span>
                        </NavLink>
                        <div className="menu-submenu">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text"><FormattedMessage id="SIDEBAR.OPERATIONS"/></span>
                </span>
                                </li>

                                <li
                                    className={`menu-item ${getMenuItemActive(
                                        "/operations/customers"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/operations/customers">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span/>
                                        </i>
                                        <span className="menu-text"><FormattedMessage id="SIDEBAR.OPERATIONS.USERS"/></span>
                                    </NavLink>
                                </li>


                                <li
                                    className={`menu-item ${getMenuItemActive(
                                        "/operations/products"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/operations/products">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span/>
                                        </i>
                                        <span className="menu-text"><FormattedMessage id="SIDEBAR.OPERATIONS.DEVICES"/></span>
                                    </NavLink>
                                </li>

                            </ul>
                        </div>
                    </li>
                    : ""}

            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
