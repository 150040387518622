import React, {Component, useEffect, useState} from "react";
import Chart from "react-apexcharts";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useIntl} from "react-intl";

export default function Line(props) {
    const intl = useIntl();

    const [series, setSeries] = useState([
        {
            name: "Ships",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
    ]);
    const [options, setOptions] = useState({
        chart: {
            height: 350,
            type: 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
            },
            toolbar: {
                show: false
            }
        },
        colors: ['tomato', '#545454'],
        dataLabels: {
            enabled: true,
        },
        stroke: {
            curve: 'smooth'
        },
        title: {
            text: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SUB_TITLE2"}),
            align: 'left'
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        markers: {
            size: 1
        },
        xaxis: {
            categories: [
                intl.formatMessage({id: "MONTH.JANUARY"}), intl.formatMessage({id: "MONTH.FEBRUARY"}), intl.formatMessage({id: "MONTH.MARCH"}),
                intl.formatMessage({id: "MONTH.APRIL"}), intl.formatMessage({id: "MONTH.MAY"}), intl.formatMessage({id: "MONTH.JUNE"}),intl.formatMessage({id: "MONTH.JULY"}),
                intl.formatMessage({id: "MONTH.AUGUST"}), intl.formatMessage({id: "MONTH.SEPTEMBER"}), intl.formatMessage({id: "MONTH.OCTOBER"}),
                intl.formatMessage({id: "MONTH.NOVEMBER"}), intl.formatMessage({id: "MONTH.DECEMBER"})],
            title: {
                text: intl.formatMessage({id: "MONTH.TITLE"})
            }
        },
        yaxis: {
            title: {
                text: intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.SUB_TITLE2"})
            },
            /*min: 5,
            max: 40*/
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
        }
    });
    let count = 0;
    useEffect(() => {
        if (props.data) {
            setSeries([
                {
                    name: "Ships",
                    data: [props.data[0].length, props.data[1].length, props.data[2].length, props.data[3].length, props.data[4].length, props.data[5].length
                        , props.data[6].length, props.data[7].length, props.data[8].length, props.data[9].length, props.data[10].length, props.data[11].length]
                }
            ])
            count += 1;
        }
        /*console.log(intl.formatMessage({id: "DEVICE.DETAIL.STATISTIC.TEST.0"}))*/
    }, [props]);

    return (
        <>
            {
               /* count != 0
                    ?*/ <div id="chart">
                        <Chart options={options} series={series} type="line" height={350}/>
                    </div>
                    /*: <div style={{
                        textAlignLast: "center",
                        alignSelf: "center"
                    }}>
                        Loading <br/>
                        <CircularProgress color="secondary"/>
                    </div>*/
            }
        </>
    );

}