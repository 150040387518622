import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';
import axios from "axios";
import {useSelector} from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";
import Table from './Table/Table'
import {useIntl} from 'react-intl'
import {DateRangePicker} from 'react-date-range';
import {addDays} from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as rdrLocales from 'react-date-range/dist/locale';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));


export default function Warnings(props) {

    const intl = useIntl();

    const {authToken,user} = useSelector(state => state.auth);
    const [date, setDate] = useState(parseInt(new Date().getTime() / 1000));
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [show, setShow] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const warnings = [intl.formatMessage({id: "WARNINGS.TABLE.0"}), intl.formatMessage({id: "WARNINGS.TABLE.1"}), intl.formatMessage({id: "WARNINGS.TABLE.2"})]
    const [state, setState] = useState({
        columns: [
            {title: intl.formatMessage({id: "DEVICE.DETAIL.WARNING.DATE"}), field: 'time', filtering: false},
            {title: intl.formatMessage({id: "DEVICE.DETAIL.WARNING.MESSAGE"}), field: 'message', filtering: false},
            /*
            { title: 'GEÇİŞ TARİHİ', field: 'date', type: 'string',filtering: false },
            {title: 'GEMİ ADI', field: 'shipName',filtering: false /!*lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },*!/ },
            { title: 'RİSK DURUMU', field: 'risk',render: rowData => <Chip size="small" variant="outlined" style={{ borderColor:"rgb(53, 193, 241)",color:"rgb(53, 193, 241)"}}  label={rowData.risk}/>},
            { title: 'GEMİ TİPİ', field: 'shipType',render: rowData => <ul><li style={rowData.shipType=="Retail"?{color:"rgb(53,116,241)"}:{color:"red"}}>{rowData.shipType}</li></ul>},*/

        ],
    });
    const [state2, setState2] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }
    ]);

    useEffect(() => {
        //console.log(user)
        if (props.db.split("_")[0] === "octopus") {
            axios.get(`${process.env.REACT_APP_API2}/warning/buoy`, {
                params: {
                    timestamp: date,
                    token: authToken,
                    type: "octopus_06202001",
                    header: props.db.split("_")[1]
                },
            }).then((res) => {
                const dataArr = res.data;
                const arr = [];
                dataArr.map((data) => {
                    //console.log(data.timestamp)
                    arr.push({message: data.message, time: new Date(data.timestamp * 1000).toLocaleString()});
                });
                setData(arr);
                setLoad(true);
                /*console.log(arr)*/
            }).catch(error => {
                if (error.response) {
                    if (error.response.status == 401) {
                        history.push("/logout");
                    }
                }
                console.log(error)
            });
        } else if (props.warning) {

            const dataArr = props.warning;
            const arr = [];
            
            dataArr.map((datas) => {
                arr.push({message: warnings[datas.warning_type], time: new Date(datas.timestamp*1000).toLocaleString()});
            });
            setData(arr);
            setLoad(true);
        }

    }, [props.warning]);
    const changeDate = (e) => {
        setState2([e.selection])
        //console.log(e)
        axios.get(`${process.env.REACT_APP_MQTT_SERVER}/api/device?token=${authToken}&dbName=${props.collection}&collection=warning&start=${e.selection.startDate}&end=${e.selection.endDate}`)
            .then((response) => {
                const arr = [];
                response.data.map((data) => {
                    arr.push({message: warnings[data.warning_type], time: new Date(data.timestamp*1000).toLocaleString()});
                });
                setData(arr);
                setLoad(true);
            }).catch(error => {
            if (error.response) {
                if (error.response.status == 401) {
                    history.push("/logout");
                }
            }
            console.log(error)
        });
    }
    const lang=localStorage.getItem("i18nConfig");
    return (
        <>
            <button type="button" style={{marginBottom: "5px"}}
                    className="btn btn-primary" onClick={e => setShow(!show)}>{intl.formatMessage({id: "DATE_PICKER"})}
            </button>
            {show
                ? <DateRangePicker
                    onChange={item => changeDate(item)}
                    showSelectionPreview={false}
                    showMonthAndYearPickers={false}
                    moveRangeOnFirstSelection={true}
                    months={2}
                    ranges={state2}
                    direction="horizontal"
                    maxDate={new Date()}
                    locale={rdrLocales.[JSON.parse(lang).selectedLang]}
                />
                : ""}
            <Table data={data} state={state} load={load} title={intl.formatMessage({id: "DEVICE.DETAIL.WARNING.TITLE"})}/>
        </>);
}