import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import axios from "axios";

export default function BasicColumn(props) {

    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({});


    useEffect(() => {

        setSeries([{
            name: 'Rüzgar Hızı',
            data: props.windSpeed.slice(props.windSpeed.length - 36, props.windSpeed.length)
        }])
        setOptions(
            {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },

                xaxis: {
                    categories: props.windTime.slice(props.windTime.length - 36, props.windTime.length) //['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
                },
                yaxis: {
                    title: {
                        text: 'kt (speed)'
                    },
                    min: 0,
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " kt"
                        }
                    }
                }
            })
    }, []);

    return (


        <div id="chart">{series.length > 0
            ? <Chart options={options} series={series} type="bar" height={350}/>
            : ""}
        </div>


    );

}