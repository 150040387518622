export default function (data) {
    var  undef=0, reserved=0, wig=0, sarAircraft=0, sar=0, fishing=0, tug=0, dredger=0, dive=0, military=0, sailing=0, pleasureCraft=0, highSpeed=0, pilot=0, port=0, pollution=0,
    patrol=0, local=0, medical=0, special=0, passenger=0, cargo=0, tanker=0, other =0;
    for(let i=0; i< data.length; i++){
        if (data[i].shiptype > 9 && data[i].shiptype < 20) {
            //return "Ayrılmış - Belirtilmemiş"
            reserved=reserved+1;
        } else if (data[i].shiptype > 19 && data[i].shiptype < 29) {
            //return "Kanat Gemisi - Kanat Gemisi"
            wig=wig+1;
        } else if (data[i].shiptype === 29) {
            //return "SAR Uçağı - Arama Kurtarma Uçak Gemisi"
            sarAircraft=sarAircraft+1;
        } else if (data[i].shiptype === 30) {
            //return "Balıkçı Gemisi - Balıkçı"
            fishing=fishing+1;
        } else if (data[i].shiptype === 31) {
            //return "Römorkör - Kurtarma"
            tug=tug+1;
        } else if (data[i].shiptype === 32) {
            //return "Römorkör - Deniz Zemin Tarama"
            tug=tug+1;
        } else if (data[i].shiptype === 33) {
            //return "Tarak - Özel Gemi"
            dredger=dredger+1;
        } else if (data[i].shiptype === 34) {
            //return "Dalış Gemisi - Özel Gemi"
            dive=dive+1;
        } else if (data[i].shiptype === 35) {
            //return "Askeri Operasyon - Özel Gemi"
            military=military+1;
        } else if (data[i].shiptype === 36) {
            //return "Yelkenli Gemi - Yelkenli Gemi"
            sailing=sailing+1;
        } else if (data[i].shiptype === 37) {
            //return "Gezi Teknesi - Gezi Teknesi"
            pleasureCraft=pleasureCraft+1;
        } else if (data[i].shiptype === 38) {
            //return "Ayrılmış - Belirsiz"
            reserved=reserved+1;
        } else if (data[i].shiptype === 39) {
            //return "Ayrılmış - Belirsiz"
            reserved=reserved+1;
        } else if (data[i].shiptype > 39 && data[i].shiptype < 50) {
            //return "Yüksek Hızlı Tekne - Yüksek Hızlı Tekne"
            highSpeed=highSpeed+1;
        } else if (data[i].shiptype === 50) {
            //return "Pilot Gemi - Özel Gemi"
            pilot=pilot+1;
        } else if (data[i].shiptype === 51) {
            //return "SAR Gemisi - Arama ve Kurtarma Gemisi"
            sar=sar+1;
        } else if (data[i].shiptype === 52) {
            //return "Römorkör - Buzkıran,İtici Römorkör vs."
            tug=tug+1;
        } else if (data[i].shiptype === 53) {
            //return "Port İhale Gemisi - Özel Gemi"
            port=port+1;
        } else if (data[i].shiptype === 54) {
            //return "Kirlilik Kontrol Gemisi - Özel Gemi"
            pollution=pollution+1;
        } else if (data[i].shiptype === 55) {
            //return "Devriye Gemisi - Özel Gemi"
            patrol=patrol+1;
        } else if (data[i].shiptype === 56) {
            //return "Yerel Gemi - Özel Gemi"
            local=local+1;
        } else if (data[i].shiptype === 57) {
            //return "Yerel Gemi - Özel Gemi"
            local=local+1;
        } else if (data[i].shiptype === 58) {
            //return "Hastane Gemisi - Özel Gemi"
            medical=medical+1;
        } else if (data[i].shiptype === 59) {
            //return "Özel Gemi - Özel Gemi"
            special=special+1;
        } else if (data[i].shiptype > 59 && data[i].shiptype < 70) {
            //return "Yolcu Gemisi - Yolcu Gemisi"
            passenger=passenger+1;
        } else if (data[i].shiptype === 70) {
            //return "Kargo Gemisi - Kargo Gemisi"
            cargo=cargo+1;
        } else if (data[i].shiptype === 71) {
           // return "Kargo Gemisi - A Tehlike Kargo Gemisi (Yüksek)"
            cargo=cargo+1;
        } else if (data[i].shiptype === 72) {
            //return "Kargo Gemisi - B Tehlike Kargo Gemisi"
            cargo=cargo+1;
        } else if (data[i].shiptype === 73) {
            //return "Kargo Gemisi - C Tehlike Kargo Gemisi (Düşük)"
            cargo=cargo+1;
        } else if (data[i].shiptype === 74) {
            //return "Kargo Gemisi - D Tehlike Kargo Gemisi (Ayırt Edilebilir)"
            cargo=cargo+1;
        } else if (data[i].shiptype > 74 && data[i].shiptype < 80) {
            //return "Kargo Gemisi - Kargo Gemisi"
            cargo=cargo+1;
        } else if (data[i].shiptype === 80) {
            //return "Tanker - Tanker"
            tanker=tanker+1;
        } else if (data[i].shiptype === 81) {
            //return "Tanker - A Tehlike Tanker (Yüksek)"
            tanker=tanker+1;
        } else if (data[i].shiptype === 82) {
            //return "Tanker - B Tehlike Tanker"
            tanker=tanker+1;
        } else if (data[i].shiptype === 83) {
            //return "Tanker - C Tehlike Tanker (Düşük)"
            tanker=tanker+1;
        } else if (data[i].shiptype === 84) {
            //return "Tanker - D Tehlike Tanker (Ayırt Edilebilir)"
            tanker=tanker+1;
        } else if (data[i].shiptype > 84 && data[i].shiptype < 90) {
            //return "Tanker - Tanker"
            tanker=tanker+1;
        } else if (data[i].shiptype > 89 && data[i].shiptype < 100) {
            //return "Diğer - Diğer"
            other=other+1
        } else if (data[i].shiptype === 100) {
            //return "Navigasyon Yardımı"
            other=other+1
        } else if (data[i].shiptype === 101) {
            //return "Referans Noktası"
            other=other+1
        } else if (data[i].shiptype === 102) {
            //return "Radar Transponder"
            other=other+1
        } else if (data[i].shiptype === 103) {
            //return "OffShore Yapısı"
            other=other+1
        } else if (data[i].shiptype === 104) {
            //return "Yedek"
            other=other+1
        } else if (data[i].shiptype === 105) {
            //return "Sektörsüz Işık"
            other=other+1
        } else if (data[i].shiptype === 106) {
            //return "Sektörlü Işık"
            other=other+1
        } else if (data[i].shiptype === 107) {
            //return "Leading Light Front"
            other=other+1
        } else if (data[i].shiptype === 108) {
            //return "Leading Light Rear"
            other=other+1
        } else if (data[i].shiptype === 109) {
            //return "Beacon, Cardinal N"
            other=other+1
        } else if (data[i].shiptype === 110) {
            //return "Beacon, Cardinal E"
            other=other+1
        } else if (data[i].shiptype === 111) {
            //return "Beacon, Cardinal S"
            other=other+1
        } else if (data[i].shiptype === 112) {
            //return "Beacon, Cardinal W"
            other=other+1
        } else if (data[i].shiptype === 113) {
            //return "Beacon, Port Hand"
            other=other+1
        } else if (data[i].shiptype === 114) {
            //return "Beacon, Starboard Hand"
            other=other+1
        } else if (data[i].shiptype === 115) {
            //return "Beacon, Preferred Channel Port hand"
            other=other+1
        } else if (data[i].shiptype === 116) {
            //return "Beacon, Preferred Channel Starboard hand"
            other=other+1
        } else if (data[i].shiptype === 117) {
            //return "Beacon, Isolated danger"
            other=other+1
        } else if (data[i].shiptype === 118) {
            //return "Beacon, Safe Water"
            other=other+1
        } else if (data[i].shiptype === 119) {
            //return "Beacon, Special Mark"
            other=other+1
        } else if (data[i].shiptype === 120) {
            //return "Cardinal Mark N"
            other=other+1
        } else if (data[i].shiptype === 121) {
            //return "Cardinal Mark E"
            other=other+1
        } else if (data[i].shiptype === 122) {
            //return "Cardinal Mark S"
            other=other+1
        } else if (data[i].shiptype === 123) {
            //return "Cardinal Mark W"
            other=other+1
        } else if (data[i].shiptype === 124) {
            //return "Port Hand Mark"
            other=other+1
        } else if (data[i].shiptype === 125) {
            //return "Starboard Hand Mark"
            other=other+1
        } else if (data[i].shiptype === 126) {
            //return "Preferred Channel Port Hand"
            other=other+1
        } else if (data[i].shiptype === 127) {
            //return "Preferred Channel Starboard Hand"
            other=other+1
        } else if (data[i].shiptype === 128) {
            //return "Isolated Danger"
            other=other+1
        } else if (data[i].shiptype === 129) {
            //return "Safe Water"
            other=other+1
        } else if (data[i].shiptype === 130) {
            //return "Manned VTS"
            other=other+1
        } else if (data[i].shiptype === 131) {
            //return "Light Vessel"
            other=other+1
        } else {
            //return "Tanımsız"
            undef=undef+1;
        }
    }
    return ({"other":other, "reserved":reserved, "wig":wig, "sarAircraft":sarAircraft, "sar":sar, "fishing":fishing, "tug":tug, "dredger":dredger, "dive":dive,"military": military,
        "sailing":sailing, "pleasureCraft":pleasureCraft, "highSpeed":highSpeed, "pilot":pilot, "port":port, "pollution":pollution, "patrol":patrol, "local":local, "medical":medical,
        "special":special, "passenger":passenger, "cargo":cargo, "tanker":tanker, "undef":undef})
}