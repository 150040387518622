import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';
import {CircularProgress} from '@material-ui/core';
import StackedBar from './charts/StackedBar'
import Line from './charts/Line'
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {Divider} from '@material-ui/core';
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import shipTypes from '../../../helper/shipTypes'
import {FormattedMessage} from "react-intl";

export default function Statistic(props) {
    const {authToken} = useSelector(state => state.auth);

    const [ais, setAis] = useState();
    const [aisData, setAisData] = useState();
    const [date, setDate] = useState(parseInt(new Date().getTime() / 1000));

    /*<-------- Ship Types Start ------->*/
    const [ship, setShip] = useState();
    /*<------------ Ship Types End ----------->*/

    var val = new Array();

    const {deviceRiskForMonitoring} = useSelector(
        (state) => ({
            deviceRiskForMonitoring: state.devices.deviceRiskForMonitoring
        }),
        shallowEqual
    );
    useEffect( () => {
        for (let i = 0; i < 12; i++) {
            val[i] = new Array(0)
        }
        if(deviceRiskForMonitoring){
            setAis(deviceRiskForMonitoring)
            var ships = shipTypes(deviceRiskForMonitoring)
            setShip(ships);

            for (let i = 0; i < deviceRiskForMonitoring.length; i++) {
                var a = new Date(deviceRiskForMonitoring[i].timestamp * 1000).getMonth()
                val[a].push(deviceRiskForMonitoring[i])
            }
            setAisData(val)
        }
    }, [deviceRiskForMonitoring]);

    return (
        <div className="row">
            <div className="col-xl-6">
                <Typography component="h6" variant="h6" style={{marginBottom: "20px"}}>
                    <b><FormattedMessage id="DEVICE.DETAIL.STATISTIC.TITLE"/>  </b>
                </Typography>
                <Divider/>
                <StackedBar data={ship}/>
            </div>
            <div className="col-xl-6">
                <Typography component="h6" variant="h6" style={{marginBottom: "20px"}}>
                    <b><FormattedMessage id="DEVICE.DETAIL.STATISTIC.TITLE"/> </b>
                </Typography>
                <Divider/>
                <Line data={aisData}/>
            </div>
        </div>
    );
}
