import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Switch from '@material-ui/core/Switch';
import {Divider} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import axios from "axios";
import {useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {FormattedMessage} from 'react-intl'


export default function BuoySettings(props) {

    const {authToken} = useSelector(state => state.auth);
    const [expanded, setExpanded] = useState(false);
    const [settings, setSettings] = useState([]);
    const [reportMail, setReportMail] = useState(false);
    const [reportSms, setReportSms] = useState(false);
    const [fioopMe, setFioopMe] = useState(false);
    const [fioopAll, setFioopAll] = useState(false);
    const [roiclAll, setRoiclAll] = useState(false);
    const [roiclMe, setRoiclMe] = useState(false);
    const [sliiAll, setSliiAll] = useState(false);
    const [sliiMe, setSliiMe] = useState(false);

    const history = useHistory();

    useEffect(() => {
        getSettings();
        //console.log("tableais"+ais);
    }, []);

    const getCode = (pathname) => {
        const pthName = pathname.split('/')

        return pthName[pthName.length - 1].split('*')[0]
    }

    const getSettings = () => {
        axios.get(`${process.env.REACT_APP_API}/api/device/list/notification`, {
            params: {
                token: authToken,
                code: getCode(history.location.pathname)
            },
        }).then((res) => {
            setSettings(res.data)
            setReportMail(res.data.report_email);
            setReportSms(res.data.report_sms);
            setFioopMe(res.data.fioop_me);
            setFioopAll(res.data.fioop_all);
            setRoiclAll(res.data.roicl_all);
            setRoiclMe(res.data.roicl_me);
            setSliiAll(res.data.slii_all);
            setSliiMe(res.data.slii_me);
        })
    }

    const mailClick = () => {
        setReportMail(!reportMail)
    }

    const smsClick = () => {
        setReportSms(!reportSms)
    }

    const fioopMeClick = () => {
        setFioopMe(!fioopMe)
    }

    const fioopAllClick = () => {
        setFioopAll(!fioopAll)
    }

    const roiclAllClick = () => {
        setRoiclAll(!roiclAll)
    }

    const roiclMeClick = () => {
        setRoiclMe(!roiclMe)
    }

    const sliiAllClick = () => {
        setSliiAll(!sliiAll)
    }

    const sliiMeClick = () => {
        setSliiMe(!sliiMe)
    }

    async function saveSettingsClick() {
        let body = {
            token: authToken,
            id: settings.id,
            deviceId: settings.deviceId,
            report_email: reportMail,
            report_sms: reportSms,
            roicl_me: roiclMe,
            roicl_all: roiclAll,
            fioop_me: fioopMe,
            fioop_all: fioopAll,
            slii_me: sliiMe,
            slii_all: sliiAll
        }
        await axios.put(`${process.env.REACT_APP_API}/api/device/update/notification`, {...body}
        ).then((response) => {
            props.onCloseSnackbar("Bildirim Güncellendi")

        });
        getSettings();
    }

    return (
        <div className="row">
            <div className="col-3" style={{marginBottom: "35px"}}>

            </div>
            <div className="col-9" style={{marginBottom: "35px"}}>
                <Typography component="h5" variant="h5" align='left'>
                    <b><FormattedMessage id="DEVICE.DETAIL.SETTINGS.TITLE1"/> </b>

                </Typography>
            </div>

            <div className="col-3" style={{margin: "auto"}}>

                <Typography component="h6" variant="h6" align='right'>
                    <FormattedMessage id="DEVICE.DETAIL.SETTINGS.BY_MAIL"/>
                </Typography>
            </div>
            <div className="col-9">
                <Switch checked={reportMail} onChange={mailClick}/>
            </div>
            <div className="col-3" style={{margin: "auto"}}>

                <Typography component="h6" variant="h6" align='right'>
                    <FormattedMessage id="DEVICE.DETAIL.SETTINGS.BY_SMS"/>
                </Typography>
            </div>
            <div className="col-9">
                <Switch checked={reportSms} onChange={smsClick}/>
            </div>
            <div className="col-12"><br/><Divider/> <br/></div>

            <div className="col-3" style={{marginBottom: "35px", marginTop: "15px"}}>

            </div>
            <div className="col-9" style={{marginBottom: "35px", marginTop: "15px"}}>
                <Typography component="h5" variant="h5" align='left'>
                    <b><FormattedMessage id="DEVICE.DETAIL.SETTINGS.TITLE2"/> </b>

                </Typography>
            </div>
            <div className="col-3" style={{margin: "auto"}}>

                <Typography component="h6" variant="h6" align='right'>
                    <FormattedMessage id="DEVICE.DETAIL.SETTINGS.ME"/>
                </Typography>
            </div>

            <div className="col-9">

                <div>
                    <Typography>
                        <Checkbox checked={fioopMe} onChange={fioopMeClick}/> <FormattedMessage
                        id="DEVICE.DETAIL.SETTINGS.POSITION"/>
                    </Typography>
                </div>

                <div>
                    <Typography>
                        <Checkbox checked={roiclMe} onChange={roiclMeClick}/> <FormattedMessage
                        id="DEVICE.DETAIL.SETTINGS.BATTERY_LEVEL"/>
                    </Typography>
                </div>

                <div>
                    <Typography>
                        <Checkbox checked={sliiMe} onChange={sliiMeClick}/> <FormattedMessage
                        id="DEVICE.DETAIL.SETTINGS.NOT_WORK"/>
                    </Typography>

                </div>
            </div>

            <div className="col-12"><br/></div>

            <div className="col-3" style={{margin: "auto"}}>

                <Typography component="h6" variant="h6" align='right'>
                    <FormattedMessage id="DEVICE.DETAIL.SETTINGS.ALL"/>
                </Typography>
            </div>
            <div className="col-9">

                <div>
                    <Typography>
                        <Checkbox checked={fioopAll} onChange={fioopAllClick}/> <FormattedMessage
                        id="DEVICE.DETAIL.SETTINGS.POSITION"/>
                    </Typography>
                </div>
                <div>
                    <Typography>
                        <Checkbox checked={roiclAll} onChange={roiclAllClick}/> <FormattedMessage
                        id="DEVICE.DETAIL.SETTINGS.BATTERY_LEVEL"/>
                    </Typography>
                </div>
                <div>
                    <Typography>
                        <Checkbox checked={sliiAll} onChange={sliiAllClick}/> <FormattedMessage
                        id="DEVICE.DETAIL.SETTINGS.NOT_WORK"/>
                    </Typography>

                </div>
            </div>
            <div className="col-12" style={{textAlignLast: "center"}}>
                <Button variant="contained" color="secondary" onClick={saveSettingsClick}><FormattedMessage
                    id="USER.PROFILE.BUTTON_SAVE"/></Button>
            </div>
        </div>
    );
}
