import axios from "axios";

export const NOTIFICATION_ALL_URL = process.env.REACT_APP_API + "/api/users/all/notification";
export const NOTIFICATION_ONE_URL = process.env.REACT_APP_API + "/api/users/list/one";
export const NOTIFICATION_UPDATE = process.env.REACT_APP_API + "/api/users/update/read/notification";
export const NOTIFICATION_CREATE = process.env.REACT_APP_API + "/api/users/create";
export const NOTIFICATION_DELETE = process.env.REACT_APP_API + "/api/users/update/delete/notification";

export const CUSTOMERS_URL = "api/customers";

// CREATE =>  POST: add a new customer to the server
export function createCustomer(customer) {
  return axios.post(CUSTOMERS_URL, { customer });
}

// READ
export function getAllCustomers() {
  return axios.get(CUSTOMERS_URL);
}

export function getCustomerById(customerId, authToken) {
  return axios.get(`${NOTIFICATION_ONE_URL}?token=${authToken}&deviceId:${customerId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(queryParams) {
  return axios.get(`${NOTIFICATION_ALL_URL}?token=${queryParams.authToken}`)
  //return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer) {
  return axios.put(`${CUSTOMERS_URL}/${customer.id}`, { customer });
}

// UPDATE Status
export function updateStatusForCustomers(ids, status, authToken) {
  return axios.put(`${NOTIFICATION_UPDATE}`, {
    deviceId:ids,
    is_read:status,
    token:authToken
  });
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId, authToken) {
  return axios.put(`${NOTIFICATION_DELETE}`, { deviceId:[customerId], token:authToken, is_delete:true });
}

// DELETE Customers by ids
export function deleteCustomers(ids, authToken) {

  return axios.put(`${NOTIFICATION_DELETE}`, { deviceId:ids, token:authToken, is_delete:true });
}
