import React, {useState, useRef, useCallback, useEffect} from "react";
import Card from "../modules/Devices/component/Cards"
import Grid from '@material-ui/core/Grid';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import {FormattedMessage} from "react-intl";

function MainPage() {
    // Getting curret state of products list from store (Redux)
    const {currentState, authToken, user} = useSelector(
        (state) => ({
            currentState: state.products,
            authToken: state.auth.authToken,
            user: state.auth.user
        }),
        shallowEqual
    );

    var count = 0;
    //const [count, setCount] = useState(0);
    const [datas, setDatas] = useState([]);
    const [sort, setSort] = useState(false);
    const [load, setLoad] = useState(true);
    const [search, setSearch] = useState([]);

    useEffect(() => {
        getDatas();
    }, [])

    const getDatas = (sort) => {
        if (sort) setLoad(true)
        axios.get(`${process.env.REACT_APP_API}/api/device/list/main`, {
            params: {
                token: authToken,
                offset: count,
                limit: sort && sort !== "Hepsi" ? totalCount : 10,
                type: sort ? sort : ""
            }
        })
            .then(res => {
                //console.log(res)
                setLoad(false)
                if (!sort) {
                    setDatas(prevData => {
                        return [...new Set([...prevData, ...res.data.rows])]
                    })
                } else {

                    setDatas(prevData => {
                        return [...new Set([...res.data.rows])]
                    })
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 401) {
                        console.log(error.response)
                    }
                }
                console.log(error)
            });
    }
    const {entities, listLoading, totalCount} = currentState;
    //console.log(currentState)
    const observer = useRef();

    const lastDeviceElementRef = useCallback((node, sorting) => {
        if (listLoading) return
        if (observer.current) observer.current.disconnect()
        if (sorting) {
            count = 0;
            setSort(false)
        }
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && totalCount > count) {
                count += 10;
                getDatas()
            }

        })
        if (node) observer.current.observe(node)
    }, [listLoading])
    const deviceTypeSort = (event) => {
        setDatas([])
        setSearch([])
        if (event.target.value == 0) {
            setSort("0");
            getDatas("0");
        } else {
            setSort(event.target.value);
            getDatas(event.target.value);
        }
    }
    const searchInput = (e) => {
        const searchData = [];
        if (!e.target.value) {
            setSearch([])
        } else if (e.target.value.length > 2) {

            entities.find(data => {
                var name = data.name.toLowerCase();
                if (name.match(e.target.value.toLowerCase())) {
                    searchData.push(data)
                }
            })
            setSearch(searchData)
            /*const timer = setTimeout(() => {
                setSearch(searchData)
            }, 1300)
            return () => clearTimeout(timer);*/

        }
    }
    /*console.log(datas)*/
    return (
        <>
            <div className="row">
                <div className="col-lg-4" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <label htmlFor="deneme"><FormattedMessage id="MAIN.SORT.DEVICE_TYPE"/>:</label>
                    <select className={"form-control"} name="deneme" id="deneme"
                            style={{width: "50%", marginLeft: "10px"}} onChange={e => deviceTypeSort(e)}>
                        <FormattedMessage id="MAIN.SORT.DEVICE_ALL">{(type) => <option
                            value={undefined}>{type}</option>}</FormattedMessage>
                        <FormattedMessage id="SIDEBAR.DEVICE_LIST.OCTOPUS">{(type) => <option
                            value={1}>{type}</option>}</FormattedMessage>
                        <FormattedMessage id="SIDEBAR.DEVICE_LIST.LANTERN">{(type) => <option
                            value={0}>{type}</option>}</FormattedMessage>
                    </select>
                </div>
                <div className="col-lg-4" style={{display: "flex", alignItems: "center"}}>

                </div>
                <div className="col-lg-4" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <label htmlFor="search" style={{display: "flex", alignItems: "center"}}><FormattedMessage
                        id="MAIN.SORT.SEARCH"/>:</label>
                    <input className={"form-control"} type="search" id="search"
                           style={{width: "50%", marginLeft: "10px"}} onChange={e => searchInput(e)}/>
                </div>
            </div>
            {!load ?
                /*
                * BURADA SORT VS VARSA VEYA YOKSA, SONUCUNUN POSTGREDEKİ TABLODA
                * COLLECTİON BİLGİSİ VARSA VE TİPİ ÇAKAR LAMBA(0) İSE KARTTA GÖSTERECEK
                * YOKSA GÖSTERMEYECEK BİR YAPI OLUŞTURMAYA ÇALIŞTIM.
                * KARIŞIKLIK İÇİN ÜZGÜNÜM */

                <div className="row">
                    {search.length > 0
                        ? search.map((data, index) => {
                            if (data.device_type > 0) {
                                return (
                                    <div className="col-lg-6" style={{textAlign: "-webkit-center"}}
                                         key={data.serial_number}>
                                        <Card name={data.name}
                                              db={data.device_type == 0 ? "lantern_01092001" : "octopus_06202001"}
                                              url={data.code + "*" + data.device_type} collection={data.collections}/>
                                    </div>

                                )
                            } else if (data.device_type <= 0 && data.collections) {
                                return (
                                    <div className="col-lg-6" style={{textAlign: "-webkit-center"}}
                                         key={data.serial_number}>
                                        <Card name={data.name}
                                              db={data.device_type == 0 ? "lantern_01092001" : "octopus_06202001"}
                                              url={data.code + "*" + data.device_type} collection={data.collections}/>
                                    </div>

                                )
                            } else {
                                return ""
                            }

                        })
                        : datas.length > 0 ? datas.map((data, index) => {
                                if (datas.length === index + 1 && (!sort || sort == "Hepsi")) {
                                    if (data.device_type > 0) {
                                        return (

                                            <div className="col-lg-6" style={{textAlign: "-webkit-center"}}
                                                 key={data.serial_number}
                                                 ref={e => lastDeviceElementRef(e, sort)}>
                                                <Card name={data.name}
                                                      db={"octopus_06202001"}
                                                      url={data.code + "*" + data.device_type}
                                                      collection={data.collections}/>
                                            </div>

                                        )
                                    } else if (data.device_type <= 0 && data.collections) {

                                        return (

                                            <div className="col-lg-6" style={{textAlign: "-webkit-center"}}
                                                 key={data.serial_number}
                                                 ref={e => lastDeviceElementRef(e, sort)}>
                                                <Card name={data.name}
                                                      db={data.device_type == 0 ? "lantern_01092001" : "octopus_06202001"}
                                                      url={data.code + "*" + data.device_type}
                                                      collection={data.collections}/>
                                            </div>

                                        )
                                    } else {
                                        return ""
                                    }
                                } else {
                                    if (data.device_type > 0) {
                                        return (
                                            <div className="col-lg-6" style={{textAlign: "-webkit-center"}}
                                                 key={data.serial_number}>
                                                <Card name={data.name}
                                                      db={data.device_type == 0 ? "lantern_01092001" : "octopus_06202001"}
                                                      url={data.code + "*" + data.device_type}
                                                      collection={data.collections}/>
                                            </div>

                                        )
                                    } else if (data.device_type <= 0 && data.collections) {
                                        return (
                                            <div className="col-lg-6" style={{textAlign: "-webkit-center"}}
                                                 key={data.serial_number}>
                                                <Card name={data.name}
                                                      db={data.device_type == 0 ? "lantern_01092001" : "octopus_06202001"}
                                                      url={data.code + "*" + data.device_type}
                                                      collection={data.collections}/>
                                            </div>

                                        )
                                    } else {
                                        return ""
                                    }
                                }
                            })
                            : <div style={{
                                textAlignLast: "center",
                                alignSelf: "center",
                                minHeight: "100%",
                                minWidth: "100%",
                            }}>

                                Data yok
                            </div>}
                </div>
                : <div style={{
                    textAlignLast: "center",
                    alignSelf: "center"
                }}>

                    <CircularProgress color="secondary"/>
                </div>}
        </>
    )
}

export default MainPage;