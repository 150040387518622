import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';
import {CircularProgress} from '@material-ui/core';
import BasicColumn from './charts/BasicColumn'
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {Divider} from '@material-ui/core';
import {useSelector} from "react-redux";
import axios from "axios";
import Radar from './charts/Radar'
import {FormattedMessage} from "react-intl";

export default function Sensors(props) {


    const {authToken} = useSelector(state => state.auth);

    const [state, setState] = useState();
    const [windSpeed, setWindSpeed] = useState([]);
    const [windDirection, setWindDirection] = useState([]);
    const [windTime, setWindTime] = useState([]);
    const [radar, setRadar] = useState();
    const [windCalc, setWindCalc] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API2}/minute/sensor_data_10min?token=${authToken}&timestamp=${parseInt(new Date().getTime() / 1000)}&type=${props.db}`)
            .then(res => {
                wnd(res.data);
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 401) {
                        localStorage.setItem('token', null);
                    }
                }
            });
    }, [props]);
    const wnd = (wind) => {
        var windSp = [];
        var windRadar = new Array();
        var windT = [];
        for (let i = 0; i < 36; i++) {
            windRadar[i] = new Array(0)
        }
        for (let i = 0; i < wind.length; i++) {
            if (i < 36) {
                windRadar[i] = new Array(0)
            }
            let total = (wind[i].wind_direction ? wind[i].wind_direction : -1 + wind[i].compass_heading) % 360;
            let speed = wind[i].wind_speed ? wind[i].wind_speed : -1;

            for (let j = 0; j < 36; j++) {
                let calc = (j * 10) + 4;
                if (j === 35) {
                    if (total > calc || total < 5) {
                        windRadar[0].push(speed)
                        break;
                    }
                } else if (total > calc && total < calc + 11) {
                    windRadar[j + 1].push(speed)
                    break;
                }
            }
            windSp.push(speed)
            windT.push(`${(new Date(wind[i].timestamp * 1000).getHours() < 10) ? `0${new Date(wind[i].timestamp * 1000).getHours()}` : new Date(wind[i].timestamp * 1000).getHours()}${':'}${(new Date(wind[i].timestamp * 1000).getMinutes() == 0) ? `${new Date(wind[i].timestamp * 1000).getMinutes()}0` : new Date(wind[i].timestamp * 1000).getMinutes()}`);

        }
        setRadar(windRadar)
        setWindSpeed(windSp)
        setWindTime(windT);

    }

    return (
        <div className="row">
            <div className="col-xl-6">
                <Typography component="h6" variant="h6" style={{marginBottom: "20px"}}>
                    <b><FormattedMessage id="DEVICE.DETAIL.SENSOR.TITLE"/> </b>
                </Typography>
                <Divider/>
                <Radar radar={radar}/>
            </div>
            {windSpeed.length > 0 & windTime.length > 0
                ? <div className="col-xl-6">
                    <Typography component="h6" variant="h6" style={{marginBottom: "20px"}}>
                        <b><FormattedMessage id="DEVICE.DETAIL.SENSOR.TITLE"/> </b>
                    </Typography>
                    <Divider/>
                    <BasicColumn windSpeed={windSpeed} windTime={windTime}/>
                </div>
                : ""}
        </div>
    );
}
