import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Map from './map/Map'
import Chip from '@material-ui/core/Chip';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LockIcon from '@material-ui/icons/Lock';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Divider} from '@material-ui/core';
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import ToysIcon from "@material-ui/icons/Toys";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(theme => ({
    card: {
        borderRadius: "0.42rem",
        marginTop: "25px"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

export default function BuoyStatus(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [day, setDay] = useState(0);
    const [maintanence, setMaintanence] = useState("00-00-0000");
    const [lastseen, setLastseen] = useState("00-00-0000");
    const [facility, setFacility] = useState("00-00-0000");
    const [alrt, setAlrt] = useState(601);

    function handleExpandClick() {
        setExpanded(!expanded);
    }

    let times;
    useEffect(() => {
        //console.log(props)
        const date1 = new Date(props.products.maintenance_date).getTime();
        const dateNow = new Date().getTime();
        const diffTime = Math.abs(dateNow - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setDay(diffDays)
        //console.log(props.data.timestamp)
        times = props.data.timestamp ? props.data.timestamp : 0;
        setAlrt( parseInt(new Date().getTime() / 1000) - parseInt(times))
        setMaintanence(GetFormattedDate(new Date(props.products.maintenance_date)))
        setFacility(GetFormattedDate(new Date(props.products.facility_date)))
        setLastseen(timeConverter(new Date(props.data.timestamp*1000)))
    }, [props]);

    function GetFormattedDate(todayTime) {
        //var todayTime = new Date();
        var month = todayTime.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        var day = todayTime.getDate();
        day = day < 10 ? "0" + day : day;
        var year = todayTime.getFullYear();
        return (day + "-" + month + "-" + year);
    }
    function timeConverter(u_timestamp){
        //var a = new Date(UNIX_timestamp * 1000);
        var a = u_timestamp;
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        //var month = months[a.getMonth()];
        // bug fix for month
        var month = a.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        var dated = a.getDate();
        dated = dated < 10 ? "0" + dated : dated;
        var hour = a.getHours();
        hour = hour < 10 ? "0" + hour : hour;
        var min = a.getMinutes();
        min = min < 10 ? "0" + min : min;
        var sec = a.getSeconds();
        var time = dated + '-' + month + '-' + year + ' ' + hour + ':' + min + ':' + sec ;
        return time;
      }

    const device = props.db.split("_")[0];
    const deviceType = parseInt(props.type) === 0 ? 'LANTERN' : 'OCTOPUS'
    //console.log(props.products)
    //let diff= new Date(props.products.maintenance_date).getTime() - new Date().getTime()

    return (
        <Card className={classes.card} style={{width: "99%", margin: 10}}>
            <CardContent style={{margin: 20}}>

                <div className="row">
                    <div className="col-12" style={{display: "flex"}}>
                        <div className="col-8">

                            <Typography component="h5" variant="h5">
                                {/*<b>{props.name.charAt(0).toUpperCase() + props.name.slice(1)} Şamandırası </b>*/}
                                <b>{props.products.name} (<FormattedMessage id={`SIDEBAR.DEVICE_LIST.${deviceType}`}/>)
                                </b>
                                <IconButton
                                    aria-label='Add to favorites'>
                                    {alrt > 300 ? < CancelPresentationIcon style={{color: 'rgb(230 8 8)'}}/> :
                                        < CheckBoxIcon style={{color: 'rgb(42,170,48)'}}/>}
                                </IconButton>
                            </Typography>
                            {/*<div className="row">
                                <div className="col-xl-3 col-md-12 col-sm-12 col-xs-12">

                                    <Typography variant="subtitle1" color="textSecondary">
                                        İlgili Kablo : 96 Fiber İzmir Körfez
                                    </Typography>
                                </div>
                                <div className="col-xl-3 col-md-12 col-sm-12 col-xs-12">
                                    <Typography variant="subtitle1" color="textSecondary">

                                        <LockIcon/>
                                        Koruma Hattında
                                    </Typography>
                                </div>
                                <div className="col-xl-3 col-md-12 col-sm-12 col-xs-12">
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <LocationOnIcon/> {parseFloat(props.data.lantern_latitude).toFixed(4)}-{parseFloat(props.data.lantern_longitude).toFixed(4)}
                                    </Typography>
                                </div>
                            </div>*/}
                            <br/>
                            <div className="row">
                            
                                <div className="col-xxl-3 col-xl-3 col-md-4 col-sm-12 col-xs-12">
                                    <Typography variant="subtitle2" gutterBottom>
                                        <FormattedMessage id="DEVICE.DETAIL.STATUS.LAST_SEEN"/>
                                    </Typography>
                                    <Chip size="small" variant="outlined"
                                          style={{borderColor: "rgb(53, 193, 241)", color: "rgb(53, 193, 241)"}}
                                          label={lastseen}/>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-md-4 col-sm-12 col-xs-12">
                                    <Typography variant="subtitle2" gutterBottom>
                                        <FormattedMessage id="DEVICE.DETAIL.STATUS.FACILITY"/>
                                    </Typography>
                                    <Chip size="small" variant="outlined"
                                          style={{borderColor: "rgb(53, 193, 241)", color: "rgb(53, 193, 241)"}}
                                          label={facility}/>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-md-4 col-sm-12 col-xs-12">
                                    <Typography variant="subtitle2" gutterBottom>
                                        <FormattedMessage id="DEVICE.DETAIL.STATUS.LAST_MAINTENANCE"/>

                                    </Typography>
                                    <Chip size="small" variant="outlined"
                                        //style={{borderColor: "rgb(241,53,81)", color: "rgb(241,53,81)"}}
                                          style={{borderColor: "rgb(53, 193, 241)", color: "rgb(53, 193, 241)"}}
                                          label={maintanence}/>

                                </div>
                                <div className="col-xxl-3 col-xl-3 col-md-4 col-sm-12 col-xs-12">
                                    <Typography variant="subtitle2" gutterBottom>
                                        <FormattedMessage id="DEVICE.DETAIL.STATUS.BATTERY_REPLACEMENT"/>

                                    </Typography>
                                    <LinearProgress color="primary" variant="determinate" value={(day / 730) * 100}/>
                                    {730} / {730- parseInt(day)}
                                </div>
                            </div>

                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-4 col-xs-4">
                            <div style={{float: "right"}}>

                                {/*<Button variant="contained"
                                        style={{marginRight: "5px", backgroundColor: "#33eaff", color: "#00a0b2"}}>Bakım
                                    Geçmişi</Button>
                                <Button variant="contained" color="secondary"
                                        style={{backgroundColor: "#d500f9", color: "#fff"}}>Risk Geçmişi</Button>*/}
                            </div>
                        </div>
                    </div>


                </div>
                <br/>
                <Divider/>

                <div className="row" style={{marginTop: "20px"}}>
                    <br/>
                    {device == "octopus"
                        ? <>
                            <div className="col-xl-2 col-md-4 col-sm-12 col-xs-12">
                                <Typography variant="subtitle2" gutterBottom>
                                    <WifiTetheringIcon
                                        style={alrt > 900 ? {color: "rgb(230 8 8)"} : {color: 'rgb(42,170,48)'}}/>
                                    <FormattedMessage id="DEVICE.STATUS.CONNCETION"/>
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom style={{marginLeft: "21px"}}>
                                    {alrt > 900
                                        ? <b><FormattedMessage id="DEVICE.STATUS.OFFLINE"/></b>
                                        : <b><FormattedMessage id="DEVICE.STATUS.ONLINE"/></b>}
                                </Typography>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-12 col-xs-12">
                                <Typography variant="subtitle2" gutterBottom>
                                    <EmojiObjectsIcon style={{color: "rgb(53, 193, 241)"}}/>
                                    <FormattedMessage id="MAIN.CARD.LANTERN"/>
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom style={{marginLeft: "21px"}}>
                                    <b>{props.data.lantern_led_health ?
                                        <FormattedMessage id="OPERATIONS.USERS.STATUS.1"/> :
                                        <FormattedMessage id="OPERATIONS.USERS.STATUS.2"/>}</b>
                                </Typography>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-12 col-xs-12">
                                <Typography variant="subtitle2" gutterBottom>
                                    <ToysIcon style={{color: "rgb(53, 193, 241)"}}/>

                                    <FormattedMessage id="DEVICE.STATUS.WIND_SPEED_DIRECTION"/>
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom style={{marginLeft: "21px"}}>
                                    <b> {props.data.wind_speed} kt/{(props.data.wind_direction + props.data.compass_heading) % 360}
                                    </b>
                                </Typography>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-12 col-xs-12">

                                <Typography variant="subtitle2" gutterBottom>
                                    <BatteryFullIcon style={{color: "rgb(53, 193, 241)"}}/>
                                    <FormattedMessage id="DEVICE.STATUS.VOLTAGE_CURRENT"/>
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom style={{marginLeft: "21px"}}>
                                    <b>{parseFloat(props.data.battery_voltage).toFixed(2)}V/ {parseFloat(props.data.led_off_avg_voltage).toFixed(2)} mA</b>
                                </Typography>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-12 col-xs-12">
                                <Typography variant="subtitle2" gutterBottom>
                                    <ReportProblemIcon style={{color: "rgb(241,53,81)"}}/>
                                    <FormattedMessage id="DEVICE.STATUS.RISK"/>
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom style={{marginLeft: "21px"}}>
                                    <b>{props.ais.length}</b>
                                </Typography>
                            </div>
                        </>
                        : <>
                            <div className="col-xl-2 col-md-2 col-sm-12 col-xs-12">
                                <Typography variant="subtitle2" gutterBottom>
                                    <WifiTetheringIcon
                                        style={alrt > 1080 ? {color: "rgb(230 8 8)"} : {color: 'rgb(42,170,48)'}}/>
                                    <FormattedMessage id="DEVICE.STATUS.CONNCETION"/>
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom style={{marginLeft: "21px"}}>
                                    {alrt > 1080
                                        ? <b><FormattedMessage id="DEVICE.STATUS.OFFLINE"/></b>
                                        : <b><FormattedMessage id="DEVICE.STATUS.ONLINE"/></b>}
                                </Typography>
                            </div>
                            <div className="col-xl-2 col-md-2 col-sm-12 col-xs-12">
                                <Typography variant="subtitle2" gutterBottom>
                                    <EmojiObjectsIcon style={{color: "rgb(53, 193, 241)"}}/>
                                    <FormattedMessage id="MAIN.CARD.LANTERN"/>
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom style={{marginLeft: "21px"}}>
                                    <b>{props.data.lantern_led_active ?
                                        <FormattedMessage id="OPERATIONS.USERS.STATUS.1"/> :
                                        <FormattedMessage id="OPERATIONS.USERS.STATUS.2"/>}</b>
                                </Typography>
                            </div>
                            <div className="col-xl-2 col-md-2 col-sm-12 col-xs-12">
                                <Typography variant="subtitle2" gutterBottom>
                                    <EmojiObjectsIcon style={{color: "rgb(53, 193, 241)"}}/>
                                    <FormattedMessage id="MAIN.CARD.LANTERNHEALTH"/>
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom style={{marginLeft: "21px"}}>
                                    <b>{props.data.lantern_led_health ?
                                        <FormattedMessage id="DEVICE.STATUS.LED_HEALTHY"/> :
                                        <FormattedMessage id="DEVICE.STATUS.LED_UNHEALTHY"/>}</b>
                                </Typography>
                            </div>

                            <div className="col-xl-2 col-md-2 col-sm-12 col-xs-12">

                                <Typography variant="subtitle2" gutterBottom>
                                    <BatteryFullIcon style={{color: "rgb(53, 193, 241)"}}/>
                                    <FormattedMessage id="DEVICE.STATUS.VOLTAGE_CURRENT"/>
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom style={{marginLeft: "21px"}}>
                                    <b>{parseFloat(props.data.battery_voltage).toFixed(2)}V/ {parseFloat(props.data.battery_current).toFixed(2)} mA</b>
                                </Typography>
                            </div>

                            <div className="col-xl-2 col-md-2 col-sm-12 col-xs-12">

                                <Typography variant="subtitle2" gutterBottom>
                                    <LocationOnIcon
                                        style={props.data.latitude ? {color: "rgb(53, 193, 241)"} : {color: "rgb(230 8 8)"}}/>
                                    <FormattedMessage id="DEVICE.STATUS.LAST_LOCATION"/>
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom style={{marginLeft: "21px"}}>
                                    <b> {parseFloat(props.data.latitude).toFixed(4) + " , " + parseFloat(props.data.longitude).toFixed(4)}</b>
                                </Typography>
                            </div>
                            <div className="col-xl-2 col-md-2 col-sm-12 col-xs-12">

                                <Typography variant="subtitle2" gutterBottom>
                                    <LocationOnIcon
                                        style={props.data.latitude ? {color: "rgb(53, 193, 241)"} : {color: "rgb(230 8 8)"}}/>
                                    <FormattedMessage id="MAIN.CARD.SENKRON"/>
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom style={{marginLeft: "21px"}}>
                                    <b> {props.data.latitude ? <FormattedMessage id="OPERATIONS.USERS.STATUS.1"/> :
                                        <FormattedMessage id="OPERATIONS.USERS.STATUS.2"/>}</b>
                                </Typography>
                            </div>
                        </>
                    }
                </div>
            </CardContent>
        </Card>
    );
}
