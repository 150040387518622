import React, {Component, useEffect, useState} from "react";
import Chart from "react-apexcharts";
import {useIntl} from "react-intl";

export default function Radar(props) {
    const intl = useIntl();

    const [series, setSeries] = useState([{
        name: 'Wind Speed',
        data: [],
    }]);
    const [options, setOptions] = useState({
        chart: {
            height: 350,
            type: 'radar',
            dropShadow: {
                enabled: true,
                blur: 1,
                left: 1,
                top: 1
            }
        },
        title: {
            text: intl.formatMessage({id: "DEVICE.DETAIL.SENSOR.SUB_TITLE"})
        },
        stroke: {
            width: 2
        },
        fill: {
            opacity: 0.1
        },/*
                markers: {
                    size: 4
                },*/
        xaxis: {
            categories: ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100', '110', '120', '130', '140', '150', '160', '170', '180', '190', '200', '210', '220', '230', '240', '250', '260', '270', '280', '290', '300', '310', '320', '330', '340', '350']//,'','','','','','','','','', 60,'','','','','','','','','','','','','','','','','','','','','','','', 120,'','','','','','','','','','','','','','','','','','','','','','','', 180,'','','','','','','','','','','','','','','','','','','','','','','', 240,'','','','','','','','','','','','','','','','','','','','','','','', 300,'','','','','','','','','','','','','','','','','','','','','','','']
        }
    });


    useEffect(() => {

        let dnm = [];
        if (props.radar) {
            for (let i = 0; i < 36; i++) {
                //console.log(this.props.radar[i].reduce((a, b) => a + b, 0))
                var a = props.radar[i].reduce((a, b) => a + b, 0)
                //console.log(a)
                dnm.push(a > 0 ? a / props.radar[i].length : a)
            }
        }
        setSeries([{
                name: 'Wind Speed',
                data: dnm,
            }]);

    }, [props]);


    return (


        <div id="chart" style={{marginTop: "20px"}}>
            <Chart options={options} series={series} type="radar" height={350}/>
        </div>


    );
}