import axios from "axios";
import products from "../../__mocks__/carTableMock"
export const PRODUCTS_URL = "api/products";
export const PRODUCTS_ALL_URL = process.env.REACT_APP_API + "/api/device/list";
export const PRODUCTS_ALL_SORT_URL = process.env.REACT_APP_API + "/api/device/list/sort";
export const PRODUCTS_ONE_URL = process.env.REACT_APP_API + "/api/device/list/one";
export const PRODUCTS_UPDATE_URL = process.env.REACT_APP_API + "/api/device/update";
export const PRODUCTS_ADD_URL = process.env.REACT_APP_API + "/api/device/add";
export const PRODUCTS_CREATE_URL = process.env.REACT_APP_API + "/api/device/create";
export const PRODUCTS_REMOVE_URL = process.env.REACT_APP_API + "/api/device/remove";
export const PRODUCTS_DELETE_URL = process.env.REACT_APP_API + "/api/device/delete";
export const COMPANIES_ALL_URL = process.env.REACT_APP_API + "/api/companies/list";

// CREATE =>  POST: add a new product to the server
export function createProduct(product) {
  console.log(product)
  return axios.post(`${PRODUCTS_CREATE_URL}`, { ...product });
}

// READ
export function getAllProductsSort(sort) {

  return axios.get(`${PRODUCTS_ALL_SORT_URL}?token=${sort.token}&type=${sort.type}`);
}

export function getProductById(productId) {

  return axios.get(`${PRODUCTS_ONE_URL}?token=${productId.token}&id=${productId.id}`);
}


export function findProducts(queryParams) {

  return axios.get(`${PRODUCTS_ALL_URL}?token=${queryParams.token}`);

}

// UPDATE => PUT: update the procuct on the server
export function updateProduct(product) {

  return axios.post(`${PRODUCTS_UPDATE_URL}`, { ...product });
}

// UPDATE => PUT: update the procuct on the server
export function relateProduct(product) {
  return axios.post(`${PRODUCTS_ADD_URL}`, { ...product });
}

// UPDATE Status
export function updateStatusForProducts(ids, status) {

  return axios.post(`${PRODUCTS_URL}/updateStatusForProducts`, {
    ids,
    status
  });
}

// DELETE => delete the product from the server
export function deleteProduct(productId) {
  console.log(productId)
  return axios.post(`${PRODUCTS_DELETE_URL}`,{...productId});
}

// REMOVE => remove the product from the company
export function removeProduct(productId) {
  return axios.post(`${PRODUCTS_REMOVE_URL}`,{...productId});
}

// DELETE Products by ids
export function deleteProducts(ids) {
  return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
}

export function getCompanies(token) {

  return axios.get(`${COMPANIES_ALL_URL}?token=${token.token}`);
}
