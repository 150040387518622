import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import BuoyStatus from '../modules/Devices/component/BuoyStatus'
import {red} from '@material-ui/core/colors';
import BuoyMonitoring from '../modules/Devices/component/BuoyMonitoring'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {useHistory} from "react-router-dom";

import * as actions from "../../app/modules/Devices/_redux/devices/devicesActions";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    card: {
        borderRadius: "0.42rem",
        marginTop: "25px"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const BuoyDetailPage = (props) => {

    //console.log(authToken)
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [date, setDate] = useState(parseInt(new Date().getTime() / 1000));
    const [range, setRange] = useState();
    const [geofence, setGeofence] = useState(null);
    const [ais, setAis] = useState([]);
    const [interval, setInter] = useState(null);
    const [lantern, setLantern] = useState(null);
    const [warning, setWarning] = useState(null);
    const [systemStatus, setSystemStatus] = useState(null);


    const pathname = props.location.pathname.split("/").[2];
    const deviceType = pathname.split("*").[1]
    const db = deviceType == 0 ? "lantern_01092001" : "octopus_06202001"
    const history = useHistory();


    // Getting curret state of products list from store (Redux)

    const {products, currentState, authToken} = useSelector(
        (state) => ({
            products: state.products,
            currentState: state.devices,
            authToken: state.auth.authToken
        }),
        shallowEqual
    );
    const productsCollections = products.entities.find(x => x.code === pathname.split('*')[0]);
    const {deviceForMonitoring, deviceRiskForMonitoring} = currentState;
    // Products Redux state
    const dispatch = useDispatch();
    useEffect(() => {
        if (db.split("_")[0] === "octopus") {
            dispatch(actions.fetchDevice({type: db, token: authToken}));
            dispatch(actions.fetchDeviceRisk({
                date: parseInt(new Date().getTime() / 1000),
                type: db,
                token: authToken
            }));
        } else {

            dispatch(actions.fetchDevice({
                type: productsCollections.collections.trim(),
                token: authToken,
                collection: 'system_status'
            }));

            //dispatch(actions.fetchDeviceRisk({type: 'LTR_4294967295', token: authToken, collection:'warning'}));

            //axios.get(`http://168.119.161.243:5000/api/device?token=${authToken}&dbName=${productsCollections.collections.trim()}&collection=warning`)
            axios.get(`${process.env.REACT_APP_MQTT_SERVER}/api/device?token=${authToken}&dbName=${productsCollections.collections.trim()}&collection=warning`)
                .then((response) => {
                    setWarning(response.data);
                }).catch(error => {
                if (error.response) {
                    if (error.response.status == 401) {
                        history.push("/logout");
                    }
                }
                console.log(error)
            });
            // start system status new added
            axios.get(`${process.env.REACT_APP_MQTT_SERVER}/api/device?token=${authToken}&dbName=${productsCollections.collections.trim()}&collection=system_status&load=1`)
                .then((response) => {
                    setSystemStatus(response.data);
                }).catch(error => {
                if (error.response) {
                    if (error.response.status == 401) {
                        history.push("/logout");
                    }
                }
                console.log(error)
            });

            // end system status
            axios.get(`${process.env.REACT_APP_MQTT_SERVER}/api/device?token=${authToken}&dbName=${productsCollections.collections.trim()}&collection=config`)
                .then((response) => {
                    // console.log(props.collection, response.data)
                    setGeofence(response.data.initial_location ? response.data.initial_location : null)
                    setRange(response.data.initial_location ? response.data.initial_location.range : "");

                }).catch(error => {
                if (error.response) {
                    if (error.response.status == 401) {
                        history.push("/logout");
                    }
                }
                console.log(error)
            });
        }

    }, [props, dispatch]);

    return (
        <>
            {deviceForMonitoring || lantern
                ? <>
                    <BuoyStatus data={deviceForMonitoring} db={db}
                                ais={ais} type={deviceType} products={productsCollections}/>
                    <BuoyMonitoring data={deviceForMonitoring} db={db} range={range} geofence={geofence} system_status={systemStatus}
                                    ais={ais} warning={warning} collection={productsCollections.collections}/>
                </>
                :
                <div style={{
                    textAlignLast: "center",
                    alignSelf: "center"
                }}>
                    <br/>
                    <CircularProgress color="secondary"/>
                </div>}
            {/* <BuoyMonitoring data={data} db={db} ais={ais}/>*/}
        </>
    );
}
export default BuoyDetailPage;