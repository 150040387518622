import * as requestFromServer from "./productsCrud";
import {productsSlice, callTypes} from "./productsSlice";

const {actions} = productsSlice;

export const fetchProducts = queryParams => dispatch => {
    var products = []
    dispatch(actions.startCall({callType: callTypes.list}));
    return requestFromServer
        .findProducts(queryParams)
        .then(response => {
            response.data.map(data => {
                products.push({
                    ...data,
                    company_name: data.company_devices[0] ? data.company_devices[0].company.name : "",
                    type: data.device_type == 1 ? "Octopus" : "Çakar Lamba",
                    active: data.is_active ? "Tanımlı" : "Tanımlı Değil"
                })
            })
            return dispatch(actions.productsFetched({totalCount: response.data.length, entities: products}));
        })
        .catch(error => {
            error.clientMessage = "Can't find products";
            dispatch(actions.catchError({error, callType: callTypes.list}));
        });


};

export const fetchProduct = id => dispatch => {
    if (!id.id) {
        return dispatch(actions.productFetched({productForEdit: undefined}));
    }

    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .getProductById(id)
        .then(response => {
            const product = response.data;
            dispatch(actions.productFetched({productForEdit: product}));
            return response;
        })
        .catch(error => {
            error.clientMessage = error.response ? error.response : "Can't delete product";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return error.clientMessage;
        });
};

export const deleteProduct = id => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .deleteProduct(id)
        .then(response => {

            dispatch(actions.productDeleted({id}));
            return response;
        })
        .catch(error => {

            error.clientMessage = error.response ? error.response : "Can't delete product";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return error.clientMessage;
        });
};

export const createProduct = productForCreation => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .createProduct(productForCreation)
        .then(response => {
            const {product} = response.data;
            dispatch(actions.productCreated({product}));
            return response;
        })
        .catch(error => {
            error.clientMessage = error.response ? error.response : "Can't delete product";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return error.clientMessage;
        });
};

export const relateProduct = productForCreation => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .relateProduct(productForCreation)
        .then(response => {
            const {product} = response.data;
            dispatch(actions.productCreated({product}));
            return response;
        })
        .catch(error => {
            error.clientMessage = error.response ? error.response : "Can't delete product";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return error.clientMessage;
        });
};

export const updateProduct = product => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .updateProduct(product)
        .then(response => {
            dispatch(actions.productUpdated({product}));
            return response;
        })
        .catch(error => {
            error.clientMessage = error.response ? error.response : {message: "Can't update product"};
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return error.clientMessage;
        });
};

export const updateProductsStatus = (ids, status) => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .updateStatusForProducts(ids, status)
        .then(response => {
            dispatch(actions.productsStatusUpdated({ids, status}));
            return response;
        })
        .catch(error => {
            error.clientMessage = error.response ? error.response : "Can't delete product";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return error.clientMessage;
        });
};

export const deleteProducts = ids => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .deleteProducts(ids)
        .then(response => {
            dispatch(actions.productsDeleted({ids}));
            return response;
        })
        .catch(error => {
            error.clientMessage = error.response ? error.response : "Can't delete product";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return error.clientMessage;
        });
};

export const removeProduct = id => dispatch => {

    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .removeProduct(id)
        .then(response => {
            dispatch(actions.productDeleted({id}));
            return response;
        })
        .catch(error => {
            error.clientMessage = error.response ? error.response : "Can't delete product";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return error.clientMessage;
        });
};

export const fetchCompanies = token => dispatch => {
    if (!token.is_admin) {
        return dispatch(actions.companyFetched({companiesList: undefined}));
    }

    dispatch(actions.companyCall({callType: callTypes.action}));
    return requestFromServer
        .getCompanies(token)
        .then(response => {

            const companies = response.data;
            dispatch(actions.companyFetched({companiesList: companies}));
            return response;
        })
        .catch(error => {
            error.clientMessage = error.response ? error.response : "Can't delete product";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return error.clientMessage;
        });
};

export const fetchSortProducts = sort => dispatch => {
    var products = []
    dispatch(actions.startCall({callType: callTypes.list}));
    return requestFromServer
        .getAllProductsSort(sort)
        .then(response => {

            response.data.map(data => {
                products.push({
                    ...data,
                    company_name: data.company_devices[0] ? data.company_devices[0].company.name : "",
                    type: data.device_type == 1 ? "Octopus" : "Çakar Lamba",
                    active: data.is_active ? "Tanımlı" : "Tanımlı Değil"
                })
            })
            return dispatch(actions.productsFetched({totalCount: response.data.length, entities: products}));
        })
        .catch(error => {
            error.clientMessage = "Can't find products";
            dispatch(actions.catchError({error, callType: callTypes.list}));
        });


};